/* eslint-disable no-underscore-dangle */
import { compose, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from 'reducers';
import lcrSaga from 'sagas';

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];
const utils = [];

if (process.env.NODE_ENV !== 'production') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    utils.push(devToolsExtension());
  }
}

if (process.env.NODE_ENV === 'production' && typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
  const keys = Object.keys(window.__REACT_DEVTOOLS_GLOBAL_HOOK__);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] === 'function') {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = () => {};
    } else {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = null;
    }
  }
}

const createStoreWithMiddleware = compose(
  applyMiddleware(...middleware),
  ...utils,
)(createStore);

export default function configureStore(initialState) {
  const store = createStoreWithMiddleware(rootReducer, initialState);
  sagaMiddleware.run(lcrSaga);

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextRootReducer = rootReducer;
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
