import axios from 'axios';
import * as localforage from 'localforage';
import { APIError, ErrorLog } from 'utils/LoggerItem';

export function createConstants(...constants) {
  return constants.reduce(
    (acc, constant) => ({
      ...acc,
      [constant]: constant,
    }),
    {},
  );
}

export function createNamespacedConstants(namespace, ...constants) {
  return constants.reduce(
    (acc, constant) => ({
      ...acc,
      [constant]: `${namespace}_${constant}`,
    }),
    {},
  );
}

// Helper function for creating reducers (to reduce boilerplate)
export function createReducer(initialState, reducerMap) {
  return (state = initialState, action) => {
    const reducer = reducerMap[action.type];
    return reducer ? reducer(state, action.payload) : state;
  };
}

export function getBlobURL(url) {
  if (!url) {
    return null;
  }
  return localforage.getItem(url).then(blob => {
    // Is item from this URL already in cache?
    if (!blob) {
      return axios
        .get(url, {
          responseType: 'blob',
        })
        .then(data => {
          const contentType = data.headers['content-type'];
          const addedBlob = new Blob([data.data], { type: contentType });

          return localforage.setItem(url, addedBlob);
        })
        .then(addedBlob => window.URL.createObjectURL(addedBlob))
        .catch(error => new ErrorLog(
          error.message,
          error.stack,
        ));
    }
    return window.URL.createObjectURL(blob);
  });
}

export function repeatText(text, times) {
  const msgList = [];
  for (let i = 0; i < times; i += 1) {
    msgList.push(text);
  }
  return msgList.join(' ');
}

export function isLightColor(inputColor) {
  const color = inputColor.replace('#', '');
  const red = parseInt(color.substr(0, 2), 16);
  const green = parseInt(color.substr(2, 2), 16);
  const blue = parseInt(color.substr(4, 2), 16);
  const yiq = (red * 299 + green * 587 + blue * 114) / 1000;
  return yiq >= 128;
}

// Turn into an object with .reduce()
export const arrayToObject = (array, id) => array.reduce((accumulator, current) => {
  accumulator[current[id]] = current;
  return accumulator;
}, {});

// if not in ranked add last
export const sortMarkets = (ranked, input) => [...input].sort((a, b) => {
  const aIdx = ranked.indexOf(a);
  const bIdx = ranked.indexOf(b);
  return (aIdx > -1 ? aIdx : Infinity) - (bIdx > -1 ? bIdx : Infinity);
});

export function DOMRectToObject(element) {
  const rect = element.getBoundingClientRect();
  return {
    top: rect.top,
    right: rect.right,
    bottom: rect.bottom,
    left: rect.left,
    width: rect.width,
    height: rect.height,
    x: rect.x,
    y: rect.y,
  };
}

// https://gitlab.sportradar.ag/sir/sir-components/-/blob/master/src/utils/colorConvert/colorConvert.js
// Converts a color from hex to decimal with separate RGB components.
// This function only accepts strings of six characters.
export function convertHexToRGB(color) {
  if (typeof color !== 'string' || color.length !== 6) {
    return {
      r: 0,
      g: 0,
      b: 0,
    };
  }

  return {
    r: parseInt(color.substr(0, 2), 16),
    g: parseInt(color.substr(2, 2), 16),
    b: parseInt(color.substr(4, 2), 16),
  };
}

// Extracts the HSL luminance from a RGB color object.
export function getLuminance(color) {
  // https://gitlab.sportradar.ag/bets/sir-components/-/blob/master/src/utils/colorConvert/colorConvert.js#L21 guessing it should be color.g and color.b
  if (color.r === undefined || color.g === undefined || color.b === undefined) {
    return 0;
  }

  return parseFloat(
    ((Math.max(color.r, color.g, color.b) + Math.min(color.r, color.g, color.b)) / 510).toFixed(3),
  );
}


export function removeDuplicatesKeepFirst(arr) {
  const seen = new Set();
  return arr.filter(item => (seen.has(item) ? false : seen.add(item)));
}

export function transformJsError(error) {
  if (error && error.stack && error.message) {
    return new ErrorLog(error.message, error.stack);
  }
  if (error && error.error) {
    return error;
  }

  return null;
}

export function transformAPIError(error) {
  if (error && error.stack && error.message) { // JS error
    return new APIError(error.message, error.stack);
  }
  if (error && error.error) {
    return error;
  }

  return null;
}

export function convertToTimeString(timeSecs) {
  if (timeSecs >= 0) {
    const h = Math.floor(timeSecs.toFixed(0) / 60);
    const hPad = h < 10 ? '0' : '';

    const s = timeSecs.toFixed(0) - Math.floor(timeSecs.toFixed(0) / 60) * 60;
    const sPad = s < 10 ? '0' : '';

    return hPad + h + ':' + sPad + s;
  }

  return '--:--';
}

export function convertToCountdownString(distance) {
  if (distance >= 0) {
    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (60 * 60 * 24));
    const hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((distance % (60 * 60)) / (60));

    return (
      (days === 0 ? '' : days + 'd ')
      + hours + 'h '
      + minutes + 'm '
    );
  }

  return '--:--';
}

export const percentageValueFromString = (stringValue, decimals = 1) => {
  if (!stringValue) {
    return stringValue;
  }
  if (!(typeof stringValue === 'string' && stringValue.includes('/'))) {
    return stringValue;
  }

  const valuesArray = stringValue.split('/');
  const divisor = valuesArray.pop();
  let valuePercentage = (
    Math.round(((valuesArray[0] / divisor) * 100) * (10 ** decimals)) / (10 ** decimals)
  );

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(valuePercentage)) {
    valuePercentage = 0;
  }
  return valuePercentage;
};
