import {
  take,
  select,
  call,
  race,
  put,
  delay,
} from 'redux-saga/effects';
import { UPDATE_MATCH_INFO, UPDATE_MATCH_DELTA, START_LCR } from 'constants/actions';
import { getMatchSportId } from 'reducers';
import {
  BLOCK_HERE, SEC, TIMEOUT,
} from '../constants/app';
import { log } from '../actions/logger';
import { POLL_ONCE } from '../constants/fishnet';
import { ERROR_LOG } from '../constants/bcms';
import { transformAPIError } from '../utils/index';


function* timeoutSaga(waitTime) {
  if (!waitTime) {
    yield take(BLOCK_HERE);
  }
  yield delay(waitTime);
  return true;
}

export function* takeMatchUpdate(
  matchId,
  pattern = [UPDATE_MATCH_INFO, UPDATE_MATCH_DELTA, START_LCR],
  timeoutVal = undefined,
) {
  for (;;) {
    const {
      update,
      timeout: timedOut,
    } = (
      yield race(
        {
          update: take(pattern),
          timeout: call(timeoutSaga, timeoutVal),
        },
      )
    );
    if (timedOut) {
      return TIMEOUT;
    }
    if ((update && update.matchId === matchId) || update.type === 'START_LCR') {
      return true;
    }
  }
}

export function* waitForMatchData(matchId) {
  if (yield select(getMatchSportId, matchId)) {
    return true;
  }

  return yield call(takeMatchUpdate, matchId);
}

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

// default options for both pollTillResponse and pollFishnet
const defaultOptions = {
  conditionFn: () => null,
  backoffFn: n => {
    const next = n * SEC;
    const max = 60 * SEC;

    return (next > max ? max : next) + randomIntFromInterval(0, 500);
  },
  tries: -1,
  pollInterval: POLL_ONCE,
  logFn: () => null,
  handleError: () => null,
  logLevel: 0,
};

export function* pollTillResponse(options, saga, ...args) {
  const opts = {
    ...defaultOptions,
    ...options,
  };

  for (let i = 0; ;i++) {
    try {
      const { timeout } = yield race({
        api: call(saga, ...args),
        timeout: delay(1000),
      });

      // not timeout = successful api call
      if (!timeout) {
        return;
      }
    } catch (error) {
      if (error && error.error) {
        if (options.logLevel === ERROR_LOG) {
          error.generateMessage();
        }
        yield put(log(transformAPIError(error)));
      }
    }

    if (opts.tries === -1) {
      yield delay(opts.backoffFn(i));
    } else if (i + 1 >= opts.tries) {
      return;
    } else {
      yield delay(opts.backoffFn(i));
    }
  }
}

export function* pollFishnet(options, saga, ...args) {
  const opts = {
    ...defaultOptions,
    ...options,
  };

  for (let i = 0; ;i++) {
    try {
      yield race({
        api: call(saga, ...args),
        timeout: delay(1000),
      });
    } catch (error) {
      if (error && error.error) {
        if (options.logLevel === ERROR_LOG) {
          error.generateMessage();
        }
        yield put(log(transformAPIError(error)));
      }
    }
    if (opts.pollInterval === POLL_ONCE) {
      return;
    }
    yield delay(opts.pollInterval);
  }
}
