import {
  call,
  select,
  put,
} from 'redux-saga/effects';
import { updateStatSlide } from '../../actions/components';
import { getSSCompDynamicSlides } from '../../reducers/index';
import { waitForMatchData } from '../utilLCR';
import generalStatSlides from './statslides/general';


export default function* controlStatSlides(matchId) {
  try {
    yield call(waitForMatchData, matchId);
    yield call(generalStatSlides, matchId, null);
  } catch (error) {
    //
  } finally {
    const dynamicSlides = yield select(getSSCompDynamicSlides);
    const slides = Object.keys(dynamicSlides);

    // deactivate used slides
    for (let i = 0; i < slides.length; i++) {
      yield put(updateStatSlide({
        slide: slides[i],
        status: false,
      }));
    }
  }
}
