import { createReducer } from 'utils';
import { createSelector } from 'reselect';
import { HIGHLIGHTS_LOAD_SUCCESS, START_LCR } from 'constants/actions';
import { UPDATE_MATCH_FUNFACTS, UPDATE_MATCH_FUNFACT_IDX } from '../actions/fishnet/actionTypes';

const initialState = {
  match: null,
  phrases: [],
  phraseIdx: null,
  qHOTD: null,
};

export default createReducer(initialState, {
  [HIGHLIGHTS_LOAD_SUCCESS]: (state, payload) => ({
    ...state,
    match: payload,
  }
  ),
  [UPDATE_MATCH_FUNFACTS]: (state, payload) => ({
    ...state,
    phrases: [...payload],
  }),
  [UPDATE_MATCH_FUNFACT_IDX]: (state, payload) => ({
    ...state,
    phraseIdx: payload,
  }),
  [START_LCR]: (state, payload) => ({
    ...state,
    qHOTD: payload.qHOTD,
  }),
});

export const getHOTDMatch = createSelector(
  state => state.match,
  state => state.qHOTD,
  (match, qHOTD) => qHOTD || match,
);

export const getHOTDPhrases = createSelector(
  state => state.phrases,
  phrases => phrases,

);

export const getHOTDPhraseIdx = createSelector(
  state => state.phraseIdx,
  phraseIdx => phraseIdx,
);
