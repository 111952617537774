
import * as types from './actionTypes';

export function updateMatchInfo(response, matchId) {
  return { type: types.UPDATE_MATCH_INFO, payload: response, matchId };
}
export function updateLiveOdds(response, matchId) {
  return { type: types.UPDATE_LIVE_ODDS, payload: response, matchId };
}
export function updatePreMatchOdds(response, matchId) {
  return { type: types.UPDATE_PREMATCH_ODDS, payload: response, matchId };
}
export function updateMatchDetails(response, matchId) {
  return { type: types.UPDATE_MATCH_DETAILS, payload: response, matchId };
}
export function updateMatchDelta(response, matchId) {
  return { type: types.UPDATE_MATCH_DELTA, payload: response, matchId };
}
export function updateStatsMatchSituation(response, matchId) {
  return { type: types.UPDATE_STATS_MATCH_SITUATION, payload: response, matchId };
}
export function updateStatsSeasonGoals(response, matchId) {
  return { type: types.UPDATE_STATS_SEASON_GOALS, payload: response, matchId };
}
export function updateStatsSeasonOverUnder(response, matchId) {
  return { type: types.UPDATE_STATS_SEASON_OVERUNDER, payload: response, matchId };
}
export function updateStatsTeamLastX(response, matchId) {
  return { type: types.UPDATE_STATS_TEAM_LASTX, payload: response, matchId };
}
export function updateTournamentLiveTable(response, matchId) {
  return { type: types.UPDATE_TOURNAMENT_LIVETABLE, payload: response, matchId };
}
export function updateMatchTimeline(response, matchId) {
  return { type: types.UPDATE_MATCH_TIMELINE, payload: response, matchId };
}
export function updateStatsMatchOverUnder(response, matchId) {
  return { type: types.UPDATE_STATS_MATCH_OVERUNDER, payload: response, matchId };
}
export function updateStatsTeamVersus(response, matchId) {
  return { type: types.UPDATE_STATS_TEAM_VERSUS, payload: response, matchId };
}
export function updateStatsTeamWinPct(response, matchId) {
  return { type: types.UPDATE_STATS_TEAM_WIN_PCT, payload: response, matchId };
}
export function updateStatsTeamInfo(response, matchId) {
  return { type: types.UPDATE_STATS_TEAM_INFO, payload: response, matchId };
}
export function updateMatchFunFacts(response, matchId) {
  return { type: types.UPDATE_MATCH_FUNFACTS, payload: response, matchId };
}
// FAILED

export function updateMatchInfoFailed(error, matchId) {
  return { type: types.UPDATE_MATCH_INFO_FAILED, payload: error, matchId };
}
export function updateLiveOddsFailed(error, matchId) {
  return { type: types.UPDATE_LIVE_ODDS_FAILED, payload: error, matchId };
}
export function updatePreMatchOddsFailed(error, matchId) {
  return { type: types.UPDATE_PREMATCH_ODDS_FAILED, payload: error, matchId };
}
export function updateMatchDeltaFailed(error, matchId) {
  return { type: types.UPDATE_MATCH_DELTA_FAILED, payload: error, matchId };
}
export function updateMatchDetailsFailed(error, matchId) {
  return { type: types.UPDATE_MATCH_DETAILS_FAILED, payload: error, matchId };
}
export function updateStatsMatchSituationFailed(error, matchId) {
  return { type: types.UPDATE_STATS_MATCH_SITUATION_FAILED, payload: error, matchId };
}
export function updateStatsSeasonGoalsFailed(error, matchId) {
  return { type: types.UPDATE_STATS_SEASON_GOALS_FAILED, payload: error, matchId };
}
export function updateStatsSeasonOverUnderFailed(error, matchId) {
  return { type: types.UPDATE_STATS_SEASON_OVERUNDER_FAILED, payload: error, matchId };
}
export function updateStatsTeamLastXFailed(error, matchId) {
  return { type: types.UPDATE_STATS_TEAM_LASTX_FAILED, payload: error, matchId };
}
export function updateTournamentLiveTableFailed(error, matchId) {
  return { type: types.UPDATE_TOURNAMENT_LIVETABLE_FAILED, payload: error, matchId };
}
export function updateMatchTimelineFailed(error, matchId) {
  return { type: types.UPDATE_MATCH_TIMELINE_FAILED, payload: error, matchId };
}
export function updateStatsMatchOverUnderFailed(error, matchId) {
  return { type: types.UPDATE_STATS_MATCH_OVERUNDER_FAILED, payload: error, matchId };
}
export function updateStatsTeamVersusFailed(error, matchId) {
  return { type: types.UPDATE_STATS_TEAM_VERSUS_FAILED, payload: error, matchId };
}
export function updateStatsTeamWinPctFailed(error, matchId) {
  return { type: types.UPDATE_STATS_TEAM_WIN_PCT_FAILED, payload: error, matchId };
}
export function updateStatsTeamInfoFailed(error, matchId) {
  return { type: types.UPDATE_STATS_TEAM_INFO_FAILED, payload: error, matchId };
}
export function updateMatchFunFactsFailed(error, matchId) {
  return { type: types.UPDATE_MATCH_FUNFACTS_FAILED, payload: error, matchId };
}

// CLEANUP

export function removeMatch(payload) {
  return {
    type: types.REMOVE_MATCH,
    payload,
  };
}
