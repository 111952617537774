import {
  put,
  select,
  delay,
} from 'redux-saga/effects';
import { getNonMainMatches, getMatchId } from 'reducers';
import { normalizeOdds } from 'fishnet-api';
import { updatePreMatchOdds, removeMatch } from 'actions/fishnet/index';
import { HOUR } from '../../constants/app';

export default function* fishnetCleanup() {
  while (true) {
    yield delay(3 * HOUR);


    const nonMatches = yield select(getNonMainMatches, yield select(getMatchId));

    for (let i = 0; i < nonMatches.length; i++) {
      const matchId = nonMatches[i];
      // remove all fishnet info except match_info
      yield put(removeMatch(matchId));

      // wipe out odds related to match
      const emptyOdds = normalizeOdds({}, matchId);
      yield put(updatePreMatchOdds(emptyOdds));
    }
  }
}
