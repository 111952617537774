import loadScript from 'load-script';
import { ErrorLog } from 'utils/LoggerItem';

export default function load(widgetLoaderUrl, alias, lang) {
  return new Promise((resolve, reject) => {
    const attrs = {
      'data-autoinit': true,
      'data-loadcss': true,
      'data-loadscripts': true,
    };
    window.SRConfig = {
      debugLevel: 1,
      on: {
        srlive_initialized: () => {
          resolve(window.SRLive);
        },
      },
    };
    const options = {
      attrs,
    };
    let srliveURL = widgetLoaderUrl.replace(':clientAlias', alias);
    srliveURL = srliveURL.replace(':language', lang);

    if (!alias || alias === '') {
      const error = new ErrorLog(
        'WidgetLoader',
        `Wrong widgetloader URL. Check its parameters. Client alias: '${alias}'`,
      );
      reject(error);
    }
    return loadScript(srliveURL, options, err => {
      if (err) {
        const error = new ErrorLog(
          'WidgetLoader',
          `Problem with widgetloader URL. Check its parameters. Client alias: '${alias}'`,
        );
        reject(error);
      }
    });
  });
}
