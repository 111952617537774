import {
  take,
  call,
  fork,
  put,
  select,
  apply,
  delay,
  race,
  takeLatest,
} from 'redux-saga/effects';
import { ChannelStageConfigRequest } from 'bcms-api';
import { setInitialStageEnded, setReadyMatchId } from 'actions/bcmsConfig';
import {
  SET_ACTIVE_STAGE,
  SRLIVE_LOAD_SUCCESS,
  SRLIVE_RELOAD,
  SET_STATIC_VIDEO_ENDED,
  SET_STATIC_VIDEO_PLAYING,
  SET_ACTIVE_TEMP_STAGE,
  UNINTENDED_STAGE_CANCEL,
  STAGE_READY,
  SET_SHOWN_STAGE,
} from 'constants/actions';
import {
  STAGE_HIGHLIGHT,
  STAGE_PRE_MATCH,
  STAGE_POST_MATCH,
  STAGE_ANNOUNCEMENT_LOOP,
  STAGE_ANNOUNCEMENT,
  STAGE_BREAK,
  STAGE_LIVE,
  STAGE_WORLD_LOTTERY,
  STAGE_INITIAL,
  CHANNEL_TRAILER,
  STAGE_LOADING,
} from 'constants/bcms';
import {
  setSubstages,
  setActiveStage,
  setShownStage,
  setUnintendedStageCancel,
} from 'actions/stages';
import runAnnouncementStage from 'sagas/stages/announcement';
import runHighlights from 'sagas/stages/highlights';
import runPreMatchStage from 'sagas/stages/prematch';
import runPostMatchStage from 'sagas/stages/postmatch';
import runAnnouncementLoopStage from 'sagas/stages/announcementloop';
import runMatchBreakStage from 'sagas/stages/matchbreak';
import runLiveStage from 'sagas/stages/live';
import runWorldLotteryStage from 'sagas/stages/worldlottery';
import {
  getBcmsFeedStage,
  getUserId,
  getTemporaryStage,
  getStreamingFeedsURL,
  getStageDuration,
  getMatchId,
  getChannelId,
  getIsOperator,
} from 'reducers';
import { log } from 'actions/logger';
import { ErrorLog, DebugLog } from 'utils/LoggerItem';
import { updateStreamUrls } from 'actions/stream';
import {
  STAGE_RETRY_DELAY,
  STAGE_RETRIES,
  STAGE_REFILL_TIME,
  MIN,
} from '../constants/app';
import { srLiveLoadError } from '../actions/srLive';


function* runStageInternal(stageId) {
  switch (stageId) {
    case STAGE_HIGHLIGHT:
      yield call(runHighlights);
      break;
    case STAGE_PRE_MATCH:
      yield call(runPreMatchStage);
      break;
    case STAGE_POST_MATCH:
      yield call(runPostMatchStage);
      break;
    case STAGE_ANNOUNCEMENT_LOOP:
      yield call(runAnnouncementLoopStage);
      break;
    case STAGE_BREAK:
      yield call(runMatchBreakStage);
      break;
    case STAGE_LIVE:
      yield call(runLiveStage);
      break;
    case STAGE_WORLD_LOTTERY:
      yield call(runWorldLotteryStage);
      break;
    case STAGE_INITIAL:
      // run for duration, then cancel
      yield race([
        call(runAnnouncementStage),
        delay(yield select(getStageDuration, stageId)),
      ]);
      yield put(setInitialStageEnded());
      break;
    case STAGE_ANNOUNCEMENT:
    default:
      yield call(runAnnouncementStage);
      break;
  }
}


function* runStage(stageId) {
  const msgStart = new DebugLog(`Stage ${stageId} Started`, '', 1);
  yield put(log(msgStart));
  try {
    yield call(runStageInternal, stageId);
  } catch (error) {
    const msg = new ErrorLog(error.message, error.stack);
    yield put(log(msg));

    yield put(setUnintendedStageCancel(stageId, -1)); // keep current stage showing
  } finally {
    const msgCancel = new DebugLog(`Stage ${stageId} cancelled`, '', 1);
    yield put(log(msgCancel));
  }
}

function* requestStageConfig(stage) {
  const userId = yield select(getUserId);
  const channelId = yield select(getChannelId);
  const baseUrl = yield select(getStreamingFeedsURL);
  const isOperator = yield select(getIsOperator);

  const request = new ChannelStageConfigRequest(
    baseUrl,
    userId,
    channelId,
    stage,
    isOperator,
  );
  const channelConfig = yield apply(request, request.get);
  const { substages } = channelConfig.data;
  yield put(setSubstages(substages));
}

export function* doInitialStage() {
  yield fork(runStage, STAGE_INITIAL);

  // give some time to load feeds, breaks animation with late loads
  // other stage loads are hidden behind trailer
  yield race({
    loaded: take(STAGE_READY),
    timeout: delay(2000),
  });
  yield put(setShownStage(STAGE_INITIAL));
}

// retry stage if no other stage change has happened
function* retryOnDelay(stageId) {
  yield delay(STAGE_RETRY_DELAY);
  yield put(setActiveStage(stageId));
}

function* trailerCheck(doSwapBack = false) {
  const firstRace = yield race({
    playing: take(SET_STATIC_VIDEO_PLAYING),
    error: take(SET_STATIC_VIDEO_ENDED),
    timeout: delay(2500),
  });

  if (firstRace.error || firstRace.timeout) {
    const msg = new ErrorLog('Video Player Error', 'Skipped ChannelTrailer');
    yield put(log(msg));
  } else {
    yield race({
      ended: take(SET_STATIC_VIDEO_ENDED),
      stageChange: take(SET_ACTIVE_STAGE),
      timeout: delay(1.5 * MIN),
    });
  }
  if (doSwapBack) {
    yield put(setShownStage(yield select(getBcmsFeedStage))); // take feed one
  }
}

function* doTrailer(stageId, doSwapBack) {
  yield put(setShownStage(stageId));
  yield call(trailerCheck, doSwapBack);
}

// show channel trailer or marketing video
function* showTmpStage(action) {
  try {
    const doSwapBack = true;
    yield call(doTrailer, action.payload, doSwapBack);
  } catch (error) {
    yield put(setShownStage(yield select(getBcmsFeedStage)));
  }
}

// should be hidden behind trailer
function* srLiveFallback() {
  yield put(setActiveStage(STAGE_LOADING));
  yield take(SET_SHOWN_STAGE);
  yield delay(200);
  yield put(srLiveLoadError('Reload SRLive to force change of language'));
  yield race([
    take(SRLIVE_LOAD_SUCCESS),
    delay(1000),
  ]);
  yield put(setActiveStage(yield select(getBcmsFeedStage))); // take feed one, best guess
}

// triggered by SET_ACTIVE_STAGE
function* changeStage(action) {
  try {
    const { stageId, didInitalStage } = action.payload;

    // show loading
    if (stageId < 3 || stageId > 11) {
      yield put(setShownStage(STAGE_LOADING));
      if (stageId !== 0) {
        const msg = new ErrorLog(
          'Could Not Switch To Stage ' + stageId,
          'Bad StageId Given',
        );
        yield put(log(msg));
      }
      return;
    }

    // run stage
    yield call(requestStageConfig, stageId);
    yield put(updateStreamUrls([])); // remove old streams
    yield fork(runStage, stageId);

    const tStage = yield select(getTemporaryStage);
    // show trailer inbetween stages
    // skip if init to announcement stage
    // skip if to world lottery
    const notInitToAnn = !(didInitalStage && stageId === STAGE_ANNOUNCEMENT);
    if (notInitToAnn && (tStage && tStage.stageid !== -1) && stageId !== STAGE_WORLD_LOTTERY) {
      const noSwapBack = false;
      yield call(doTrailer, CHANNEL_TRAILER, noSwapBack);
    }

    yield put(setReadyMatchId(yield select(getMatchId)));
    yield put(setShownStage(stageId));
  } catch (error) {
    const msg = new ErrorLog(error.message, error.stack);
    yield put(log(msg));
  }
}

// create object with X retries per stage.
const retryObj = () => {
  const stageIds = [...Array(12).keys()];
  return stageIds.reduce((acc, cur) => {
    acc[cur] = {
      retries: STAGE_RETRIES,
    };
    return acc;
  }, {});
};

function* monitorStage() {
  // takeLatest cancels running fork on new action, non blocking
  yield takeLatest([SET_ACTIVE_STAGE], changeStage);
  yield takeLatest([SET_ACTIVE_TEMP_STAGE], showTmpStage);
  yield takeLatest([SRLIVE_RELOAD], srLiveFallback);

  let retry = retryObj();
  /*
    3 instant retries, no hide
    delayed retry, cancelled by stage change,
    so changing stage manually will still work, but it wont have any "retries" left
  */
  yield takeLatest([UNINTENDED_STAGE_CANCEL], function* (action) {
    const { stageId, fallbackId } = action.payload;

    if (retry[stageId] && retry[stageId].retries > 0) {
      yield put(setActiveStage(stageId));
      retry[stageId].retries--;
    } else {
      const msg = new ErrorLog(
        'Could Not Run Stage ' + stageId,
        'Max Retries',
      );
      yield put(log(msg));

      if (fallbackId !== -1) {
        yield put(setActiveStage(fallbackId));
      }

      yield race({
        retry: fork(retryOnDelay, stageId), // call?
        changed: take(SET_ACTIVE_STAGE),
      });
    }
  });

  // refill retries every x
  for (;;) {
    yield delay(STAGE_REFILL_TIME);
    retry = retryObj();
  }
}

export default monitorStage;
