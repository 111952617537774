import {
  call,
  put,
  select,
  fork,
  take,
  all,
} from 'redux-saga/effects';
import runCrawlerSaga from 'sagas/crawler';
import monitorStreamsUrls from 'sagas/streams';
import { cancelPollingStreamUrls, pollStreamUrls } from 'actions/stream';
import {
  getMatchId,
  getShowLmt,
  getMatchStatus,
  getVideoBreak,
} from 'reducers';
import { BCMS_BETSHOP_CONFIG_LOAD_SUCCESS } from 'constants/actions';
import {
  isEnded,
  isNotStarted,
} from 'utils/matchstatus';
import { UPDATE_MATCH_DELTA, UPDATE_MATCH_INFO } from 'actions/fishnet/actionTypes';
import controlScoreboard from '../components/scoreboard';


function* monitorMatchState() {
  let showLmt = yield select(getShowLmt);
  if (showLmt) {
    // start polling if lmt is turned off later
    for (;;) {
      yield take(BCMS_BETSHOP_CONFIG_LOAD_SUCCESS);
      showLmt = yield select(getShowLmt);
      if (!showLmt) {
        break;
      }
    }
  }
  let matchStatus = yield select(getMatchStatus, yield select(getMatchId));
  let prevMatchStatus = matchStatus;

  // initial check
  if (!isEnded(matchStatus) && !(yield select(getVideoBreak))) {
    yield put(pollStreamUrls());
  }

  for (;;) {
    if (isEnded(matchStatus)) {
      yield put(cancelPollingStreamUrls());
    } else if (isNotStarted(prevMatchStatus) && prevMatchStatus !== matchStatus) {
      yield put(pollStreamUrls());
    }
    prevMatchStatus = matchStatus;
    yield take([UPDATE_MATCH_INFO, UPDATE_MATCH_DELTA]);

    matchStatus = yield select(getMatchStatus, yield select(getMatchId));
  }
}


function* monitorMatchLive(matchId) {
  yield all([
    call(monitorMatchState),
    call(monitorStreamsUrls, matchId),
  ]);
}


export default function* runLiveStage() {
  const mId = yield select(getMatchId);
  yield fork(runCrawlerSaga);
  yield fork(controlScoreboard, mId);

  yield call(monitorMatchLive, mId);
}
