import { useState, useEffect } from 'react';
import { formatPreMatchOdds } from 'odds-conversion/index';

export const useOdds = (odds, oddsFormat, teams = null) => {
  const [Odds, setOdds] = useState(null);

  useEffect(() => {
    setOdds(formatPreMatchOdds(odds, oddsFormat, teams));
  }, [odds, oddsFormat, teams]); // dependencies

  return Odds;
};

export default useOdds;
