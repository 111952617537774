import { arrayToObject } from 'utils';
import { specialMarkets } from '../constants/enum';


export const updateMatchInfoState = (state, payload) => {
  if (!payload) {
    return state;
  }
  const matchIds = Object.keys(payload.entities.match);
  if (matchIds.length > 0) {
    const matchId = matchIds[0];
    const { events, match, ...matchinfo } = payload.entities.match[matchId];

    return {
      ...state,
      matchInfo: {
        ...state.matchInfo,
        [matchId]: {
          ...state.matchInfo[matchId],
          ...matchinfo,
          round: match.round,
          roundname: match.roundname,
        },
      },

      match: {
        ...state.match,
        [matchId]: {
          ...state.match[matchId],
          ...match,
          overtimeperiods: match.overtimeperiods,
        },
      },
    };
  } else {
    return state;
  }
};

// goes through a match and concats every oddsIdByMarket, then concats each match's
const getAllOddsIds = newMatches => (
  Object.values(newMatches).reduce(
    (prevMatch, curMatch) => prevMatch.concat(
      Object.values(curMatch.oddsIdsByMarket).reduce(
        (prevMarket, curMarket) => prevMarket.concat(curMarket), [],
      ),
    ), [],
  ));

const getAllMarketIds = (newBigMarket, payload, matchId) => {
  // remove ids with substring matchId
  const allMarketIds = Object.keys(newBigMarket).filter(bg => !bg.includes(matchId));

  const curMarketIds = Object.values(payload.matches).reduce(
    (prev, cur) => prev.concat(Object.keys(cur.oddsIdsByMarket).map(marketId => matchId + '_' + marketId)),
    [],
  );
    // add back this match's marketids from payload
  return allMarketIds.concat(curMarketIds);
};

// only include markets where extra is used for marketname
const getExtras = filteredMarkets => Object.values(filteredMarkets)
  .reduce((accumulator, cur) => {
    const oddsTypeId = cur.id.split('_')[1];
    if (specialMarkets.includes(oddsTypeId)) {
      accumulator[cur.id] = cur.extra;
    } else {
      accumulator[cur.id] = null;
    }

    return accumulator;
  }, {});


export const updateMatchMarkets = (state, payload) => {
  if (!payload || !Object.keys(payload.matches).length) {
    return state;
  }
  const matchId = Object.keys(payload.matches)[0];

  const newOdds = {
    ...state.markets.odds,
    ...payload.odds,
  };

  const newMatches = {
    ...state.markets.matches,
    [matchId]: { ...payload.matches[matchId] },
  };

  const newBigMarket = {
    ...state.markets.bigMarket,
    ...payload.bigMarket,
  };

  const newMLT = {
    ...state.markets.marketLookup,
    ...payload.marketLookupTable,
  };

  const newClientMatchIds = {
    ...state.clientMatchIds,
    ...payload.clientMatchId,
  };

  let marketLookupTable;
  const mltKeys = Object.keys(newMLT);
  const mltKeysOld = Object.keys(state.markets.marketLookup);
  // replace if arrays are not equal
  if (mltKeys.length === mltKeysOld.length && mltKeys.every(key => mltKeysOld.includes(key))) {
    marketLookupTable = state.markets.marketLookup;
  } else {
    marketLookupTable = newMLT;
  }

  const allMarketIds = getAllMarketIds(newBigMarket, payload, matchId);
  const allOddsIds = getAllOddsIds(newMatches);

  const filteredOdds = arrayToObject(
    Object.values(newOdds).filter(o => allOddsIds.includes(o.tbid)),
    'tbid',
  );
  const filteredMarkets = arrayToObject(
    Object.values(newBigMarket).filter(mm => allMarketIds.includes(mm.id)),
    'id',
  );
  const extras = getExtras(filteredMarkets);


  return {
    ...state,

    markets: {
      matches: {
        ...newMatches,
      },
      odds: {
        ...filteredOdds,
      },
      bigMarket: {
        ...filteredMarkets,
      },
      extras: {
        ...extras,
      },
      marketLookup: marketLookupTable,
    },
    clientMatchIds: {
      ...newClientMatchIds,
    },
  };
};
