import {
  call,
  put,
  select,
  fork,
} from 'redux-saga/effects';
import {
  updateScorboardBottom,
  updateScorboardTop,
} from 'actions/components';
import {
  getMatchId,
  getMatchTeams,
  getMatchTournament,
  getSBCompConfig,
  getMatchHomeLastMResult,
  getMatchAwayLastMResult,
  getMatchDetailsStat,
  getMatchHomeStatsMatchOU,
  getMatchAwayStatsMatchOU,
} from 'reducers';
import {
  UPDATE_MATCH_INFO,
  UPDATE_MATCH_DETAILS,
  UPDATE_MATCH_DELTA,
  UPDATE_STATS_TEAM_LASTX,
} from 'constants/actions';

import {
  SBB_TOURNAMENT_INFO,
  SCOREBOARD_TOP,
  SBT_NATIONALITY,
  SBT_LAST_MATCHES,
  SBT_POINTS_WON,
  SBT_SERVICE_POINTS_WON,
  SBT_ACES,
  SBT_FORM_PERC,
  SBT_MAX_POINTS_IN_A_ROW,
  SBT_SERVICE_ERRORS,
  SBT_RECEIVER_POINTS_WON,
  SBT_POINTS_PER_MATCH,
  SBT_SETS_PER_MATCH,
} from 'constants/components';
import { UPDATE_STATS_MATCH_OVERUNDER } from 'actions/fishnet/actionTypes';
import { sagaRunTakeLatest } from '../../utilSagas';

// SCOREBOARD Bottom

function* onMatchInfo() {
  const tour = yield select(getMatchTournament, yield select(getMatchId));

  yield put(updateScorboardBottom({
    slide: SBB_TOURNAMENT_INFO,
    status: !!tour,
  }));
}

function* onMatchInfoTop() {
  const matchId = yield select(getMatchId);
  const teams = yield select(getMatchTeams, matchId);

  if (teams) {
    yield put(updateScorboardTop({
      slide: SBT_NATIONALITY,
      status: !!teams.home[0].cc,
    }));
  }
}

function* getStat(key) {
  const mId = yield select(getMatchId);
  const config = (yield select(getSBCompConfig, SCOREBOARD_TOP, key));
  const mdKey = config ? config.key : null;

  return yield select(getMatchDetailsStat, mId, mdKey);
}

function* onMatchDetails() {
  yield put(updateScorboardTop({
    slide: SBT_ACES,
    status: (yield call(getStat, SBT_ACES)) != null,
  }));

  yield put(updateScorboardTop({
    slide: SBT_POINTS_WON,
    status: (yield call(getStat, SBT_POINTS_WON)) != null,
  }));

  yield put(updateScorboardTop({
    slide: SBT_SERVICE_POINTS_WON,
    status: (yield call(getStat, SBT_SERVICE_POINTS_WON)) != null,
  }));

  yield put(updateScorboardTop({
    slide: SBT_RECEIVER_POINTS_WON,
    status: (yield call(getStat, SBT_RECEIVER_POINTS_WON)) != null,
  }));

  yield put(updateScorboardTop({
    slide: SBT_MAX_POINTS_IN_A_ROW,
    status: (yield call(getStat, SBT_MAX_POINTS_IN_A_ROW)) != null,
  }));

  yield put(updateScorboardTop({
    slide: SBT_SERVICE_ERRORS,
    status: (yield call(getStat, SBT_SERVICE_ERRORS)) != null,
  }));
}

function* onStatsMatch() {
  const matchId = yield select(getMatchId);

  const homeS = yield select(getMatchHomeStatsMatchOU, matchId);
  const awayS = yield select(getMatchAwayStatsMatchOU, matchId);


  yield put(updateScorboardTop({
    slide: SBT_POINTS_PER_MATCH,
    status: (homeS !== null && awayS !== null),
  }));

  yield put(updateScorboardTop({
    slide: SBT_SETS_PER_MATCH,
    status: (homeS !== null && awayS !== null),
  }));
}

function* onTeamLastX() {
  const matchId = yield select(getMatchId);
  const home = yield select(getMatchHomeLastMResult, matchId);
  const away = yield select(getMatchAwayLastMResult, matchId);

  yield put(updateScorboardTop({
    slide: SBT_LAST_MATCHES,
    status: !!((home && home.length) && (away && away.length)),
  }));

  yield put(updateScorboardTop({
    slide: SBT_FORM_PERC,
    status: !!((home && home.length) && (away && away.length)),
  }));
}


export const volleyballTop = [
  fork(sagaRunTakeLatest([UPDATE_MATCH_INFO, UPDATE_MATCH_DELTA], onMatchInfoTop)),
  fork(sagaRunTakeLatest([UPDATE_MATCH_DETAILS], onMatchDetails)),
  fork(sagaRunTakeLatest([UPDATE_STATS_TEAM_LASTX], onTeamLastX)),
  fork(sagaRunTakeLatest([UPDATE_STATS_MATCH_OVERUNDER], onStatsMatch)),
];

export const volleyballBottom = [
  fork(sagaRunTakeLatest([UPDATE_MATCH_INFO, UPDATE_MATCH_DELTA], onMatchInfo)),
];
