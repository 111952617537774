import { ErrorLog } from 'utils/LoggerItem';
import lcrTranslations from 'ClientsBranding/configs/lcrTranslations';

class Translator {
  constructor() {
    if (!Translator.instance) {
      this.words = {};
      this.lcrWords = { ...lcrTranslations };
      this.language = 'en';
      Translator.instance = this;
    }
  }

  loadDictionary(request, language) {
    return request.get().then(
      data => {
        this.language = language;
        const doc = data.data;

        if (doc) {
          const words = doc;
          Object.keys(words).forEach(key => {
            const item = { [key.toLowerCase()]: words[key] };
            this.words = { ...this.words, ...item };
          });
        } else {
          throw new Error('No translations document found!');
        }
      },
      error => {
        throw new ErrorLog(`Translator error: ${error}`);
      },
    );
  }

  t(key) {
    if (!key) {
      return key;
    }

    let retVal = key;
    if (this.words && this.words[key.toLowerCase()]) {
      retVal = this.words[key.toLowerCase()].translation;
    }

    if (
      this.lcrWords
      && this.lcrWords[this.language]
      && this.lcrWords[this.language][key.toLowerCase()]
    ) {
      retVal = this.lcrWords[this.language][key.toLowerCase()];
    }

    return retVal;
  }
}

const instance = new Translator();
export default instance;
