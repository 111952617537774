import { fork, select } from 'redux-saga/effects';
import { getMatchId } from 'reducers';
import controlScoreboard from '../components/scoreboard';
import controlStatSlides from '../components/statslides';


export default function* runPostMatchStage() {
  yield fork(controlStatSlides, yield select(getMatchId));
  yield fork(controlScoreboard, yield select(getMatchId));
}
