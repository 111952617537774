import {
  put,
  select,
  call,
  delay,
  take,
  fork,
} from 'redux-saga/effects';
import {
  hideOpener,
} from 'actions/stages';
import {
  getStageConfig,
  getSubstages,
  getMatchId,
  getChannelId,
} from 'reducers';
import {
  STAGE_PRE_MATCH,
  OPENER,
} from 'constants/bcms';
import { BLOCK_HERE, CHANNELS_ESOCCER_ONLY, SEC } from 'constants/app';
import { waitForMatchData } from '../utilLCR';
import controlStatSlides from '../components/statslides';


export default function* runPreMatchStage() {
  const stageConfig = yield select(getStageConfig, STAGE_PRE_MATCH);
  const substages = yield select(getSubstages);
  const matchId = yield select(getMatchId);
  const channelId = yield select(getChannelId);

  yield call(waitForMatchData, matchId);

  if (CHANNELS_ESOCCER_ONLY.includes(channelId)) {
    yield delay(30 * SEC);
    yield put(hideOpener());
    yield fork(controlStatSlides, yield select(getMatchId));
  } else if (substages && substages.length && substages.indexOf(OPENER) === -1) {
    // substages has items and OPENER isnt one of them
    yield put(hideOpener());
    yield fork(controlStatSlides, yield select(getMatchId));
  } else if (substages.length === 1 && substages[0] === OPENER) {
    // show opener only. do not switch to stats afterwards
  } else {
    yield delay(stageConfig.openerDuration * 1000);
    yield put(hideOpener());
    yield fork(controlStatSlides, yield select(getMatchId));
  }
  // block here to be able to catch errors
  yield take(BLOCK_HERE);
}
