import {
  SIR_LOADING,
  SIR_LOAD_ERROR,
  SIR_LOAD_SUCCESS,
} from 'constants/actions';


export function sirLoading() {
  return { type: SIR_LOADING };
}

export function sirLoadSuccess() {
  return { type: SIR_LOAD_SUCCESS };
}

export function sirLoadError(err) {
  return { type: SIR_LOAD_ERROR, payload: err };
}
