import {
  select,
  apply,
} from 'redux-saga/effects';
import {
  getClientAlias,
  getLanguage,
  getQueryFeed,
} from 'reducers';
import { MatchTimelineRequest, MatchStartRecording } from 'fishnet-api';
import * as config from 'Config/srlive';

function* getOffset(id, replayParam) {
  try {
    let uts = -1;
    let offset = -1;
    let startAtStart = 1;

    const clientAlias = yield select(getClientAlias);
    const language = yield select(getLanguage);
    const qFeed = yield select(getQueryFeed);

    const request = new MatchTimelineRequest(id, clientAlias, language, qFeed);
    const obj = yield apply(request, request.get);

    const splt = replayParam.split('-');

    // start replay relative to start of match(1) or end of match(2)
    // 1-100 = 100 sec after start
    // 2-500 = 500 sec from end
    if (splt.length === 1) {
      if (splt[0] === '1') {
        startAtStart = true;
        offset = -0;
      } else {
        startAtStart = false;
        offset = 500;
      }
    } else if (splt.length === 2) {
      if (splt[0] === '1') {
        startAtStart = true;
        offset = -parseInt(splt[1], 10);
      } else {
        startAtStart = false;
        offset = parseInt(splt[1], 10);
      }
    }

    const arr = obj.entities.match[Object.keys(obj.entities.match)[0]].events;
    for (let i = 0; i < arr.length; i++) {
      const event = arr[i];


      if (startAtStart) {
        if (event.type === 'match_started') {
          uts = event.uts;

          break;
        }
      } else if (event.type === 'match_ended') {
        uts = event.uts;

        break;
      }
    }
    if (uts > 0) {
      const d = new Date(uts * 1000);
      offset = Math.trunc((Date.now() - d) / 1000) + offset + 0;
      try {
        const req = new MatchStartRecording(id, clientAlias, language, config.fnReplayUrl, offset);
        yield apply(req, req.get);
      } catch (error) {
        //
      }
    }

    return offset;
  } catch (error) {
    return -1;
  }
}

export default getOffset;
