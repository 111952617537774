import React from 'react';

import { useImportImageNewDesign } from 'hooks';

import styles from 'newDesign/modules/auth/styles/AuthStyles.scss';

const PasswordFooter = () => {
  const footerLogo = useImportImageNewDesign('auth/login/footer-logo.png');

  return (
    <div className={`${styles.password_footer} flex flex-row w-full px-8 pt-2`}>
      <div className={'flex flex-col w-1/2'}>
        <p className={`${styles.footer_company}`}>© Sportradar AG 2024</p>
        <p>Betradar, the market leading supplier of sports and betting-related data services.<br />
          Support: +41 71 517 72 00<br />
          Website: <a href='https://www.betradar.com'>www.betradar.com</a>
        </p>
      </div>
      <div className={'flex flex-col text-end items-end w-1/2'}>
        <img src={footerLogo} alt={'Footer logo'} className={`${styles.footer_logo}`} />
        <p className={'pt-4'}>BCMS v4.2.2</p>
      </div>
    </div>
  );
};

export default PasswordFooter;
