import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import styles from 'newDesign/modules/auth/styles/AuthStyles.scss';
import { useDispatch } from 'react-redux';
import { LCR_AUTH_CHECK } from 'constants/actions';

const ResetPasswordForm = () => {
  const dispatch = useDispatch();
  const [passwordFields, setPasswordFields] = useState({
    password: '',
    repeatPassword: '',
  });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const passwordRegexp = new RegExp(/^(?:[a-z]()|\d()|[A-Z]()|[^\n\w]()){16,}(?:\1\2(?:\3|\4)|\3\4(?:\1|\2))$/);

  const validateValues = passwordValues => {
    const errorsObject = {};
    const regexpCheck = passwordRegexp.test(passwordValues.password);

    if (!regexpCheck) {
      errorsObject.password = 'Password is not matching required parameters';
    }

    if (passwordValues.password !== passwordValues.repeatPassword) {
      errorsObject.match = 'Passwords are not the same';
    }
    return errorsObject;
  };

  const handleChange = event => {
    setPasswordFields({ ...passwordFields, [event.target.name]: event.target.value });
  };

  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get('token');
  const username = queryParams.get('u');

  const successMessage = (
    <div className='flex flex-row'>
      Your password has been reset. Click
      <Link
        className='ml-1 mr-1 text-blue-500 hover:text-blue-800'
        to='/login'
      >
        here
      </Link>
      to go to the login page.
    </div>
  );

  useEffect(() => {
    dispatch({
      type: LCR_AUTH_CHECK,
    });
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      setErrorMessage('');
      const { password } = passwordFields;

      // TODO: Integration, post MVP, move to auth service
      fetch('/auth/password-reset', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          token,
          new_password: password,
        }),
      })
        .then(response => {
          if (!response.ok) {
            setErrorMessage('Failed to process password reset. Request new reset link.');
          } else {
            setPasswordFields({
              password: '',
              repeatPassword: '',
            });
            setFormSubmittedSuccessfully(true);
          }
        })
        .catch(() => {
          setErrorMessage('Failed to process password reset. Request new reset link.');
        })
        .finally(() => setSubmitting(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleSubmit = event => {
    event.preventDefault();
    const validationErrors = validateValues(passwordFields);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setSubmitting(true);
    }
  };

  return (
    <Fragment>
      {!formSubmittedSuccessfully && (
        <Fragment>
          <div className={'flex flex-row'}>
            <h2>Please enter your new password. Password should meet strict password strength requirements: </h2>
          </div>
          <div className={'flex flex-row'}>
            <ul className={'list-decimal ml-4 mt-4'}>
              <li>At least 16 characters long.</li>
              <li>Contains characters from three of the following categories:</li>
              <ul className={'list-disc ml-8'}>
                <li>Uppercase letters</li>
                <li>Lowercase letters</li>
                <li>Numbers</li>
                <li>Non-alphanumeric characters (special characters)</li>
              </ul>
            </ul>
          </div>
          <div className={'flex my-4'}>
            <form onSubmit={handleSubmit} className={'flex flex-col'}>
              <div className={'flex flex-col w-full'}>
                <label htmlFor='Password'>Password<span style={{ color: 'red' }}>*</span>
                  <input
                    type='password'
                    name='password'
                    className={`py-2 px-3 my-2 w-full ${styles.login_input}`}
                    value={passwordFields.password}
                    onChange={handleChange}
                    style={{
                      border: errors.password || errors.match ? '1px solid red' : null,
                    }}
                  />
                </label>
                {errors.password && (
                  <p className={`${styles.error_message}`}>{errors.password}</p>
                )}
              </div>
              <div className={'flex flex-col w-full my-4'}>
                <label htmlFor='repeatPassword'>Repeat password<span style={{ color: 'red' }}>*</span>
                  <input
                    type='password'
                    name='repeatPassword'
                    className={`py-2 px-3 my-2 w-full ${styles.login_input}`}
                    value={passwordFields.repeatPassword}
                    onChange={handleChange}
                    style={{
                      border: errors.match ? '1px solid red' : null,
                    }}
                  />
                </label>
                {errors.match && (
                  <p className={`${styles.error_message}`}>{errors.match}</p>
                )}
              </div>
              <div className={'flex flex-row justify-center'}>
                <button type='submit' className={`${styles.submit_button}`} disabled={submitting}>
                  {submitting ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </Fragment>
      )}
      {errorMessage && (
        <div className={'flex flex-row'}>
          <h1 style={{ color: 'red' }}>{errorMessage}</h1>
        </div>
      )}

      {formSubmittedSuccessfully && (
        successMessage
      )}
    </Fragment>
  );
};

export default ResetPasswordForm;
