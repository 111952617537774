// TODO: remove this component (post Facelift refactoring)
import {
  call,
  put,
  fork,
  select,
  take,
  delay,
  all,
  takeLatest,
} from 'redux-saga/effects';
import * as config from 'Config/srlive';
import load from 'srlive-loader';
import {
  srLiveLoading,
  srLiveLoadSuccess,
  srLiveLoadError,
} from 'actions/srLive';
import {
  getMatchId,
  getLastMatch,
  getScoreBreak,
  getClockBreak,
  getClientAlias,
  getLanguage,
  getMatchIdVisibility,
  getLmtColors,
  getActiveStage,
  getMatchSportId,
  getBcmsFeedStage,
  getUseSRDev,
} from 'reducers';
import {
  BCMS_BETSHOP_CONFIG_LOAD_SUCCESS,
  SRLIVE_LOAD_ERROR,
} from 'constants/actions';
import {
  STAGE_BREAK,
} from 'constants/bcms';
import { ErrorLog } from 'utils/LoggerItem';
import { sagaTryLog, sagaTakeRunOnce } from './utilSagas';
import { SEC } from '../constants/app';

function* monitorMatchId() {
  let matchID = null;
  for (;;) {
    const newMatchID = yield select(getMatchId);
    if (newMatchID !== matchID) {
      window.SRLive.event.trigger('set_match_focus', {
        matchId: newMatchID,
        idContainer: 'primaryMatch',
      });
      matchID = newMatchID;
    }
    yield take(BCMS_BETSHOP_CONFIG_LOAD_SUCCESS);
  }
}

function* monitorTicker() {
  let lastMatch = null;
  for (;;) {
    const newMatch = yield select(getLastMatch);
    if (lastMatch !== newMatch) {
      window.SRLive.event.trigger('set_match_focus', {
        matchId: newMatch,
        idContainer: 'matchTicker',
      });
      lastMatch = newMatch;
    }
    yield take(BCMS_BETSHOP_CONFIG_LOAD_SUCCESS);
  }
}

function* monitorScoreAndClockBreak() {
  let prevScoreBreak = 0;
  let prevClockBreak = 0;
  for (;;) {
    const nextScoreBreak = yield select(getScoreBreak);
    const matchId = yield select(getMatchId);

    if (prevScoreBreak !== nextScoreBreak) {
      window.SRLive.event.trigger('scorebreak', {
        enabled: nextScoreBreak,
        matchId,
      });
      prevScoreBreak = nextScoreBreak;
    }

    const nextClockBreak = yield select(getClockBreak);
    if (prevClockBreak !== nextClockBreak) {
      window.SRLive.event.trigger('clockbreak', {
        enabled: nextClockBreak,
        matchId,
      });
      prevClockBreak = nextClockBreak;
    }

    yield take(BCMS_BETSHOP_CONFIG_LOAD_SUCCESS);
  }
}

function* addSingletonWidgets() {
  const lmtColors = yield select(getLmtColors);

  // Add styler widget
  window.SRLive.addWidget('streaming.styler', {
    colors: {
      primary: null,
      secondary: null,
      lmtHome: lmtColors.lmtHomeColor ? `#${lmtColors.lmtHomeColor}` : null,
      lmtAway: lmtColors.lmtAwaycolor ? `#${lmtColors.lmtAwaycolor}` : null,
    },
  });

  const showMatchId = yield select(getMatchIdVisibility);
  window.SRLive.addWidget('streaming.clientMatchIdProvider', {
    hideMatchIdContainer: !showMatchId,
  });
}

function* monitorStage() {
  let prevStage = null;
  for (;;) {
    let newStage = null;
    const matchID = yield select(getMatchId);
    const sportId = yield select(getMatchSportId, matchID);
    if (sportId === 5) { // 5 - Tennis
      newStage = yield select(getBcmsFeedStage);
    } else {
      newStage = yield select(getActiveStage);
    }
    if (newStage === STAGE_BREAK && prevStage !== STAGE_BREAK) {
      window.SRLive.event.trigger('operator_event', {
        actionType: 'matchPauseState', active: true,
      });
    } else if (prevStage === STAGE_BREAK && newStage !== STAGE_BREAK) {
      window.SRLive.event.trigger('operator_event', {
        actionType: 'matchPauseState', active: false,
      });
    }
    prevStage = newStage;
    yield take(BCMS_BETSHOP_CONFIG_LOAD_SUCCESS);
  }
}

function* runTasks() {
  yield all([
    call(addSingletonWidgets),
    call(monitorMatchId),
    call(monitorTicker),
    call(monitorStage),
    call(monitorScoreAndClockBreak),
  ]);
}

function* loadSrLive() {
  try {
    if (window.SRLive && window.SRLive.addWidget) {
      yield take(BCMS_BETSHOP_CONFIG_LOAD_SUCCESS); // wait for new alias/lang
      window.SRLive = null;
    }

    const clientAlias = yield select(getClientAlias);
    const language = yield select(getLanguage);
    const SRDev = yield select(getUseSRDev);
    let url = '';

    if (SRDev === 1) {
      url = config.widgetLoaderUrlDevRaw;
    } else if (SRDev === 2) {
      url = config.widgetLoaderUrlDev;
    } else {
      url = config.widgetLoaderUrlProd;
    }

    yield call(load, url, clientAlias, language);
    const sr = window.SRLive;
    if (!!sr && !!sr.event && !!sr.addWidget) {
      yield fork(runTasks);
      yield put(srLiveLoadSuccess());
    } else {
      throw new ErrorLog('SRLive Load Failed');
    }
    yield take(SRLIVE_LOAD_ERROR);
  } catch (error) {
    yield delay(10 * SEC);
    yield put(srLiveLoadError(error));
  }
}

export default function* monitorSrLive() {
  yield put(srLiveLoading());
  yield takeLatest([SRLIVE_LOAD_ERROR], sagaTryLog(loadSrLive)); // reload on error

  yield call(sagaTakeRunOnce(BCMS_BETSHOP_CONFIG_LOAD_SUCCESS, loadSrLive)); // first time load
}
