import { SEC } from './app';
import { sport } from './enum';

export const SCOREBOARD_TOP = 'TOP';
export const SCOREBOARD_MAIN = 'MAIN';
export const SCOREBOARD_BOTTOM = 'BOTTOM';

/*
 * Scoreboard Bottom
 */

export const SBB_TOURNAMENT_INFO = 0;
export const SBB_LEAGUE_STANDINGS = 1;
export const SBB_TOURNAMENT_INFO_STR = 'TOURNAMENT_INFO';
export const SBB_LEAGUE_STANDINGS_STR = 'LEAGUE_STANDINGS';

export const SBB_TOURNAMENT_INFO_DURATION = 120 * SEC;
export const SBB_LEAGUE_STANDINGS_DURATION = 50 * SEC;
/*
 * Scoreboard Top
 */
export const SBT_SLIDE_DURATION = 15 * SEC;

export const SBT_LAST_MATCHES = 0;
export const SBT_GOALS_SCORED = 1;
export const SBT_GOALS_CONCEDED = 2;
export const SBT_LEAGUE_RANK = 3;
export const SBT_NATIONALITY = 4;
export const SBT_ATT_STRENGTH = 5;
export const SBT_CORNER_KICKS = 6;
export const SBT_1ST_HALF_SCORE = 7;
export const SBT_ENDED = 8;
export const SBT_GOALS_SCORED_16_30 = 9;
export const SBT_GOALS_CONCEDED_16_30 = 10;

// TENNIS
export const SBT_FORM_PERC = 11;
export const SBT_POINTS_WON = 12;
export const SBT_BREAK_POINTS_WON = 13;
export const SBT_DOUBLE_FAULTS = 14;
export const SBT_SERVICE_GAMES_WON = 15;
export const SBT_MAX_POINTS_IN_A_ROW = 16;
export const SBT_1ST_SERVE_PTS_WON = 17;
export const SBT_2ND_SERVE_PTS_WON = 18;
export const SBT_SERVICE_POINTS_WON = 19;
export const SBT_MAX_GAMES_IN_A_ROW = 20;
export const SBT_TIEBREAKS_WON = 21;
export const SBT_RECEIVER_POINTS_WON = 22;
export const SBT_2ND_SERVE_SUCCESSFUL = 23;
export const SBT_GAMES_WON = 24;
export const SBT_ACES = 25;
export const SBT_1ST_SERVE_SUCCESSFUL = 26;

// BASKETBALL
export const SBT_FT = 27;
export const SBT_REBOUNDS = 28;
export const SBT_BIGGEST_LEAD = 29;
export const SBT_3PT = 30;
export const SBT_2PT = 31;
export const SBT_TIME_SPENT_IN_LEAD = 32;


// ICE HOCKEY
export const SBT_PUCK_POSSESION = 33;
export const SBT_GOALS_IN_POWERPLAY = 34;
export const SBT_GOALS_SHORTHANDED = 35;
export const SBT_SUSPENSIONS = 36;


// Volleyball
export const SBT_SERVICE_ERRORS = 37;
export const SBT_POINTS_PER_MATCH = 38;
export const SBT_SETS_PER_MATCH = 39;

/*
 * Statistics slides
 */
export const STATSLIDES = 'statslides';
export const BETTINGSUGGESTIONS = 'bettingsuggestions';
export const AMOUNT_OF_MATCHES_RESULT_BOX = 5;

export const SS_SLIDE_DURATION = 14 * SEC; // scrolls up and down on 5s interval

export const SS_LAST_MATCHES = 0;
export const SS_PREVIOUS_MEETINGS = 1;
export const SS_LIVESTATS = 2;
export const SS_FORM_CLAY = 3;
export const SS_FORM_HARDCOURT = 4;
export const SS_FORM_INDOOR = 5;
export const SS_FORM_GRASS = 6;
export const SS_FORM_OVERALL = 7;
export const SS_TIMELINE = 8;
export const SS_LEAGUE_TABLE = 9;

/**
 * Slides available only for Live Match => BREAK STAGE:
 */
export const SS_BREAK_STAGE = [
  SS_LIVESTATS, SS_TIMELINE,
];

export const SS_PREMATCH_SUBSTAGE_PERFORMANCE = [
  SS_FORM_OVERALL, SS_FORM_CLAY, SS_FORM_HARDCOURT, SS_FORM_INDOOR, SS_FORM_GRASS,
];

export const PERIOD_BASE_SPORTS = [sport.tennis, sport.table_tennis];
