import React from 'react';
import cssModule from 'react-css-modules';
import styles from 'styles/widgets/LoadingSpinner.scss';

const LoadingSpinner = () => (
  <div styleName='spinnerWrapper'>
    <div styleName='spinner' />
  </div>
);

export default cssModule(LoadingSpinner, styles);
