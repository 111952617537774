export const MATCHES_SHOWN = 4;
export const NEXT_MATCHES_POLL_INTERVAL = 60 * 1000;
export const MATCH_INFO_POLL_INTERVAL = 30 * 1000;
export const LIVE_ODDS_POLL_INTERVAL = 3 * 1000;
export const MATCH_TIMELINE_DELTA_POLL_INTERVAL = 3 * 1000;
export const PREMATCH_ODDS_POLL_INTERVAL = 60 * 1000;
export const PREMATCH_MATCH_DETAILS_POLL_INTERVAL = 60 * 1000;
export const MATCH_SITUATION_POLL_INTERVAL = 10 * 1000;
export const SEASON_STATS_POLL_INTERVAL = 15 * 60 * 1000;
export const LIVE_MATCH_DETAILS_POLL_INTERVAL = 10 * 1000;
export const POLL_ONCE = -1;
export const TOURNAMENT_INTERVAL = 15 * 60 * 1000;

export const OVERALL = '0';
export const CLAY = '1';
export const HARDCOURT = '2';
export const INDOOR = '3';
export const GRASS = '4';

export const GROUND_ARRAY = ['OVERALL', 'CLAY', 'HARDCOURT', 'INDOOR', 'GRASS'];
