import {
  put,
  select,
  apply,
  call,
} from 'redux-saga/effects';
import {
  getClientAlias,
  getLanguage,
  getQueryFeed,
} from 'reducers';
import { MatchFunFactHighlight } from 'fishnet-api';
import { updateMatchFunFacts, updateMatchFunFactsFailed } from 'actions/fishnet/index';
import { pollTillResponse } from '../utilLCR';


export function* loadFunFact(matchId) {
  const clientAlias = yield select(getClientAlias);
  const language = yield select(getLanguage);
  const qFeed = yield select(getQueryFeed);

  const request = new MatchFunFactHighlight(matchId, clientAlias, language, qFeed);
  const response = yield apply(request, request.get);


  if (response && !response.error) {
    const phraseArr = Object.values(response);
    yield put(updateMatchFunFacts(phraseArr, matchId));
  } else if (response && response.error) {
    yield put(updateMatchFunFactsFailed(response, matchId));
    throw response;
  } else {
    throw new Error('Update MatchFunFactHighlight failed');
  }
}


function* pollFunFact(matchId) {
  yield call(pollTillResponse, { logLevel: 5 }, loadFunFact, matchId); // poll this endpoint once
}

export default pollFunFact;
