/* eslint-disable no-underscore-dangle */
/* eslint-disable max-classes-per-file */
import * as config from 'Config/srlive';
import { APIError } from 'utils/LoggerItem';
import lcrRequest from 'lcrRequest';
import {
  oddsT,
  totalMarkets,
  spreadMarkets,
  handicapMarkets,
  sport as sportEnum,
} from 'constants/enum';
import { arrayToObject, transformAPIError } from 'utils';
import { normalize, schema } from 'normalizr';
import { ESPORTS_BATTLE } from 'constants/app';
import { get as _get } from 'lodash';

function getTimezone() {
  // NOTE: timezone is hardcoded to Europe:Berlin.
  // Initially "jstimezonedetect.determine()" was used
  // but the problem is because "determine" is to detailed with timezones!
  // Fishnet only supports a certain set of all the timezones.
  return 'Europe:Berlin';
}

const defaultOptions = {
  language: 'en',
  alias: 'whitelabel1',
  tz: getTimezone(),
};

export class FishnetFeed {
  constructor(path, options = defaultOptions, qUrlBase = null, offset = -1) {
    const urlBase = qUrlBase || config.fishnetFeedsUrl;
    const offsetParam = offset > 0 ? '&offset=' + offset : '';
    const opt = { ...defaultOptions, ...options };

    this.url = `${urlBase}${opt.alias}/${opt.language}/${opt.tz}/gismo/${path}${offsetParam}`;
    this.maxAge = 60;
  }

  get() {
    return lcrRequest.get(this.url)
      .then(response => {
        if (response && response.doc && response.doc[0]) {
          const { data, event } = response.doc[0];
          if (event === 'exception' && data) {
            throw new APIError(
              data.name + ': ' + (response.queryUrl || data.query),
              data.message,
              data.message, // fishnet exception
            );
          }

          if (data) {
            this.maxAge = response._maxage || 60;
            return data;
          }
        }
        const splitUrl = this.url.split('/');
        const feed = splitUrl[splitUrl.length - 2] + '/' + splitUrl[splitUrl.length - 1];

        throw new APIError(
          'Fishnet Error',
          feed + ': no document',
        );
      })
      .catch(transformAPIError);
  }
}


const allOutcomes = markets => markets.reduce((acc, cur) => {
  const outcomes = Object.values(cur.outcomes);
  const formattedOutcomes = outcomes.map((outcome, i) => {
    const oc = { ...outcome };
    oc.betstop = cur.betstop;

    if (cur.oddstypeid === oddsT.live.tennis_number_of_sets) {
      oc.name += ' '; // to avoid replacing '2' with teamname
    }

    // add hcp to outcome
    if (cur.hcp) {
      if (totalMarkets.includes(cur.oddstypeid)) {
        oc.totalHcp = parseFloat(cur.hcp.value);
      } else if (spreadMarkets.includes(cur.oddstypeid)) {
        oc.spreadHcp = i === 0 ? parseFloat(cur.hcp.value) : -1 * parseFloat(cur.hcp.value);
      }
    }

    oc.marketId = cur.oddstypeid; // required for over/under swap
    return oc;
  });

  return acc.concat(formattedOutcomes);
}, []);


const outcomeIdsByMarket = markets => markets.reduce((accumulator, cur) => {
  accumulator[cur.oddstypeid] = Object.values(cur.outcomes).map(o => o.tbid);

  return accumulator;
}, {});

const marketInfo = (markets, matchId) => markets.reduce((accumulator, cur) => {
  const id = matchId + '_' + cur.oddstypeid; // cur.matchid + '_' + cur.oddstypeid;
  let hcp = { ...cur.hcp };
  if (!cur.hcp) { // check feed one for undefined
    if (handicapMarkets.includes(cur.oddstypeid)) {
      // create hcp obj from extra
      hcp = {
        name: 'HandicapCustom',
        id: 9999,
        value: cur.extra, // of type 0:2
        feedValue: cur.extra,
      };
    } else {
      hcp = null;
    }
  } else {
    hcp.feedValue = hcp.value;
    if (!hcp.value.includes(':')) {
      hcp.value = parseFloat(hcp.value);
    }
  }

  const marketObj = {
    id,
    livebet: cur.livebet,
    extra: cur.extra,
    hcp,
    betstop: cur.betstop, // will be false until it is removed from filter func
  };
  accumulator[id] = marketObj;

  return accumulator;
}, {});

const marketLookup = markets => markets.reduce((accumulator, cur) => {
  accumulator[cur.oddstypeid] = {
    oddsType: cur.oddstype,
    oddsTypeShort: cur.oddstypeshort,
    oddsTypeId: cur.oddstypeid,
  };

  return accumulator;
}, {});

export const normalizeOdds = (match, matchId) => {
  // check for clientmatchid, livebets dont have it?
  const cMId = Object.values(match).map(m => m.clientmatchid).find(c => c !== '');

  // still block betstopped markets here temporary until rest is setup to handle it
  const markets = Object.values(match)
    .filter(m => m.active); // filter out not active
  const outcomes = allOutcomes(markets);
  const outcomeIds = outcomeIdsByMarket(markets);
  const mInfo = marketInfo(markets, matchId);
  const lookupTable = marketLookup(markets);

  // empty request also returns this just with empty objects
  return {
    matches: {
      [matchId]: {
        oddsIdsByMarket: outcomeIds,
      },
    },
    odds: arrayToObject(outcomes, 'tbid'),
    bigMarket: mInfo,
    marketLookupTable: lookupTable,
    clientMatchId: cMId ? {
      [matchId]: cMId,
    } : undefined,
  };
};

class OddsRequest extends FishnetFeed {
  get() {
    return super.get()
      .then(originalData => {
        if (!originalData || (originalData && originalData.error)) {
          throw originalData;
        }
        let match;
        let matchId;
        if (Array.isArray(originalData) || !Object.keys(originalData).length) {
          match = {};
          matchId = this.matchId;
        } else {
          match = Object.values(originalData).shift();
          matchId = Object.keys(originalData).shift();
        }
        return normalizeOdds(match, matchId);
      })
      .catch(error => error);
  }
}

export class PreMatchOddsRequest extends OddsRequest {
  constructor(matchId, alias, language, qUrlBase = null) {
    super(`match_bookmaker_prematchodds/${matchId}`, { alias, language }, qUrlBase);
    this.matchId = matchId;
  }
}

export class LiveOddsRequest extends OddsRequest {
  constructor(matchId, alias, language, qUrlBase = null) {
    super(`match_bookmaker_liveodds/${matchId}`, { alias, language }, qUrlBase);
    this.matchId = matchId;
  }
}

export class BookmakerOddsRequest extends OddsRequest {
  constructor(matchId, alias, language, qUrlBase = null, offset = -1) {
    super(`match_bookmakerodds/${matchId}`, { alias, language }, qUrlBase, offset);
    this.matchId = matchId;
  }
}

export class MatchInfoRequest extends FishnetFeed {
  constructor(matchId, alias, language, qUrlBase = null, offset = -1) {
    super(`match_info/${matchId}`, { alias, language }, qUrlBase, offset);
  }

  get() {
    return super.get()
      .then(data => {
        if (!data || (data && data.error)) {
          throw data;
        }

        const {
          match,
          sport,
          realcategory,
          tournament,
          jerseys,
          stadium,
          cities,
          season,
          referee,
          manager,
        } = data;

        if (!match) {
          return null;
        }
        let teams = {
          home: null,
          away: null,
          tennisDouble: null,
        };

        if (match.teams) {
          Object.keys(match.teams).forEach(teamSide => {
            let team = match.teams[teamSide];


            if (team.children) {
              const { children, ...doubleInfo } = team;

              teams.tennisDouble = {
                ...teams.tennisDouble,
                [teamSide]: [doubleInfo],
              };
            }


            team = team.children ? team.children : [team];

            const teamList = team.map(t => ({
              countryCodeA2: t.cc ? t.cc.a2 : null,
              uniqueId: t.uid,
              name: t.name,
              id: t._id,
              abbr: t.abbr,
              mediumname: t.mediumname,
              iscountry: t.iscountry,
              cc: t.cc,
            }));

            teams = {
              ...teams,
              [teamSide]: teamList,
            };
          });
        }

        // Only take needed properties from match_info feed
        const matchInfo = {
          id: match._id,
          tId: match._tid,
          seasonId: match._seasonid,
          sportId: sport._id,
          sportName: sport.name,
          matchStatusId: match.status._id,
          realcategoryName: realcategory.name,
          realcategoryCountryCodeA2: realcategory.cc
            ? realcategory.cc.a2
            : null,
          tournamentTennisInfoA2: tournament.tennisinfo
            ? tournament.tennisinfo.a2
            : null,
          tournamentName: tournament.name,
          teams,
          match,
          cities,
          stadium,
          tournament,
          sport,
          realcategory,
          season,
          referee,
          manager,
          jerseys,
        };
        const matchEntity = new schema.Entity('match');
        const normalizeMatchInfo = normalize(matchInfo, matchEntity);
        return normalizeMatchInfo;
      })
      .catch(transformAPIError);
  }
}

export class MatchTimelineDeltaRequest extends FishnetFeed {
  constructor(matchId, alias, language, qUrlBase = null, offset = -1) {
    super(`match_timelinedelta/${matchId}`, { alias, language }, qUrlBase, offset);
  }

  get() {
    return super.get()
      .then(data => {
        if (!data || (data && data.error)) {
          throw data;
        }

        const { match, events } = data;
        if (!match) {
          return null;
        }

        // Only take needed properties from match_info feed
        const matchInfo = {
          events,
          match,
          id: match._id,
          matchStatusId: match.status._id,
        };
        const matchEntity = new schema.Entity('match');
        const normalizeMatchInfo = normalize(matchInfo, matchEntity);

        return normalizeMatchInfo;
      })
      .catch(transformAPIError);
  }
}

export class MatchTimelineRequest extends FishnetFeed {
  constructor(matchId, alias, language, qUrlBase = null) {
    super(`match_timeline/${matchId}`, { alias, language }, qUrlBase);
  }

  get() {
    return super.get()
      .then(data => {
        if (!data || (data && data.error)) {
          throw data;
        }

        const { match, events } = data;
        if (!match) {
          return null;
        }

        // Only take needed properties from match_info feed
        const matchInfo = {
          events,
          id: match._id,
        };
        const matchEntity = new schema.Entity('match');
        const normalizeMatchInfo = normalize(matchInfo, matchEntity);

        return normalizeMatchInfo;
      })
      .catch(transformAPIError);
  }
}

export class SportMatchesRequest extends FishnetFeed {
  constructor(sportId, date, alias, language, qUrlBase = null) {
    super(`sport_matches/${sportId}/${date}/1`, { alias, language, tz: 'Etc:UTC' }, qUrlBase);
    this.sportId = sportId;
  }

  get() {
    return super.get()
      .then(originalData => {
        const realcategories = Object.values(originalData.sport.realcategories)
          .filter(rc => !rc.name.includes('imulated') && !(this.sportId === sportEnum.esoccer && rc._id === ESPORTS_BATTLE))
          .reduce((realAcc, real) => {
            const tournaments = Object.values(real.tournaments)
              .reduce((tourAcc, tour) => {
                if (this.sportId === sportEnum.table_tennis && tour._utid !== 32129) { // just TT Cup, _tid = 41115
                  return tourAcc;
                }

                const matches = Object.values(tour.matches)
                  .reduce((matchAcc, match) => {
                    const matchObj = {
                      id: match._id,
                      matchId: match._id,
                      sportId: match._sid,
                      dt: match._dt,
                      timeinfo: match.timeinfo,
                      timestamp: match._dt.uts + '',
                      date: new Date(match._dt.uts * 1000).toLocaleString(),
                      statusName: match.status.name,
                      status: null,
                      statusId: match.status._id,
                      tour: tour.name,
                      utid: tour._utid,
                      realcategory: real.name,
                      rcid: real._id,
                      scout_match: _get(match, 'coverage.scoutmatch', 0),
                    };


                    return matchAcc.concat([matchObj]);
                  }, []);

                return tourAcc.concat(matches);
              }, []);
            return realAcc.concat(tournaments);
          }, []);


        return realcategories;
      })
      .catch(() => null);
  }
}

export class MatchStartRecording extends FishnetFeed {
  constructor(matchId, alias, language, qUrlBase = null, offset = -1) {
    super(`match_start/${matchId}`, { alias, language }, qUrlBase, offset);
  }

  get() {
    return super.get();
  }
}


const normalizeMatchDetails = (match, matchId) => {
  const entries = Object.entries(match.values || {});

  const mDetails = entries.reduce((acc, cur) => {
    const [key, value] = cur;

    acc[key] = {
      name: value.name,
      ...value.value,
    };

    return acc;
  }, {});


  return {
    [matchId]: {
      ...mDetails,
    },
  };
};


export class MatchDetailsRequest extends FishnetFeed {
  constructor(matchId, alias, language, qUrlBase = null, offset = -1) {
    super(`match_details/${matchId}`, { alias, language }, qUrlBase, offset);
  }

  get() {
    return super
      .get()
      .then(data => {
        if (!data || (data && data.error)) {
          throw data;
        }
        // eslint-disable-next-line no-underscore-dangle
        const matchId = data._matchid;
        return normalizeMatchDetails(data, matchId);
      })
      .catch(transformAPIError);
  }
}


const makeTable = tour => {
  const tableArr = tour.reduce((accumulator, cur) => {
    const obj = {
      name: cur.team.name,
      uid: cur.team.uid,
      pts: cur.pointsTotal,
      pos: cur.pos,
      pld: cur.total,
      wins: cur.winTotal,
      draws: cur.drawTotal,
      losses: cur.lossTotal,
      gf: cur.goalsForTotal,
      ga: cur.goalsAgainstTotal,
      gfga: `${cur.goalsAgainstTotal}:${ cur.goalsAgainstTotal}`,
      changeTotal: cur.changeTotal,
    };
    return [...accumulator, obj];
  }, []);

  return tableArr;
};

export class TournamentLiveTableRequest extends FishnetFeed {
  constructor(tId, alias, language, qUrlBase = null, offset = -1) {
    super(`tournament_livetable/${tId}`, { alias, language }, qUrlBase, offset);
  }

  get() {
    return super
      .get()
      .then(data => {
        if (!data || (data && data.error)) {
          throw data;
        }

        return makeTable(data.tablerows);
      })
      .catch(transformAPIError);
  }
}

export class StatsSeasonOverUnderRequest extends FishnetFeed {
  constructor(sId, alias, language, qUrlBase = null, offset = -1) {
    super(`stats_season_overunder/${sId}`, { alias, language }, qUrlBase, offset);
  }

  get() {
    return super
      .get()
      .then(data => {
        if (!data || (data && data.error)) {
          throw data;
        }
        return data.stats;
      })
      .catch(transformAPIError);
  }
}

export class StatsMatchOverUnderRequest extends FishnetFeed {
  constructor(matchId, alias, language, qUrlBase = null, offset = -1) {
    super(`stats_match_overunder/${matchId}`, { alias, language }, qUrlBase, offset);
  }

  get() {
    return super
      .get()
      .then(data => {
        if (!data || (data && data.error)) {
          throw data;
        }
        return data;
      })
      .catch(transformAPIError);
  }
}


export class StatsSeasonGoalsRequest extends FishnetFeed {
  constructor(sId, alias, language, qUrlBase = null, offset = -1) {
    super(`stats_season_goals/${sId}`, { alias, language }, qUrlBase, offset);
  }

  get() {
    return super
      .get()
      .then(data => {
        if (!data || (data && data.error)) {
          throw data;
        }

        return data.teams.reduce((acc, cur) => {
          acc[cur.team.uid] = {
            scored: cur.scored,
            conceded: cur.conceded,
          };
          return acc;
        }, {});
      })
      .catch(transformAPIError);
  }
}

export class StatsMatchSituationRequest extends FishnetFeed {
  constructor(matchId, alias, language, qUrlBase = null, offset = -1) {
    super(`stats_match_situation/${matchId}`, { alias: 'common', language }, qUrlBase, offset);
  }

  get() {
    return super
      .get()
      .then(data => {
        if (!data || (data && data.error)) {
          throw data;
        }
        const matchId = data.matchid;

        return {
          matchId,
          situations: data.data,
        };
      })
      .catch(transformAPIError);
  }
}

export class StatsTeamLastXRequest extends FishnetFeed {
  constructor(teamId, alias, language, qUrlBase = null, offset = -1) {
    super(`stats_team_lastx/${teamId}`, { alias, language }, qUrlBase, offset);
  }

  get() {
    return super
      .get()
      .then(data => {
        if (!data || (data && data.error)) {
          throw data;
        }

        return data.matches;
      })
      .catch(transformAPIError);
  }
}


export class StatsTeamVersusRecent extends FishnetFeed {
  constructor(teamId1, teamId2, alias, language, qUrlBase = null, offset = -1) {
    super(`stats_team_versusrecent/${teamId1}/${teamId2}`, { alias, language }, qUrlBase, offset);
  }

  get() {
    return super
      .get()
      .then(data => {
        if (!data || (data && data.error)) {
          throw data;
        }

        return {
          matches: data.matches,
          tournaments: data.tournaments,
        };
      })
      .catch(transformAPIError);
  }
}

export class StatsTeamWinningPercentage extends FishnetFeed {
  constructor(teamId1, alias, language, qUrlBase = null, offset = -1) {
    super(`stats_team_winpercentage/${teamId1}`, { alias, language }, qUrlBase, offset);
  }

  get() {
    return super
      .get()
      .then(data => {
        if (!data || (data && data.error)) {
          throw data;
        }

        return data.grounds;
      })
      .catch(transformAPIError);
  }
}

export class StatsTeamInfo extends FishnetFeed {
  constructor(teamId1, alias, language, isDouble, qUrlBase = null, offset = -1) {
    super(`stats_team_info/${teamId1}`, { alias, language }, qUrlBase, offset);
    this.isDouble = isDouble;
  }

  get() {
    return super
      .get()
      .then(data => {
        if (!data || (data && data.error)) {
          throw data;
        }

        let rank;
        if (this.isDouble) {
          rank = data.doublesrank;
        } else {
          rank = data.singlesrank;
        }

        return {
          rank,
          sex: (data && data.team && data.team.sex) ? data.team.sex : undefined,
        };
      })
      .catch(transformAPIError);
  }
}

export class MatchFunFactHighlight extends FishnetFeed {
  constructor(matchId, alias, language, isDouble, qUrlBase = null, offset = -1) {
    super(`match_funfacthighlight/${matchId}`, { alias, language }, qUrlBase, offset);
  }

  get() {
    return super
      .get()
      .then(data => {
        if (!data || (data && data.error)) {
          throw data;
        }

        return data.funfacts;
      })
      .catch(transformAPIError);
  }
}
