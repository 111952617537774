import React from 'react';

import ResetPasswordForm from 'newDesign/modules/auth/resetPassword/resetPasswordForm/ResetPasswordForm';
import PasswordFooter from 'newDesign/modules/auth/footer/passwordFooter/PasswordFooter';

import styles from 'newDesign/modules/auth/styles/AuthStyles.scss';
import { isOldHostName, redirectToForgotPassword } from 'utils/Url';
import { useLocation } from 'react-router-dom';

const ResetPassword = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get('token');
  const username = queryParams.get('u');

  if (!token || !username || isOldHostName()) {
    redirectToForgotPassword();

    return null;
  }

  return (
    <div className={'flex min-h-screen w-full'}>
      <div className={`flex w-full flex-col ${styles.password_wrapper}`}>
        <div className={'flex h-full flex-col box-border px-4'}>
          <div className={'mt-20'} />
          <div className={'flex mb-8'}>
            <h1 className={`font-second relative w-full ${styles.forgot_password_header}`}>Reset your password
              <div
                className={`w-full ${styles.password_line}`}
              />
            </h1>
          </div>
          <ResetPasswordForm />
        </div>
        <PasswordFooter />
      </div>
    </div>
  );
};


export default ResetPassword;
