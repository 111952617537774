/* eslint-disable no-nested-ternary */
import {
  DECIMAL_ODDS,
  AMERICAN_ODDS,
  USOddsRoundingLadder,
} from 'constants/bcms';


function americanOdds(num) {
  if (num >= 2.0) {
    return (num - 1) * 100;
  } else {
    return -100 / (num - 1);
  }
}
function decimalOdds(num) {
  try {
    const parsedNum = parseFloat(num);
    if (parsedNum <= 1.005) {
      return '-';
    } else if (parsedNum < 10) {
      // round to 2 decimals for less than 10
      return parsedNum.toFixed(2);
    } else if (parsedNum < 100) {
      // round to 1 decimals for 10 to 100
      return parsedNum.toFixed(1);
    } else if (parsedNum >= 100) {
      // round to no decimals for > 100
      return parsedNum.toFixed(0);
    } else {
      return parsedNum;
    }
  } catch (error) {
    return '-';
  }
}

function roundUSOdds(oddsUs, ladder) {
  // ladder is an array of numbers that helps us round the value

  let maxLadder = Number.MAX_SAFE_INTEGER;
  let minLadder = Number.MIN_SAFE_INTEGER;
  ladder.forEach(l => {
    if (oddsUs >= l) {
      minLadder = l;
    }
    if (oddsUs <= l && l < maxLadder) {
      maxLadder = l;
    }
  });
  const minLadderDiff = Math.abs(oddsUs) - Math.abs(minLadder);
  const maxLadderDiff = Math.abs(maxLadder) - Math.abs(oddsUs);
  return minLadderDiff <= maxLadderDiff ? minLadder : maxLadder; // round value up or down
}

export function formatOdds(num, format) {
  if (format === DECIMAL_ODDS) {
    return decimalOdds(num);
  } else if (format === AMERICAN_ODDS) {
    if (num <= 1.005 || num > 1000) {
      return '-';
    }

    const usOdds = roundUSOdds(americanOdds(num), USOddsRoundingLadder).toFixed(0);
    if (usOdds < 0) {
      return `${usOdds}`;
    } else {
      return `+${usOdds}`;
    }
  }
  return null;
}


export const formatPreMatchOdds = (odds, oddsFormat, teams = null) => {
  if (odds == null) {
    return null;
  }

  const copyOdds = JSON.parse(JSON.stringify(odds));
  const marketKeys = Object.keys(copyOdds).filter(m => copyOdds[m]);

  marketKeys.forEach(mId => {
    const market = copyOdds[mId];

    const outcomesKeys = Object.keys(market.outcomes).filter(
      m => market.outcomes[m],
    );

    outcomesKeys.forEach((key, i) => {
      copyOdds[mId].outcomes[key].decOdds = formatOdds(
        copyOdds[mId].outcomes[key].odds,
        DECIMAL_ODDS,
      );
      copyOdds[mId].outcomes[key].feedOdds = parseFloat(copyOdds[mId].outcomes[key].odds);
      copyOdds[mId].outcomes[key].odds = formatOdds(copyOdds[mId].outcomes[key].odds, oddsFormat);

      if (teams && teams.home[0] && teams.away[0]) {
        copyOdds[mId].outcomes[key].feedName = copyOdds[mId].outcomes[key].name;

        if (outcomesKeys.length === 3) {
          // 1st outcome = home team, 2nd = draw?, 3rd = away
          copyOdds[mId].outcomes[key].name = (
            i === 0
              ? teams.home[0].name
              : i === 2
                ? teams.away[0].name
                : copyOdds[mId].outcomes[key].name);

          copyOdds[mId].outcomes[key].abbr = (
            i === 0
              ? teams.home[0].abbr
              : i === 2
                ? teams.away[0].abbr
                : copyOdds[mId].outcomes[key].name);
        }

        if (outcomesKeys.length === 2 && !copyOdds[mId].oddsTypeShort.toLowerCase().includes('total')) {
          copyOdds[mId].outcomes[key].name = (
            i === 0
              ? teams.home[0].name
              : teams.away[0].name);

          copyOdds[mId].outcomes[key].abbr = (
            i === 0
              ? teams.home[0].abbr
              : teams.away[0].abbr);
        }
      }
    });
  });

  return copyOdds;
};
