import {
  put,
  select,
  apply,
  fork,
  delay,
  call,
} from 'redux-saga/effects';
import {
  getClientAlias,
  getLanguage,
  getQueryFeed,
  getMatchStatus,
} from 'reducers';
import { PreMatchOddsRequest, LiveOddsRequest, BookmakerOddsRequest } from 'fishnet-api';
import * as config from 'Config/srlive';
import { PREMATCH_ODDS_POLL_INTERVAL, LIVE_ODDS_POLL_INTERVAL } from 'constants/fishnet';
import { isNotStarted, isLive, isEnded } from 'utils/matchstatus';
import {
  sagaCancel,
  sagaRunning,
} from 'sagas/utilSagas';
import {
  updateLiveOdds, updatePreMatchOdds, updateLiveOddsFailed, updatePreMatchOddsFailed,
} from 'actions/fishnet/index';
import { pollFishnet, pollTillResponse, takeMatchUpdate } from '../utilLCR';

function* loadOdds(matchId, live = false, offset = -1) {
  const clientAlias = yield select(getClientAlias);
  const language = yield select(getLanguage);
  const qFeed = offset > 0 ? config.fnReplayUrl : (yield select(getQueryFeed));
  let request;

  if (offset >= 0) {
    request = new BookmakerOddsRequest(matchId, clientAlias, language, qFeed, offset);
  } else {
    request = live
      ? new LiveOddsRequest(matchId, clientAlias, language, qFeed)
      : new PreMatchOddsRequest(matchId, clientAlias, language, qFeed);
  }

  // returns null only for failed request, no odds returns object
  const normalizedOdds = yield apply(request, request.get);

  // null to reducer is no-op
  if (normalizedOdds && !normalizedOdds.error) {
    if (live) {
      yield put(updateLiveOdds(normalizedOdds, matchId));
    } else {
      yield put(updatePreMatchOdds(normalizedOdds, matchId));
    }
  } else if (normalizedOdds && normalizedOdds.error) {
    if (live) {
      yield put(updateLiveOddsFailed(normalizedOdds, matchId));
    } else {
      yield put(updatePreMatchOddsFailed(normalizedOdds, matchId));
    }
    throw normalizedOdds;
  } else {
    throw new Error('Update Odds failed');
  }
}

// atleast once, different pre and live interval
function* pollOdds(matchId, pollLive = true, offset = -1) {
  let status;
  let taskPre;
  let taskLive;

  // always do atleast one request, to wipe out old data in state
  // does a BookmakerOddsRequest
  yield call(pollTillResponse, { logLevel: 5 }, loadOdds, matchId, pollLive, 0); // atleast once

  while (true) {
    status = yield select(getMatchStatus, matchId);

    if (!sagaRunning(taskPre) && isNotStarted(status)) {
      // poll using prematch interval
      taskPre = yield fork(
        pollFishnet,
        { pollInterval: PREMATCH_ODDS_POLL_INTERVAL },
        loadOdds,
        matchId,
        false, // prematch
        offset,
      );
    } else if (!sagaRunning(taskLive) && isLive(status) && pollLive) {
      taskPre = sagaCancel(taskPre); // cancel prematch polling
      // poll using prematch interval
      taskLive = yield fork(
        pollFishnet,
        { pollInterval: LIVE_ODDS_POLL_INTERVAL },
        loadOdds,
        matchId,
        true, // live
        offset,
      );
    } else if (isEnded(status)) {
      yield delay(PREMATCH_ODDS_POLL_INTERVAL + 1000); // let it make one final request
      taskPre = sagaCancel(taskPre);
      taskLive = sagaCancel(taskLive);
    }

    yield call(takeMatchUpdate, matchId);
  }
}

export default pollOdds;
