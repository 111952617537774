import { createReducer } from 'utils';
import { createSelector } from 'reselect';
import {
  UPDATE_STREAM_URLS,
  STREAM_URLS_REQUEST_ERROR,
  UPDATE_ACTIVE_VIDEO_HANDLER,
} from 'constants/actions';
import { debugPlayerUrl } from 'Config/app';
import { DASH, SMIL } from 'constants/bcms';

const initialState = {
  backoffSettings: {
    maxAttempts: 100,
    initialDelay: 2500,
    delta: 1.35,
    maxDelay: 60 * 1000,
  },
  activeVideoHandler: null,
  urls: [],
  error: null,
};

export default createReducer(initialState, {
  [UPDATE_STREAM_URLS]: (state, payload) => ({
    ...state,
    urls: payload,
    error: null,
  }),
  [STREAM_URLS_REQUEST_ERROR]: (state, payload) => ({
    ...state,
    error: payload,
  }),
  [UPDATE_ACTIVE_VIDEO_HANDLER]: (state, payload) => ({
    ...state,
    activeVideoHandler: payload,
  }),
});

export const getStreamUrls = createSelector(
  state => state.urls,
  urls => urls,
);

export const getStreamRequestSettings = createSelector(
  state => state.backoffSettings,
  backoffSettings => backoffSettings,
);

export const getStreamRequestError = createSelector(
  state => state.error,
  error => error,
);

export const getDebugPlayerUrl = createSelector(
  getStreamUrls,
  state => state.activeVideoHandler,
  (urls, activeVideoHandler) => {
    if (!urls || !urls.length || typeof activeVideoHandler !== 'string') {
      return null;
    }
    let streamUrl = null;
    let format = '';
    if (activeVideoHandler.toLowerCase() === 'dash') {
      [streamUrl] = urls.filter(source => source.type === DASH || source.url.includes('dash'));
      format = 'DASH';
    } else if (activeVideoHandler.toLowerCase() === 'smil') {
      [streamUrl] = urls.filter(source => source.type === SMIL || source.url.includes('smil'));
      format = 'smil';
    } else {
      [streamUrl] = urls; // take first
    }

    let finalUrl = null;
    if (streamUrl && streamUrl.url) {
      finalUrl = streamUrl.url.replace(/\\\//g, '/'); // remove https:\/\/ pattern
      finalUrl = btoa(finalUrl);

      finalUrl = finalUrl.replace('/', '_');
      finalUrl = finalUrl.replace('+', '-');
      finalUrl = debugPlayerUrl.replace(':url', finalUrl);
      finalUrl = finalUrl.replace(':format', format);
    }
    // TODO: check and return correct stuff for worldlottery
    //  if (this.config.streamid) {
    // } else {
    //   streamUrl = this.getWorldLotterySmilUrl();
    // }
    return finalUrl;
  },
);
