/*
 * action types
 */

import {
  HIGHLIGHTS_LOAD_SUCCESS,
} from 'constants/actions';
import { UPDATE_MATCH_FUNFACT_IDX } from './fishnet/actionTypes';

/*
 * action creators
 */

export function updateHighlights(response) {
  return { type: HIGHLIGHTS_LOAD_SUCCESS, payload: response };
}

export function updateFunFactIdx(response) {
  return { type: UPDATE_MATCH_FUNFACT_IDX, payload: response };
}
