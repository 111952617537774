// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Footer__footer___1aKpx{background-color:#333;min-height:340px;padding:60px 0;color:#fff}.Footer__footer___1aKpx h1{text-transform:uppercase;margin:0 0 3px;font-size:15px;font-weight:500;line-height:1.1}.Footer__footer___1aKpx h2{color:#fff;font-size:15px;line-height:19px;margin:17px 2px;text-transform:none;font-family:inherit;font-weight:inherit}.Footer__footer___1aKpx p{line-height:24px;margin:0px 0 30px;color:#ccc}.Footer__footer___1aKpx a{color:#0073ac}.Footer__footer___1aKpx span{margin:0 18px 0 0}.Footer__social_icons___22ZSP img:hover{filter:invert(46%) sepia(99%) saturate(1956%) hue-rotate(161deg) brightness(95%) contrast(101%)}.Footer__iglogo___2OvQx{filter:invert(54%) sepia(0%) saturate(13%) hue-rotate(263deg) brightness(93%) contrast(96%)}.Footer__subscribe_button___139cA{text-transform:uppercase;max-width:120px;min-width:27%;padding:10px 12px;margin-right:-100px;background:#0073ac;border:none;font-family:\"Arial\";color:#fff;display:inline-block;margin-bottom:0;font-weight:normal;text-align:center;vertical-align:middle;cursor:pointer;white-space:nowrap;font-size:14px;line-height:1.428571429;border-radius:4px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.Footer__subscribe_button___139cA:hover{background:#000}", ""]);
// Exports
exports.locals = {
	"footer": "Footer__footer___1aKpx",
	"social_icons": "Footer__social_icons___22ZSP",
	"iglogo": "Footer__iglogo___2OvQx",
	"subscribe_button": "Footer__subscribe_button___139cA"
};
module.exports = exports;
