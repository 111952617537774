import {
  put,
  select,
  apply,
  fork,
  delay,
  call,
} from 'redux-saga/effects';
import { MATCH_TIMELINE_DELTA_POLL_INTERVAL } from 'constants/fishnet';
import * as config from 'Config/srlive';
import {
  getClientAlias,
  getLanguage,
  getMatchStatus,
  getQueryFeed,
  getMatchStartTime,
} from 'reducers';
import { updateMatchDelta, updateMatchDeltaFailed } from 'actions/fishnet/index';
import { MatchTimelineDeltaRequest } from 'fishnet-api';
import { sagaCancel, sagaRunning } from '../utilSagas';
import { isEnded, isLive } from '../../utils/matchstatus';
import { MIN } from '../../constants/app';
import { pollFishnet, pollTillResponse, takeMatchUpdate } from '../utilLCR';

function* loadTimelineDelta(matchId, offset) {
  const clientAlias = yield select(getClientAlias);
  const language = yield select(getLanguage);
  const qFeed = offset > 0 ? config.fnReplayUrl : (yield select(getQueryFeed));

  const request = new MatchTimelineDeltaRequest(matchId, clientAlias, language, qFeed, offset);
  const response = yield apply(request, request.get);

  if (response && !response.error) {
    yield put(updateMatchDelta(response, matchId));
  } else if (response && response.error) {
    yield put(updateMatchDeltaFailed(response, matchId));
    throw response;
  } else {
    throw new Error('Update TimelineDelta failed');
  }
}

function* pollTimelineDelta(matchId, offset, pollInterval = MATCH_TIMELINE_DELTA_POLL_INTERVAL) {
  let status;
  let task;
  yield call(pollTillResponse, { logLevel: 0 }, loadTimelineDelta, matchId, offset);


  for (;;) {
    status = yield select(getMatchStatus, matchId);
    const startTime = yield select(getMatchStartTime, matchId); // start time could be updated

    const notRunning = !sagaRunning(task);
    const nowPlus5Min = new Date(Date.now() + 5 * MIN);
    const start = new Date(startTime * 1000);
    const startTimeCheck = (startTime && (start < nowPlus5Min));
    /**
     * only enter if task is not running already
     * and (current time + 5 minutes is more than starttime) OR (matchStatus is live)
     * and match is not ended
     * (last condition is to negate the time condition which would be true for an ended match)
     */
    if (
      notRunning
      && (startTimeCheck || isLive(status))
      && !isEnded(status)
    ) {
      task = yield fork(
        pollFishnet,
        { pollInterval },
        loadTimelineDelta,
        matchId,
        offset,
      );
    } else if (sagaRunning(task) && isEnded(status)) {
      yield delay(pollInterval);
      sagaCancel(task);
    }

    yield call(takeMatchUpdate, matchId);
  }
}

export default pollTimelineDelta;
