import { matchStatusIds as ms } from 'constants/enum';

export const isEnded = status => {
  if (status >= ms.cancelled && status <= ms.AGS && status !== ms.interrupted) {
    return true;
  }
  return false;
};

export const isLive = status => {
  if (
    status > ms.not_started
    && !(status >= ms.cancelled && status <= ms.AGS && status !== ms.interrupted)
  ) {
    return true;
  }
  return false;
};

export const isInPlay = status => {
  const pausedStatuses = [
    ms.pause, ms.first_break, ms.second_break, ms.third_break, ms.fourth_break,
    ms.halftime, ms.extra_time_halftime, ms.awaiting_extra_time,
    ms.awaiting_penalties, ms.awaiting_penalties_hockey,
  ];
  if (
    status > ms.not_started
    && !(status >= ms.cancelled && status <= ms.AGS && status !== ms.interrupted)
    && !(pausedStatuses.includes(status))
  ) {
    return true;
  }
  return false;
};

export const isNotStarted = status => {
  if (status === ms.not_started) {
    return true;
  }
  return false;
};

export const isFirstHalfDone = status => {
  if (status === ms.third_quarter || status === ms.fourth_quarter) {
    return true;
  } else if (
    status >= ms.halftime
    && !(status >= ms.cancelled && status <= ms.AGS && status !== ms.ended)
  ) {
    return true;
  }
  return false;
};
