import {
  takeEvery,
  apply,
  delay,
  select,
  race,
  put,
} from 'redux-saga/effects';

import { LCR_AUTH_CHECK } from 'constants/actions';
import CheckAuthorization from 'auth-api/index';
import { redirectCurrentHostToClient } from 'utils/Url';
import { SEC } from 'constants/app';
import { getAuthPollingInterval, getTechnicalIssuesStatus } from 'reducers';
import {
  HTTP_STATUS_BAD_GATEWAY,
  HTTP_STATUS_CODE_LOCKED,
  HTTP_STATUS_NOT_FOUND,
  HTTP_STATUS_SERVICE_UNAVAILABLE,
} from 'constants/bcms';
import { accountDisabled, accountEnabled, loggedInStatusFailed } from 'actions/authorization';
import { setTechnicalIssuesStatus } from '../../actions/stages';

function* lcrAuthValidateSaga() {
  let nextDelay = 2;
  const multi = 1.75;
  const pollInterval = yield select(getAuthPollingInterval);
  let usedDelay = pollInterval;

  for (; ;) {
    const technicalIssues = yield select(getTechnicalIssuesStatus);
    const request = new CheckAuthorization('/auth/validate', null, false);
    const response = yield apply(request, request.get);
    const {
      isAuthorized,
      userId = null,
    } = response;

    if (!isAuthorized) {
      // Gradually increases the delay between authorization checks, without exceeding the max interval (pollInterval),
      // balancing timely checks with reduced server load.
      usedDelay = nextDelay;
      nextDelay = (nextDelay * multi) < pollInterval
        ? (nextDelay * multi)
        : pollInterval;
    }

    if (response
      && [HTTP_STATUS_NOT_FOUND, HTTP_STATUS_SERVICE_UNAVAILABLE, HTTP_STATUS_BAD_GATEWAY].includes(response.code)
    ) {
      if (technicalIssues === null) {
        yield put(setTechnicalIssuesStatus(true));
      }
    } else if (response && response.error) {
      // if error or not authorized do nothing
    } else if (isAuthorized && userId) {
      yield put(setTechnicalIssuesStatus(false));
      yield put(accountEnabled());
      redirectCurrentHostToClient(userId);
    } else if (!isAuthorized && response.code === HTTP_STATUS_CODE_LOCKED) {
      yield put(setTechnicalIssuesStatus(false));
      yield put(loggedInStatusFailed());
      yield put(accountDisabled());
    }

    yield race([
      delay(usedDelay * SEC),
    ]);
  }
}

export default function* runLoginAuthCheckSaga() {
  yield takeEvery(LCR_AUTH_CHECK, lcrAuthValidateSaga);
}
