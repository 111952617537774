import { createReducer } from 'utils';
import {
  LOG_MESSAGE,
  HIDE_MESSAGE,
} from 'constants/actions';

const initialState = {
  logMessageTimeout: 8000,
  messages: [],
};

export default createReducer(initialState, {
  [LOG_MESSAGE]: (state, payload) => ({
    ...state,
    messages: state.messages.concat(payload),
  }),
  [HIDE_MESSAGE]: (state, payload) => ({
    ...state,
    messages: state.messages.filter(msg => msg.id !== payload),
  }),
});

export const getLogMessagesTimeout = state => state.logMessageTimeout;
export const getActiveMessages = state => state.messages.filter(msg => msg.active);
