/* eslint-disable max-classes-per-file */
import lcrRequest from 'lcrRequest';
import { DASH } from 'constants/bcms';

export class StreamURLRequest {
  constructor(feed, type, options = undefined) {
    const paramArr = Object.keys(options).map(
      param => `${param}=${options[param]}`,
    );
    const paramStr = `&${paramArr.join('&')}`;
    this.url = `${feed}?${paramStr}`;
    this.type = type;
  }

  get() {
    return lcrRequest
      .get(this.url, { credentials: 'include' })
      .then(data => {
        if (data) {
          return {
            url: data.url,
            type: this.type,
          };
        }
        return null;
      })
      .catch(() => null);
  }
}

export class DashUrlWorldLotteryRequest extends StreamURLRequest {
  constructor(worldLotteryUrl, worldlotterystreamid) {
    super('', DASH, {});
    const paramStr = `id=${worldlotterystreamid}`;
    this.url = `${worldLotteryUrl}?${paramStr}`;
  }
}


export class StreamUrlRequest extends StreamURLRequest {
  constructor(streamUrl, matchId, userId, channelId) {
    super(streamUrl, DASH, {
      matchid: matchId,
      userid: userId,
      channelid: channelId,
    });
  }
}

export class LcrDashUrlWorldLotteryRequest extends StreamURLRequest {
  constructor(worldLotteryUrl) {
    super('', DASH, {});
    this.url = `${worldLotteryUrl}`;
  }
}


export class LcrStreamUrlRequest extends StreamURLRequest {
  constructor(streamUrl, matchId) {
    super(streamUrl, DASH, {
      match_id: matchId,
    });
  }
}
