import {
  call,
  put,
} from 'redux-saga/effects';
import {
  cacheStaticVideos,
} from 'actions/stages';
import { getBlobURL } from 'utils';
import { ErrorLog } from 'utils/LoggerItem';

export function* cacheChannelTrailer(channelTrailerUrl) {
  try {
    if (!channelTrailerUrl) {
      throw new ErrorLog('No Channel Trailer Url');
    }
    const addParam = channelTrailerUrl + '?avlcr';
    const channelTrailerCachedUrl = yield call(getBlobURL, addParam);

    if (channelTrailerCachedUrl && channelTrailerCachedUrl.error) {
      throw channelTrailerCachedUrl;
    } else {
      yield put(cacheStaticVideos({
        channelTrailerCachedUrl,
      }));
    }
  } catch (error) {
    yield put(cacheStaticVideos({
      channelTrailerCachedUrl: '',
    }));
    throw error;
  }
}

export function* cacheMarketingVideo(marketingVideoUrl) {
  try {
    if (!marketingVideoUrl) {
      throw new ErrorLog('No Marketing Video Url');
    }
    const addParam = marketingVideoUrl + '?avlcr';
    const marketingVideoCachedUrl = yield call(getBlobURL, addParam);

    if (marketingVideoCachedUrl && marketingVideoCachedUrl.error) {
      throw marketingVideoCachedUrl;
    } else {
      yield put(cacheStaticVideos({
        marketingVideoCachedUrl,
      }));
    }
  } catch (error) {
    yield put(cacheStaticVideos({
      marketingVideoCachedUrl: '',
    }));
    throw error;
  }
}
