
import {
  put,
  select,
  apply,
  call,
} from 'redux-saga/effects';
import {
  getClientAlias,
  getLanguage,
  getQueryFeed,
  getMatchHomeUid,
  getMatchAwayUid,
} from 'reducers';
import { StatsTeamVersusRecent } from 'fishnet-api';
import * as config from 'Config/srlive';
import { updateStatsTeamVersusFailed, updateStatsTeamVersus } from 'actions/fishnet/index';
import { pollTillResponse, takeMatchUpdate } from '../utilLCR';

function* loadStatsTeamLastX(matchId, offset) {
  const clientAlias = yield select(getClientAlias);
  const language = yield select(getLanguage);
  const qFeed = offset > 0 ? config.fnReplayUrl : yield select(getQueryFeed);
  const tId1 = yield select(getMatchHomeUid, matchId);
  const tId2 = yield select(getMatchAwayUid, matchId);


  const request = new StatsTeamVersusRecent(
    tId1,
    tId2,
    clientAlias,
    language,
    qFeed,
  );

  const response = yield apply(request, request.get);

  if (response && !response.error) {
    const pm = {
      [matchId]: response,
    };

    yield put(updateStatsTeamVersus(pm, matchId));
  } else if (response && response.error) {
    yield put(updateStatsTeamVersusFailed(response, matchId));
    throw response;
  } else {
    throw new Error('Update stats_season_overunder failed');
  }
}

// request once
function* pollStatsTeamVersus(matchId, offset) {
  yield call(takeMatchUpdate, matchId); // wait on one succesful match_info / match_timelinedelta
  yield call(pollTillResponse, {}, loadStatsTeamLastX, matchId, offset); // poll this endpoint once
}

export default pollStatsTeamVersus;
