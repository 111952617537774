import {
  put,
  select,
  apply,
  call,
} from 'redux-saga/effects';
import {
  getClientAlias,
  getLanguage,
  getQueryFeed,
  getSeasonId,
  getMatchTeams,
} from 'reducers';
import { StatsSeasonGoalsRequest } from 'fishnet-api';
import * as config from 'Config/srlive';
import { updateStatsSeasonGoalsFailed, updateStatsSeasonGoals } from 'actions/fishnet/index';
import { pollTillResponse, takeMatchUpdate } from '../utilLCR';

function* loadStatsGoals(matchId, offset) {
  const clientAlias = yield select(getClientAlias);
  const language = yield select(getLanguage);
  const qFeed = offset > 0 ? config.fnReplayUrl : (yield select(getQueryFeed));
  const sId = yield select(getSeasonId, matchId);

  if (!sId) {
    return;
  }

  const request = new StatsSeasonGoalsRequest(sId, clientAlias, language, qFeed);
  const response = yield apply(request, request.get);

  const teams = yield select(getMatchTeams, matchId);

  if (response && !response.error) {
    const homeUid = teams.home[0].uniqueId;
    const awayUid = teams.away[0].uniqueId;

    const goals = {
      [matchId]: {
        home: response[homeUid],
        away: response[awayUid],
      },
    };
    yield put(updateStatsSeasonGoals(goals, matchId));
  } else if (response && response.error) {
    yield put(updateStatsSeasonGoalsFailed(response, matchId));
    throw response;
  } else {
    throw new Error('Update stats_season_overunder failed');
  }
}
// request once
function* pollStatsSeasonGoals(matchId, offset) {
  yield call(takeMatchUpdate, matchId); // wait on one succesful match_info / match_timelinedelta
  yield call(pollTillResponse, {}, loadStatsGoals, matchId, offset); // poll this endpoint once
}

export default pollStatsSeasonGoals;
