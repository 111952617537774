import {
  put,
  select,
  delay,
  takeEvery,
} from 'redux-saga/effects';
import {
  LOG_MESSAGE,
} from 'constants/actions';
import {
  getLogMessagesTimeout,
} from 'reducers';
import { hideLoggerMessage } from 'actions/logger';


function* hideLogMessage(action) {
  const { id } = action.payload;
  const timeout = yield select(getLogMessagesTimeout);
  yield delay(timeout);
  yield put(hideLoggerMessage(id));
}

export default function* runLoggerSaga() {
  yield takeEvery(LOG_MESSAGE, hideLogMessage);
}
