import {
  PAYMENT_CHECK_SUCCESS,
  PAYMENT_CHECK_FAILED,
  LOGGED_IN_SUCCESS,
  LOGGED_IN_FAILURE,
  ACCOUNT_DISABLED,
  ACCOUNT_ENABLED,
  SET_BID,
} from 'constants/actions';

export function paymentCheckFailed() {
  return { type: PAYMENT_CHECK_FAILED };
}

export function paymentCheckSuccess() {
  return { type: PAYMENT_CHECK_SUCCESS };
}


export function accountDisabled() {
  return { type: ACCOUNT_DISABLED };
}

export function accountEnabled() {
  return { type: ACCOUNT_ENABLED };
}

export function loggedInStatusSuccess(isOperator) {
  return {
    type: LOGGED_IN_SUCCESS,
    payload: isOperator,
  };
}

export function loggedInStatusFailed() {
  return { type: LOGGED_IN_FAILURE };
}

export function setBId(bId) {
  return {
    type: SET_BID,
    payload: bId,
  };
}
