/* eslint-disable dot-notation */
import { createReducer } from 'utils';
import { createSelector } from 'reselect';
import {
  UPDATE_MATCH_TIMELINE,
  UPDATE_PREMATCH_ODDS,
  UPDATE_LIVE_ODDS,
  UPDATE_MATCH_INFO,
  UPDATE_MATCH_DELTA,
  NEXT_MATCHES_LOAD_SUCCESS,
  UPDATE_MATCH_DETAILS,
  UPDATE_TOURNAMENT_LIVETABLE,
  UPDATE_STATS_SEASON_OVERUNDER,
  UPDATE_STATS_SEASON_GOALS,
  UPDATE_STATS_MATCH_SITUATION,
  UPDATE_STATS_TEAM_LASTX,
  SET_MATCHCLOCK,
  REMOVE_MATCH,
  UPDATE_STATS_MATCH_OVERUNDER,
  UPDATE_STATS_TEAM_VERSUS,
  UPDATE_STATS_TEAM_WIN_PCT,
  UPDATE_STATS_TEAM_INFO,
} from 'actions/fishnet/actionTypes';
import { normalize, schema } from 'normalizr';
import {
  CLAY, GROUND_ARRAY, HARDCOURT, INDOOR, OVERALL,
} from 'constants/fishnet';
import { getMatchId } from './bcmsConfig';
import { updateMatchInfoState, updateMatchMarkets } from './fishnetHelpers';
import { eSports, eventTypeId, sport } from '../constants/enum';

const initialState = {
  markets: {
    marketLookup: {},
  },
  matchInfo: {},
  match: {},
  matchTimeline: {},
  nextMatches: {},
  nextMatchesById: [],
  nextMatchesLoaded: false,
  matchDetails: {},
  matchClocks: {},
  stats: {
    overunder: {},
    matchOverUnder: {},
    goals: {},
    lastx: {},
    winPct: {},
    prevMeetings: {},
    ranking: {},
  },
  clientMatchIds: {},
};

export default createReducer(initialState, {
  [UPDATE_PREMATCH_ODDS]: (state, payload) => (
    updateMatchMarkets(state, payload)
  ),
  [UPDATE_LIVE_ODDS]: (state, payload) => (
    updateMatchMarkets(state, payload)
  ),
  [UPDATE_MATCH_INFO]: (state, payload) => (
    updateMatchInfoState(state, payload)
  ),
  [UPDATE_MATCH_DELTA]: (state, payload) => {
    if (!payload) {
      return state;
    }
    const matchIds = Object.keys(payload.entities.match);
    if (matchIds.length > 0) {
      const matchId = matchIds[0];
      const { events, ...match } = payload.entities.match[matchId];

      const eventsObj = Object.values((events || {})).reduce((acc, cur) => {
        acc[cur._id] = {
          id: cur._id,
          ...cur,
        };
        return acc;
      }, {});

      return {
        ...state,
        matchTimeline: {
          ...state.matchTimeline,
          [matchId]: {
            ...state.matchTimeline[matchId],
            events: state.matchTimeline[matchId] && state.matchTimeline[matchId].events ? {
              ...state.matchTimeline[matchId].events,
              ...eventsObj,
            } : eventsObj,
          },
        },
        match: {
          ...state.match,
          [matchId]: {
            overtimeperiods: state.match[matchId]
              ? state.match[matchId].overtimeperiods
              : undefined, // not in delta??
            ...match.match,

          },
        },
      };
    } else {
      return state;
    }
  },
  [UPDATE_MATCH_TIMELINE]: (state, payload) => {
    if (!payload) {
      return state;
    }
    const matchIds = Object.keys(payload.entities.match);
    if (matchIds.length > 0) {
      const matchId = matchIds[0];
      const { events } = payload.entities.match[matchId];

      const eventsObj = Object.values((events || {})).reduce((acc, cur) => {
        acc[cur._id] = {
          id: cur._id,
          ...cur,
        };
        return acc;
      }, {});

      return {
        ...state,
        matchTimeline: {
          ...state.matchTimeline,
          [matchId]: {
            ...state.matchTimeline[matchId],
            events: state.matchTimeline[matchId] && state.matchTimeline[matchId].events ? {
              ...state.matchTimeline[matchId].events,
              ...eventsObj,
            } : eventsObj,
          },
        },
      };
    } else {
      return state;
    }
  },
  [UPDATE_MATCH_DETAILS]: (state, payload) => ({
    ...state,
    matchDetails: {
      ...state.matchDetails,
      ...payload,
    },
  }),
  [UPDATE_STATS_SEASON_OVERUNDER]: (state, payload) => ({
    ...state,
    stats: {
      ...state.stats,
      overunder: {
        ...state.stats.overunder,
        ...payload,
      },
    },
  }),
  [UPDATE_STATS_MATCH_OVERUNDER]: (state, payload) => ({
    ...state,
    stats: {
      ...state.stats,
      matchOverUnder: {
        ...state.stats.matchOverUnder,
        ...payload,
      },
    },
  }),
  [UPDATE_STATS_SEASON_GOALS]: (state, payload) => ({
    ...state,
    stats: {
      ...state.stats,
      goals: {
        ...state.stats.goals,
        ...payload,
      },
    },
  }),
  [UPDATE_STATS_MATCH_SITUATION]: (state, payload) => ({
    ...state,
    matchInfo: {
      ...state.matchInfo,
      [payload.matchId]: {
        ...state.matchInfo[payload.matchId],
        situations: payload.situations ? [...payload.situations] : [],
      },
    },
  }),
  [UPDATE_STATS_TEAM_LASTX]: (state, payload) => ({
    ...state,
    stats: {
      ...state.stats,
      lastx: {
        ...state.stats.lastx,
        ...payload,
      },
    },
  }),
  [UPDATE_STATS_TEAM_WIN_PCT]: (state, payload) => ({
    ...state,
    stats: {
      ...state.stats,
      winPct: {
        ...state.stats.winPct,
        ...payload,
      },
    },
  }),
  [UPDATE_STATS_TEAM_INFO]: (state, payload) => ({
    ...state,
    stats: {
      ...state.stats,
      ranking: {
        ...state.stats.ranking,
        ...payload,
      },
    },
  }),
  [UPDATE_STATS_TEAM_VERSUS]: (state, payload) => ({
    ...state,
    stats: {
      ...state.stats,
      prevMeetings: {
        ...state.stats.prevMeetings,
        ...payload,
      },
    },
  }),

  [UPDATE_TOURNAMENT_LIVETABLE]: (state, payload) => {
    if (!payload) {
      return state;
    }
    return {
      ...state,
      matchInfo: {
        ...state.matchInfo,
        [payload.matchId]: {
          ...state.matchInfo[payload.matchId],
          table: [...payload.table],
        },
      },
    };
  },
  [SET_MATCHCLOCK]: (state, payload) => ({
    ...state,
    matchClocks: {
      ...state.matchClocks,
      ...payload,
    },
  }),
  [REMOVE_MATCH]: (state, matchId) => ({
    ...state,
    matchInfo: {
      ...state.matchInfo,
      [matchId]: undefined,
    },
    match: {
      ...state.match,
      [matchId]: undefined,
    },
    matchDetails: {
      ...state.matchDetails,
      [matchId]: undefined,
    },
    stats: {
      ...state.stats,
      overunder: {
        ...state.stats.overunder,
        [matchId]: undefined,
      },
      goals: {
        ...state.stats.goals,
        [matchId]: undefined,
      },
      lastx: {
        ...state.stats.lastx,
        [matchId]: undefined,
      },
    },
  }),
  [NEXT_MATCHES_LOAD_SUCCESS]: (state, payload) => {
    const matchSchema = new schema.Entity('match', undefined, {
      idAttribute: 'matchId',
    });
    const macthListSchema = new schema.Array(matchSchema);
    const normalizedData = normalize(payload, macthListSchema);
    return {
      ...state,
      nextMatches: { ...normalizedData.entities.match },
      nextMatchesById: [...normalizedData.result],
      nextMatchesLoaded: true,
    };
  },
});

export const getMatchInfo = createSelector(
  state => state.matchInfo,
  (state, matchId) => matchId,
  (matchInfo, matchId) => matchInfo[matchId] || {},
);

export const getMatch = createSelector(
  state => state.match,
  (state, matchId) => matchId,
  (match, matchId) => match[matchId],
);

export const getMatchTimeline = createSelector(
  state => state.matchTimeline,
  (state, matchId) => matchId,
  (matchTimeline, matchId) => matchTimeline[matchId],
);

export const getMatches = createSelector(
  state => state.matchInfo,
  // get all non undefined matchids
  matchInfo => Object.keys(matchInfo).map(m => parseInt(m, 10)).filter(obj => matchInfo[obj]),
);

export const getMatchResult = createSelector(
  getMatch,
  match => (match ? match.result : undefined),
);
export const getMatchPeriodLength = createSelector(
  getMatch,
  info => {
    if (!info) {
      return null;
    }
    return info.periodlength;
  },
);
export const getMatchNumPeriods = createSelector(
  getMatch,
  info => {
    if (!info) {
      return null;
    }
    return info.numberofperiods;
  },
);
export const getMatchOvertimeLength = createSelector(
  getMatch,
  info => {
    if (!info) {
      return null;
    }
    return info.overtimelength;
  },
);


export const getMatchDetails = createSelector(
  (state, matchId) => state.matchDetails[matchId],
  matchDetails => matchDetails,
);
export const getSoccerEvents = createSelector(
  getMatchDetails,
  matchDetails => (matchDetails || null),
);

export const getTeamYellows = createSelector(
  getSoccerEvents,
  (state, matchId, team) => team,
  (teamEvents, team) => (teamEvents && teamEvents[eventTypeId.yellow_card]
    ? teamEvents[eventTypeId.yellow_card][team]
    : undefined
  ),
);

export const getTeamReds = createSelector(
  getSoccerEvents,
  (state, matchId, team) => team,
  (teamEvents, team) => {
    const straightRed = (teamEvents && teamEvents[eventTypeId.red_card]
      ? teamEvents[eventTypeId.red_card][team]
      : null);


    const secondYellowRed = (teamEvents && teamEvents[eventTypeId.yellow_red_card]
      ? teamEvents[eventTypeId.yellow_red_card][team]
      : null);

    if (straightRed == null && secondYellowRed == null) {
      return undefined;
    }

    let sum = 0;
    if (straightRed != null) {
      sum += straightRed;
    }
    if (secondYellowRed != null) {
      sum += secondYellowRed;
    }

    return sum;
  },
);

export const getTeamSubs = createSelector(
  getSoccerEvents,
  (state, matchId, team) => team,
  (teamEvents, team) => (teamEvents && teamEvents[eventTypeId.player_substitution]
    ? teamEvents[eventTypeId.player_substitution][team]
    : undefined
  ),
);

export const getTeamCorners = createSelector(
  getSoccerEvents,
  (state, matchId, team) => team,
  (teamEvents, team) => (teamEvents && teamEvents[eventTypeId.corner_kick_count]
    ? teamEvents[eventTypeId.corner_kick_count][team]
    : undefined
  ),
);


export const getMatchDetailsStat = createSelector(
  getMatchDetails,
  (state, matchId, stat) => stat,
  (matchDetails, stat) => {
    if (matchDetails && stat === -1) {
      return {
        ...matchDetails,
      };
    }
    if (!matchDetails || !stat || !matchDetails[stat]) {
      return undefined;
    }

    return {
      ...matchDetails[stat],
    };
  },
);

export const getMatchSeeding = createSelector(
  (state, matchId) => (state.matchInfo[matchId] ? state.matchInfo[matchId].teams : null),
  teams => {
    if (!teams) {
      return null;
    }

    const retArr = [];

    if (teams.home && teams.away) {
      if (teams.home.seed) {
        const homeSeed = teams.home.seed.seeding
          ? teams.home.seed.seeding
          : '-';

        retArr.push(homeSeed);
      } else {
        retArr.push(null);
      }

      if (teams.away.seed) {
        const awaySeed = teams.away.seed.seeding
          ? teams.away.seed.seeding
          : '-';

        retArr.push(awaySeed);
      } else {
        retArr.push(null);
      }
    }

    if (retArr.every(seed => !seed)) {
      return null;
    }
    return retArr;
  },
);


export const getMatchOvertimePeriods = createSelector(
  getMatch,
  match => (match ? match.overtimeperiods : undefined),
);

export const getMatchGameScore = createSelector(
  getMatch,
  match => (match ? match.gamescore : undefined),
);

export const getMatchBasketballBonus = createSelector(
  getMatch,
  match => (match ? match.basketballbonus : undefined),
);

export const getMatchFoulsCurPer = createSelector(
  getMatchDetails,
  mDetails => (mDetails ? mDetails[eventTypeId.fouls_count_period] : undefined),
);

export const getMatchDirectFoulsCurPer = createSelector(
  getMatchDetails,
  mDetails => (mDetails ? mDetails[eventTypeId.direct_foul_count] : undefined),
);

export const getMatchSets = createSelector(
  getMatch,
  match => (match ? match.bestofsets : undefined),
);

export const getMatchSportId = createSelector(
  getMatch,
  getMatchInfo,
  (match, mInfo) => mInfo.sportId || (match ? match._sid : undefined),
);

export const getMatchPeriods = createSelector(
  getMatch,
  getMatchSportId,
  (match, sportId) => {
    let k = 0;
    let normalPeriodNr = 0;


    if (sport.table_tennis === sportId) {
      normalPeriodNr = 5;
      k = match && match.periods ? Object.keys(match.periods).length : 0;
    } else if (sport.ice_hockey === sportId) {
      normalPeriodNr = 3;
      k = match && match.periods ? Object.keys(match.periods).length : 0;
    } else {
      return (match ? match.periods : undefined);
    }

    const cpyPeriods = {
      ...match.periods,
    };


    delete cpyPeriods.ft;
    delete cpyPeriods.ap;
    delete cpyPeriods.aet;
    delete cpyPeriods.ot;

    // fill remaining periods
    while (k < normalPeriodNr) {
      cpyPeriods['p' + (1 + k)] = {
        home: '-',
        away: '-',
      };
      k++;
    }


    return cpyPeriods;
  },
);

// returns 0, 1, 2, 3, 4 ot/ot1/ot2
export const getMatchCurPeriod = createSelector(
  getMatch,
  getMatchOvertimePeriods,
  (match, overtime) => {
    if (!match) {
      return null;
    }

    let period = parseInt(match.p, 10);
    let periods = 0;

    const periodsArr = match.periods ? Object.keys(match.periods) : [];
    switch (period) {
      case 31:
      case 32:
      case 33:
      case 34:
        if (periodsArr.includes('ft')) {
          period = 'ot';
          break;
        }

        for (let i = 0; i < periodsArr.length; i++) {
          let loopP = periodsArr[i];
          loopP = loopP.substring(loopP.length - 1);

          periods = loopP > periods
            ? loopP
            : periods;
        }
        period = parseInt(periods, 10) + 1;
        break;
      case 10:
      case 11:
      case 12:
        if (overtime) {
          period = Object.keys(overtime).pop();
        } else {
          period = 'ot';
        }
        break;
      case 20:
        period = 'pen';
        break;
      case 40:
        period = '-';
        break;
      default:
        break;
    }

    return period;
  },
);

export const getMatchPeriodsBasketballHockey = createSelector(
  getMatchCurPeriod,
  getMatchSportId,
  (state, matchId) => (state.match[matchId] ? state.match[matchId].periods : {}),
  (state, matchId) => (state.match[matchId] ? state.match[matchId].result : {}),
  getMatchOvertimePeriods,
  (curPeriod, sportId, periods, result, overtime) => {
    const emptyPeriods = {
      p1: {
        home: '-',
        away: '-',
      },
      p2: {
        home: '-',
        away: '-',
      },
      p3: {
        home: '-',
        away: '-',
      },
    };

    if (sportId === sport.basketball || sportId === sport.ebasketball) {
      emptyPeriods.p4 = {
        home: '-',
        away: '-',
      };
    }
    // fishnet periods <3
    // https://jira.sportradar.ag/browse/FF-2202
    // https://jira.sportradar.ag/browse/DI-1766

    if (curPeriod === 0) { // ended or not started
      if (periods && periods.ft) {
        const cpy = JSON.parse(JSON.stringify(periods));

        if (cpy.ot) {
          const ot = {
            home: cpy.ot.home - cpy.ft.home,
            away: cpy.ot.away - cpy.ft.away,
          };

          delete cpy.ft;
          delete cpy.ot;
          delete cpy.ap;
          delete cpy.aet;


          if (overtime) {
            // use overtimeperiods with ot1, ot2 breakdown
            return {
              ...cpy,
              ...overtime,
            };
          } else {
            // multiple ot periods all in "ot"
            return {
              ...cpy,
              ot,
            };
          }
        }

        delete cpy.ft;

        return cpy;
      }
      return emptyPeriods;
    } else if (curPeriod === 1) { // first period
      return {
        ...emptyPeriods,
        p1: {
          home: result.home,
          away: result.away,
        },
      };
    } else { // create and add current live period
      const cpyP = JSON.parse(JSON.stringify(periods || {}));

      delete cpyP.ft;
      delete cpyP.ot;

      const homePeriodScore = Object.values(cpyP).reduce((acc, cur) => acc + cur.home, 0);
      const awayPeriodScore = Object.values(cpyP).reduce((acc, cur) => acc + cur.away, 0);

      const curP = {
        home: result.home - homePeriodScore,
        away: result.away - awayPeriodScore,
      };

      return {
        ...emptyPeriods,
        ...cpyP,
        [curPeriod > 1 ? 'p' + curPeriod : curPeriod]: curP, // p1, p2 or ot
      };
    }
  },
);


export const getMatchPeriodsSets = createSelector(
  getMatchPeriods,
  getMatchSets,
  (periods, nrOfSets) => {
    const emptyPeriods = { };

    for (let i = 0; i < nrOfSets; i++) {
      emptyPeriods['p' + (i + 1)] = {
        home: '-',
        away: '-',
      };
    }

    const cpy = {
      ...emptyPeriods,
      ...periods,
    };
    delete cpy.ft;

    return cpy;
  },
);

export const getMatchStadium = createSelector(
  getMatchInfo,
  matchInfo => matchInfo.stadium,
);
export const getMatchCities = createSelector(
  getMatchInfo,
  matchInfo => matchInfo.cities,
);
export const getMatchTournament = createSelector(
  getMatchInfo,
  matchInfo => matchInfo.tournament,
);
export const getMatchSport = createSelector(
  getMatchInfo,
  matchInfo => matchInfo.sport,
);
export const getMatchRealCategory = createSelector(
  getMatchInfo,
  matchInfo => matchInfo.realcategory,
);
export const getMatchSeason = createSelector(
  getMatchInfo,
  matchInfo => matchInfo.season,
);
export const getMatchReferee = createSelector(
  getMatchInfo,
  matchInfo => matchInfo.referee,
);
export const getMatchManager = createSelector(
  getMatchInfo,
  matchInfo => matchInfo.manager,
);
export const getMatchJerseys = createSelector(
  getMatchInfo,
  matchInfo => matchInfo.jerseys,
);

export const getMatchSportName = createSelector(
  getMatchInfo,
  matchInfo => matchInfo.sportName,
);

export const getMatchTeams = createSelector(
  getMatchInfo,
  matchInfo => matchInfo.teams,
);
export const getMatchTennisDouble = createSelector(
  getMatchInfo,
  matchInfo => (matchInfo && matchInfo.teams ? matchInfo.teams.tennisDouble : undefined),
);
export const getMatchTeamsNames = createSelector(
  getMatchInfo,
  getMatchTennisDouble,
  (matchInfo, tennisDouble) => {
    if (tennisDouble) {
      return {
        home: tennisDouble.home[0].name,
        away: tennisDouble.away[0].name,
      };
    } else if (matchInfo.teams) {
      return {
        home: matchInfo.teams.home[0].name,
        away: matchInfo.teams.away[0].name,
      };
    } else {
      return {
        home: '',
        away: '',
      };
    }
  },
);
export const getRealCategoryName = createSelector(
  getMatchInfo,
  matchInfo => matchInfo.realcategoryName,
);
export const getTournamentName = createSelector(
  getMatchInfo,
  matchInfo => matchInfo.tournamentName,
);

export const getMatchStatus = createSelector(
  (fishnet, bcms, matchId) => getMatch(fishnet, matchId),
  (fishnet, bcms) => bcms,
  (fishnet, bcms, matchId) => matchId,
  (fishnet, bcms) => getMatchId(bcms),
  (match, bcms, matchId, bcmsMatchId) => {
    if (typeof bcms.qMMStatus === 'number' && bcmsMatchId === matchId) {
      return bcms.qMMStatus;
    }
    return (
      match && match.status
        ? match.status._id
        : undefined
    );
  },
);

export const getMatchStatusName = createSelector(
  getMatch,
  match => {
    if (match && match.status) {
      return match.status.name;
    }
    return undefined;
  },
);


export const getTournamentId = createSelector(
  getMatchInfo,
  match => match.tId,
);

export const getSeasonId = createSelector(
  getMatchInfo,
  match => match.seasonId,
);

export const getMatchTable = createSelector(
  getMatchInfo,
  mInfo => {
    const { table } = mInfo || {};
    return table || [];
  },
);


export const getMatchHomeUid = createSelector(
  getMatchTeams,
  getMatchTennisDouble,
  (teams, tennisDouble) => {
    if (tennisDouble) {
      return tennisDouble.home[0].uid;
    }

    if (teams && teams.home) {
      return teams.home[0].uniqueId;
    }

    return undefined;
  },

);
export const getMatchAwayUid = createSelector(
  getMatchTeams,
  getMatchTennisDouble,
  (teams, tennisDouble) => {
    if (tennisDouble) {
      return tennisDouble.away[0].uid;
    }

    if (teams && teams.away) {
      return teams.away[0].uniqueId;
    }

    return undefined;
  },
);

const getTablePos = (table, uid) => {
  const a = table.find(e => e.uid === uid);
  return a ? a.pos : null;
};

export const getMatchHomeLeagueRank = createSelector(
  getMatchTable,
  getMatchHomeUid,
  getTablePos,
);
export const getMatchAwayLeagueRank = createSelector(
  getMatchTable,
  getMatchAwayUid,
  getTablePos,
);

export const getMatchTableStr = createSelector(
  getMatchTable,
  table => table.map(e => '#' + e.pos + ' ' + e.name.toUpperCase() + ', ' + e.pts + 'pts'), // .join('   '),
);

export const getMatchHomeStatsOU = createSelector(
  (state, matchId) => state.stats.overunder[matchId],
  stats => (stats ? stats.home : null),
);

export const getMatchAwayStatsOU = createSelector(
  (state, matchId) => state.stats.overunder[matchId],
  stats => (stats ? stats.away : null),
);

const goalsScored = stats => (stats ? stats.goalsscored.ft.average : null);
const goalsConceded = stats => (stats ? stats.conceded.ft.average : null);


export const getMatchHomeScored = createSelector(
  getMatchHomeStatsOU,
  goalsScored,
);
export const getMatchHomeConceded = createSelector(
  getMatchHomeStatsOU,
  goalsConceded,
);

export const getMatchAwayScored = createSelector(
  getMatchAwayStatsOU,
  goalsScored,
);
export const getMatchAwayConceded = createSelector(
  getMatchAwayStatsOU,
  goalsConceded,
);


export const getMatchHomeStatsG = createSelector(
  (state, matchId) => state.stats.goals[matchId],
  stats => (stats ? stats.home : null),
);

export const getMatchAwayStatsG = createSelector(
  (state, matchId) => state.stats.goals[matchId],
  stats => (stats ? stats.away : null),

);

export const getMatchHomeStatsMatchOU = createSelector(
  (state, matchId) => state.stats.matchOverUnder[matchId],
  stats => (stats ? stats.home : null),
);

export const getMatchAwayStatsMatchOU = createSelector(
  (state, matchId) => state.stats.matchOverUnder[matchId],
  stats => (stats ? stats.away : null),
);

const minToSoccerPeriod = min => {
  let per = '';

  if (min <= 15) {
    per = '0-15';
  } else if (16 <= min && min <= 30) {
    per = '16-30';
  } else if (31 <= min && min <= 45) {
    per = '31-45';
  } else if (46 <= min && min <= 60) {
    per = '46-60';
  } else if (61 <= min && min <= 75) {
    per = '61-75';
  } else if (min >= 76) {
    per = '76-90';
  }

  return per;
};

const goalsScoredPeriod = (stats, min) => {
  if (!stats) {
    return null;
  }

  const per = minToSoccerPeriod(min);
  return stats.scored ? stats.scored[per] : null;
};

export const getMatchHomeScoredPeriod = createSelector(
  getMatchHomeStatsG,
  (state, matchId, min) => min,
  goalsScoredPeriod,
);
export const getMatchAwayScoredPeriod = createSelector(
  getMatchAwayStatsG,
  (state, matchId, min) => min,
  goalsScoredPeriod,
);

export const getMatchAttStrength = createSelector(
  getMatchInfo,
  mInfo => {
    const situs = mInfo && mInfo.situations ? mInfo.situations : [];
    const last15mins = situs.slice(Math.max(situs.length - 15, 0));

    if (last15mins.length === 0) {
      return null;
    }

    const sums = last15mins.reduce((acc, cur) => {
      acc.hAtt += cur.home.attack;
      acc.hDangAtt += cur.home.dangerous;
      acc.aAtt += cur.away.attack;
      acc.aDangAtt += cur.away.dangerous;


      return acc;
    }, {
      hAtt: 0,
      hDangAtt: 0,
      aAtt: 0,
      aDangAtt: 0,
    });

    const attackWeight = 0.2;
    const homeMixedAttack = sums.hDangAtt + (attackWeight * sums.hAtt);
    const awayMixedAttack = sums.aDangAtt + (attackWeight * sums.aAtt);
    const mixedAttackSum = homeMixedAttack + awayMixedAttack;

    const homeAttackingStrength = Math.round(
      (homeMixedAttack / mixedAttackSum) * 100,
    );
    const awayAttackingStrength = Math.round(
      (awayMixedAttack / mixedAttackSum) * 100,
    );

    return {
      homePercent: homeAttackingStrength,
      awayPercent: awayAttackingStrength,
    };
  },
);

export const getMatchGround = createSelector(
  getMatchInfo,
  mInfo => {
    if (!(mInfo && mInfo.tournament && mInfo.tournament.ground)) {
      return undefined;
    }

    return GROUND_ARRAY[mInfo.tournament.ground.mainid];
  },
);

export const getMatchGroundId = createSelector(
  getMatchInfo,
  mInfo => {
    if (!(mInfo && mInfo.tournament && mInfo.tournament.ground)) {
      return undefined;
    }
    return mInfo.tournament.ground.mainid;
  },
);


export const getFormForGround = ground => {
  if (!ground) {
    return null;
  }
  return {
    matchesWon: ground.matcheswon,
    matchesLost: ground.matcheslost,
    winPct: ground.matcheswon_percent,
  };
};

export const getMatchWinPctOverall = createSelector(
  (state, matchId) => state.stats.winPct[matchId],
  winPct => {
    if (!winPct) {
      return undefined;
    }

    const home = getFormForGround(winPct.home['ground-0']);
    const away = getFormForGround(winPct.away['ground-0']);

    if ((!home && !away)) {
      return undefined;
    }

    return {
      home: home || { matchesWon: 0, matchesLost: 0, winPct: '0' },
      away: away || { matchesWon: 0, matchesLost: 0, winPct: '0' },
    };
  },
);

export const getMatchWinPctGround = createSelector(
  (state, matchId) => state.stats.winPct[matchId],
  (state, matchId, ground) => ground,
  (winPct, ground) => {
    if (!winPct) {
      return undefined;
    }

    let groundKey = null;
    switch (ground) {
      case OVERALL:
        groundKey = 'ground-0';
        break;
      case CLAY:
        groundKey = 'ground-1';
        break;
      case HARDCOURT:
        groundKey = 'ground-2';
        break;
      case INDOOR:
        groundKey = 'ground-3';
        break;

      default:
        break;
    }

    const home = getFormForGround(winPct.home[groundKey]);
    const away = getFormForGround(winPct.away[groundKey]);

    if ((!home && !away)) {
      return undefined;
    }

    return {
      home: home || { matchesWon: 0, matchesLost: 0, winPct: '0' },
      away: away || { matchesWon: 0, matchesLost: 0, winPct: '0' },
    };
  },
);

export const getMatchTeamsRank = createSelector(
  (state, matchId) => state.stats.ranking[matchId],
  ranking => {
    if (!ranking) {
      return undefined;
    }

    return {
      home: ranking.home.rank,
      away: ranking.away.rank,
      sex: ranking.home.sex || ranking.away.sex,
    };
  },
);

export const getMatchHomeAttStrength = createSelector(
  getMatchAttStrength,
  homeAwayStrength => (homeAwayStrength ? homeAwayStrength.homePercent : null),
);

export const getMatchAwayAttStrength = createSelector(
  getMatchAttStrength,
  homeAwayStrength => (homeAwayStrength ? homeAwayStrength.awayPercent : null),
);

const oppositeTeam = team => {
  if (team === 'home') {
    return 'away';
  } else {
    return 'home';
  }
};
const getLastMatchesResult = (stats, teamUid, nrOfMatches, teamSide) => {
  if (!stats || !stats.home) {
    return [];
  }

  const lastMatches = stats[teamSide] // latest matches first in array
    .filter(m => m.result && m.status === null) // filter valid matches
    .slice(0, nrOfMatches) // take first nrOfMatches
    .map(m => { // 1 = win, 0 = draw, -1 = loss
      const team = m.teams.home.uid === teamUid ? 'home' : 'away';

      let result = m.result.winner === team
        ? 1
        : m.result.winner === oppositeTeam(team)
          ? -1
          : 0;

      // differentiate between loss and ot loss
      if (m.result.period === 'ot') {
        result += result; // 1 => 2, -1 => -2
      }

      return result;
    });

  while (lastMatches.length && (lastMatches.length < nrOfMatches)) {
    lastMatches.push(null);
  }

  return lastMatches;
};


export const getLastMatches = (stats, teamUid, nrOfMatches, teamSide) => {
  if (!stats || !stats.home) {
    return [];
  }

  return stats[teamSide] // latest matches first in array
    .filter(m => m.result && m.status === null) // filter valid matches
    .slice(0, nrOfMatches); // take first nrOfMatches
};

export const getMatchHomeLastM = createSelector(
  (state, matchId) => state.stats.lastx[matchId],
  getMatchHomeUid,
  (state, matchId, nrOfMatches) => nrOfMatches,
  () => 'home',
  getLastMatches,
);

export const getMatchAwayLastM = createSelector(
  (state, matchId) => state.stats.lastx[matchId],
  getMatchAwayUid,
  (state, matchId, nrOfMatches) => nrOfMatches,
  () => 'away',
  getLastMatches,
);


const sumArrN = (arr, n) => arr.slice(0, n).reduce((acc, cur) => acc + cur, 0);

// weighted moving average of last 5 matches
const getForm = (stats, teamUid, nrOfMatches, teamSide, sportId) => {
  if (!stats || !stats.home) {
    return 0;
  }

  const wdlWeights = [15, 12, 9, 6, 3];

  const lastMatches = stats[teamSide] // latest matches first in array
    .filter(m => m.result && m.status === null) // filter valid matches
    .slice(0, nrOfMatches); // take first nrOfMatches

  const wins = lastMatches.reduce((acc, cur, i) => {
    const team = cur.teams.home.uid === teamUid ? 'home' : 'away';

    if (sportId === sport.soccer) {
      if (cur.result.winner === team) {
        return acc + wdlWeights[i];
      } else if (cur.result.home === cur.result.away) {
        return acc + (wdlWeights[i] / 3); // 1 point
      }
    } else if (cur.result.winner === team) {
      return acc + wdlWeights[i];
    }

    return acc;
  }, 0);

  let formPct = (wins / (sumArrN(wdlWeights, lastMatches.length) || 1)) * 100;
  formPct = Math.round(formPct); // round


  return formPct;
};

export const getMatchHomeLastMResult = createSelector(
  (state, matchId) => state.stats.lastx[matchId],
  getMatchHomeUid,
  (state, matchId, nrOfMatches) => nrOfMatches,
  () => 'home',
  getLastMatchesResult,
);

export const getMatchAwayLastMResult = createSelector(
  (state, matchId) => state.stats.lastx[matchId],
  getMatchAwayUid,
  (state, matchId, nrOfMatches) => nrOfMatches,
  () => 'away',
  getLastMatchesResult,
);

export const getMatchHomeForm = createSelector(
  (state, matchId) => state.stats.lastx[matchId],
  getMatchHomeUid,
  (state, matchId, nrOfMatches) => nrOfMatches,
  () => 'home',
  getMatchSportId,
  getForm,
);

export const getMatchAwayForm = createSelector(
  (state, matchId) => state.stats.lastx[matchId],
  getMatchAwayUid,
  (state, matchId, nrOfMatches) => nrOfMatches,
  () => 'away',
  getMatchSportId,
  getForm,
);

export const getMatchPrevMeetings = createSelector(
  (state, matchId) => state.stats.prevMeetings[matchId],
  prev => prev,
);

export const getMatchFirstHalfScore = createSelector(
  getMatch,
  getMatchSportId,
  (match, sportId) => {
    if (sportId === sport.soccer || sportId === sport.esoccer) {
      return match && match.periods ? match.periods.p1 : null;
    } else {
      if (match && match.periods && match.periods.p1 && match.periods.p2) {
        return {
          home: match.periods.p1.home + match.periods.p2.home,
          away: match.periods.p1.away + match.periods.p2.away,
        };
      }
      return null;
    }
  },
);


export const getPreMatchOdds = state => (state.markets ? state.markets : null);

export const getMatchIdsWithOdds = createSelector(
  state => state.markets.matches,
  matches => (matches ? Object.keys(matches) : []),
);

export const getClientMatchId = (state, matchId) => (
  state.clientMatchIds[matchId]
    ? state.clientMatchIds[matchId]
    : null
);

export const getTournamentFlag = (state, matchId) => {
  const match = state.matchInfo[matchId];
  if (!match) {
    return null;
  }

  if (match.realcategoryCountryCodeA2) {
    return match.realcategoryCountryCodeA2;
  } else if (match.tournamentTennisInfoA2) {
    return match.tournamentTennisInfoA2;
  }

  return 'international';
};

export const getNextMatches = state => state.nextMatchesById.map(matchId => (
  state.nextMatches[matchId]
));
export const getIsNextMatchesLoaded = state => state.nextMatchesLoaded;

export const getNextMatchesById = state => state.nextMatchesById;

export const getNextMatchDisplaySolution = (state, matchId) => {
  if (!state.nextMatches[matchId]) {
    return null;
  }

  return state.nextMatches[matchId].displaySolution;
};

export const getNextMatchStatus = (state, matchId) => {
  if (!state.nextMatches[matchId]) {
    return null;
  }

  return state.nextMatches[matchId].status;
};

export const getNextMatchStatusMessage = (state, matchId) => {
  if (!state.nextMatches[matchId]) {
    return null;
  }

  return state.nextMatches[matchId].statusMessage;
};

export const getNextMatchTimestamp = (state, matchId) => {
  if (!state.nextMatches[matchId]) {
    return null;
  }

  return state.nextMatches[matchId].timestamp;
};

export const showNextMatchRealCategory = (state, matchId) => {
  if (state.nextMatches[matchId] && eSports.includes(state.nextMatches[matchId].sportId)) {
    return true;
  }
  if (!state.nextMatches[matchId]) {
    return null;
  }

  return state.nextMatches[matchId].showRealcategory;
};

export const getNonMainMatches = createSelector(
  (state, matchId) => matchId,
  getMatches,
  getNextMatchesById,
  (matchId, matches, nextMatches) => matches.filter(m => m !== matchId && !nextMatches.includes(m)),
);

export const getMatchStartTime = createSelector(
  getMatch,
  match => {
    if (!(match && match._dt)) {
      return null;
    }
    return match._dt.uts;
  },
);

export const getMatchEvents = createSelector(
  getMatchTimeline,

  matchTimeline => {
    if (!matchTimeline) {
      return null;
    }

    return matchTimeline.events;
  }
  ,
);

export const getMatchEventsLastN = createSelector(
  getMatchTimeline,
  (state, matchId, n) => n,

  (matchTimeline, n) => {
    if (!matchTimeline) {
      return null;
    }

    const numEvents = n || 5;

    return Object.values(matchTimeline.events).slice(-numEvents).map(e => ({
      type: e.type,
      team: e.team,
      player: e.player ? e.player.name : undefined,
      scorer: e.scorer ? e.scorer.name : undefined,
      secs: e.seconds,
      calc: new Date(e.seconds * 1000).toISOString().substr(14, 5),
      q: new Date((12 * 60 - (e.seconds - 36 * 60)) * 1000).toISOString().substr(14, 5),


    }));
  }
  ,
);

export const getMatchRound = createSelector(
  getMatchInfo,

  matchInfo => {
    if (!matchInfo) {
      return null;
    }

    return matchInfo.round;
  }
  ,
);

export const getMatchRoundName = createSelector(
  getMatchInfo,

  matchInfo => {
    if (!matchInfo || !matchInfo.roundname) {
      return null;
    }
    return matchInfo.roundname.name;
  }
  ,
);

export const getMatchEventsPeriodChunks = createSelector(
  getMatchEvents,
  events => {
    if (!events) {
      return null;
    }
    const eventsArr = Object.values(events);

    const firstHalf = 0;
    let secondHalf = -1;
    let firstExtra = -1;
    let secondExtra = -1;

    let curP = -1;
    let lastPIdx = -1;

    const eventsPeriod = eventsArr.reduce((acc, cur, curIdx) => {
      if (cur.type === 'periodstart') {
        if (cur.period === 31) {
          acc['p1'] = eventsArr.slice(firstHalf, curIdx);
          curP = 2;
          lastPIdx = curIdx;
          secondHalf = curIdx;
        }
        if (cur.period === 32) {
          acc['p2'] = eventsArr.slice(lastPIdx, curIdx);
          curP = 3;
          lastPIdx = curIdx;
          firstExtra = curIdx;
        }
        if (cur.period === 33) {
          acc['p3'] = eventsArr.slice(lastPIdx, curIdx);
          curP = 4;
          lastPIdx = curIdx;
          secondExtra = curIdx;
        }
        if (cur.period === 34) {
          acc['p4'] = eventsArr.slice(lastPIdx, curIdx);
          lastPIdx = curIdx;
        }
      }
      if (cur.type === 'match_ended') {
        acc['p' + curP] = eventsArr.slice(lastPIdx, curIdx);
        curP = 5;
        lastPIdx = curIdx;
      }

      return acc;
    }, {});

    // chunck live periods
    if (curP === -1) {
      eventsPeriod['p1'] = eventsArr;
    } else if (curP === 2) {
      eventsPeriod['p2'] = eventsArr.slice(secondHalf);
    } else if (curP === 3) {
      eventsPeriod['p3'] = eventsArr.slice(firstExtra);
    } else if (curP === 4) {
      eventsPeriod['p4'] = eventsArr.slice(secondExtra);
    }

    return eventsPeriod;
  },
);

export const getMatchTimeEvents = createSelector(
  getMatchEventsPeriodChunks,
  eventsChunked => {
    if (!eventsChunked) {
      return null;
    }

    const eventsArr = Object.values(eventsChunked);

    let stopTime = 0;
    let stopUts = 0;

    if (eventsArr && eventsArr.length) {
      const elem = eventsArr[eventsArr.length - 1];

      for (let i = 0; i < elem.length; i++) {
        if (elem[i].name === 'Time start/stop') {
          if (elem[i].params['1'] === '1') { // 1 = time running
            if (stopUts) {
              stopTime += elem[i].uts - stopUts;
              stopUts = 0;
            }
          } else {
            stopUts = elem[i].uts;
          }
        }
      }
    }

    // hasnt been resumed yet
    if (stopUts > 0) {
      stopTime += (Date.now() / 1000) - stopUts;
    }

    return stopTime;
  },
);


export const getMatchPTime = createSelector(
  getMatch,
  getMatchTimeEvents,
  (state, matchId, bcms) => bcms.qStopTime,
  (info, stopTime, addStopTime) => {
    if (!info) {
      return null;
    }

    if (addStopTime && stopTime > 0) {
      // eslint-disable-next-line no-console
      console.log('stopTime', stopTime);
      return info.ptime + stopTime;
    }
    return info.ptime;
  },
);


export const getMatchSuspended = createSelector(
  getMatchEventsPeriodChunks,
  (state, matchId, bcms) => bcms.qStopTime,
  (eventsChunked, useSuspend) => {
    if (!useSuspend || !eventsChunked) {
      return false;
    }

    const eventsArr = Object.values(eventsChunked);

    let stopUts = 0;

    if (eventsArr && eventsArr.length) {
      const elem = eventsArr[eventsArr.length - 1];

      for (let i = 0; i < elem.length; i++) {
        if (elem[i].name === 'Time start/stop') {
          if (elem[i].params['1'] === '1') {
            if (stopUts) {
              // stopTime += elem[i].uts - stopUts;
              stopUts = 0;
            }
          } else {
            stopUts = elem[i].uts;
          }
        }
      }
    }

    return !!stopUts;
  },
);

export const getMatchTimeInfo = createSelector(
  getMatch,
  match => {
    if (!match) {
      return undefined;
    }

    return match.timeinfo || { played: '0', remaining: '0', running: false };
  },
);
