import {
  take,
  put,
  select,
  apply,
  delay,
  spawn,
  race,
  takeLatest,
  fork,
} from 'redux-saga/effects';
import {
  BetshopConfigRequest,
  BetshopOddsPanelSettingsRequest,
} from 'bcms-api';
import updateBcmsConfig from 'actions/bcmsConfig';
import {
  paymentCheckSuccess,
  paymentCheckFailed,
  loggedInStatusSuccess,
  loggedInStatusFailed,
  accountDisabled,
  accountEnabled,
  setBId,
} from 'actions/authorization';
import {
  START_LCR,
  LOGGED_IN_SUCCESS,
  LOGGED_IN_FAILURE,
  CHANGE_MAIN_MATCH,
  BCMS_BETSHOP_CONFIG_LOAD_SUCCESS,
} from 'constants/actions';
import monitorSrLive from 'sagas/srLive';
import runLoggerSaga from 'sagas/logger';
import {
  isOldHostName,
  getUserIdFromUrl,
  isNewHostName,
  redirectCurrentHostToClient,
  redirectToLoginByToken,
  redirectToLogin,
} from 'utils/Url';
import {
  getAuthPollingInterval,
  getStreamingFeedsURL,
  getAuthUrl,
  getLoggedInStatus,
  getBetshopId,
  getMatchSportId,
  getAutoSport,
  getChannelId,
  getIsOperator, getTechnicalIssuesStatus,
} from 'reducers/index';
import {
  updateMarketPackages,
  marketPackagesLoading,
} from 'actions/marketPackages';
import CheckAuthorization from 'auth-api';
import { DisplayError } from 'utils/LoggerItem';
import loadPlayer from 'sagas/player';
import loadSir from 'sagas/sir';
import fishnetSaga from 'sagas/fishnet/fishnet';
import fishnetCleanup from 'sagas/fishnet/fishnetCleanup';
import { CHANNELS_ESOCCER_ONLY, SEC } from 'constants/app';
import { sport } from 'constants/enum';
import {
  HTTP_STATUS_BAD_GATEWAY,
  HTTP_STATUS_CODE_LOCKED,
  HTTP_STATUS_CODE_PAYMENT_REQUIRED,
  HTTP_STATUS_NOT_FOUND,
  HTTP_STATUS_SERVICE_UNAVAILABLE,
} from 'constants/bcms.js';
import { makeRestartable, sagaCancel } from './utilSagas';
import onBCMSChange from './bcms';
import monitorStage from './stage';
import { srLiveLoadError } from '../actions/srLive';
import autoSportSaga from './autosport';
import runLoginAuthCheckSaga from './loginAuthCheck/loginAuthCheck';
import { setTechnicalIssuesStatus } from '../actions/stages';


function* authorizationStatusCheck(betshopUserId) {
  try {
    let nextDelay = 2;
    const multi = 1.25;
    const pollInterval = yield select(getAuthPollingInterval);
    let usedDelay = pollInterval;

    for (;;) {
      let url = '';

      if (isOldHostName()) {
        url = yield select(getAuthUrl);
      } else {
        url = '/auth/validate';
      }

      const isOperatorStatus = yield select(getIsOperator);
      const technicalIssues = yield select(getTechnicalIssuesStatus);

      const request = new CheckAuthorization(url, betshopUserId, isOperatorStatus);
      const response = yield apply(request, request.get);

      const {
        isAuthorized, userId = null, isOperator, token, bId,
      } = response;

      if (response
        && [HTTP_STATUS_NOT_FOUND, HTTP_STATUS_SERVICE_UNAVAILABLE, HTTP_STATUS_BAD_GATEWAY].includes(response.code)
      ) {
        if (technicalIssues === null) {
          yield put(setTechnicalIssuesStatus(true));
        }
      } else if (isNewHostName() && !isAuthorized) {
        if (response.code === HTTP_STATUS_CODE_LOCKED) {
          yield put(setTechnicalIssuesStatus(false));
          yield put(accountDisabled());
          yield put(loggedInStatusFailed());
        }

        yield put(setTechnicalIssuesStatus(false));
        yield put(loggedInStatusFailed());
        redirectToLogin();
        return;
      } else if (isOldHostName() && userId && token) {
        redirectToLoginByToken(token);
        return;
      } else if (isNewHostName()
        && response && !isOperator
        && userId && isAuthorized
        && userId !== getUserIdFromUrl()
      ) {
        redirectCurrentHostToClient(userId);
        return;
      } else if (isAuthorized && isAuthorized.error) {
        // if error dont do anything
        // ie. bcms down, keep running if been auth
        if (yield select(getLoggedInStatus) === null) { // if first time
          yield put(loggedInStatusFailed());
        }
      } else if (!isAuthorized) {
        yield put(loggedInStatusFailed());
        usedDelay = nextDelay;
        nextDelay = (nextDelay * multi) < pollInterval
          ? (nextDelay * multi)
          : pollInterval;
      } else if (isAuthorized) {
        yield put(setTechnicalIssuesStatus(false));
        yield put(loggedInStatusSuccess(isOperator));
        yield put(setBId(bId));
        yield put(accountEnabled());
        nextDelay = 2;
        usedDelay = pollInterval;
      }

      yield race([
        take(START_LCR),
        delay(usedDelay * SEC),
      ]);
    }
  } catch (error) {
    yield put(loggedInStatusFailed());
    throw error;
  }
}

function* pollBetshopConfig(userId) {
  for (;;) {
    const technicalIssues = yield select(getTechnicalIssuesStatus);

    if (technicalIssues) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const baseUrl = yield select(getStreamingFeedsURL);
    const isOperator = yield select(getIsOperator);

    const request = new BetshopConfigRequest(baseUrl, userId, isOperator);
    const response = yield apply(request, request.get);

    if (response && response.betshopConfig) {
      yield put(paymentCheckSuccess());
      yield put(updateBcmsConfig(response.betshopConfig));
    } else if (isNewHostName() && response && response.code === HTTP_STATUS_CODE_PAYMENT_REQUIRED) {
      yield put(paymentCheckFailed());
    } else if (response && response.error && response.message === 'Payment check failed') { // set paymentCheckFailed no matter what if message 'Payment check failed'
      yield put(paymentCheckFailed());
    } else {
      // other errors dont do anything
      // ie. bcms down, keep running if been logged in
    }
    yield delay(request.getMaxAge() * SEC);
  }
}

function* loadOddsMarketPackages() {
  for (;;) {
    const { payload: matchId } = yield take(CHANGE_MAIN_MATCH);
    const url = yield select(getStreamingFeedsURL);
    const betshopId = yield select(getBetshopId);
    let matchSportId;
    while (!(matchSportId > 0)) {
      matchSportId = yield select(getMatchSportId, matchId);
      yield delay(1000);
    }

    // does first request to BetshopOddsPanelSettings
    // and  if it contains a package it requests OddsPackageMarkets too
    const request = new BetshopOddsPanelSettingsRequest(
      url,
      betshopId,
      matchSportId,
    );
    yield put(marketPackagesLoading());
    const response = yield apply(request, request.exec); // run exec() not get()

    let marketPackages = [];
    if (response && response.data && response.data.markets) {
      marketPackages = response.data.markets;
    }
    yield put(updateMarketPackages(marketPackages));
  }
}

function* onAutoSport() {
  yield take(BCMS_BETSHOP_CONFIG_LOAD_SUCCESS);
  let saga = null;
  let autoSport = yield select(getAutoSport);
  const channelId = yield select(getChannelId);

  let prev = autoSport;
  if (autoSport) {
    saga = yield fork(autoSportSaga, autoSport);
  } else if (CHANNELS_ESOCCER_ONLY.includes(channelId)) {
    saga = yield fork(autoSportSaga, sport.esoccer);
  }

  yield takeLatest(START_LCR, function* () {
    autoSport = yield select(getAutoSport);
    if (autoSport !== prev) {
      prev = autoSport;
      saga = sagaCancel(saga);
      if (autoSport) {
        saga = yield fork(autoSportSaga, autoSport);
      } else if (CHANNELS_ESOCCER_ONLY.includes(channelId)) {
        saga = yield fork(autoSportSaga, sport.esoccer);
      }
    }
  });
}

export function* userSaga(userId) {
  const restartAble = [];

  try {
    restartAble.push(yield spawn(makeRestartable(null, authorizationStatusCheck, userId)));

    const { type } = yield take([LOGGED_IN_SUCCESS, LOGGED_IN_FAILURE]);
    if (type === LOGGED_IN_FAILURE) {
      yield put(paymentCheckFailed()); // show logged in failed screen
      yield take(LOGGED_IN_SUCCESS);
    }

    restartAble.push(yield spawn(makeRestartable(null, onBCMSChange, userId)));
    restartAble.push(yield spawn(makeRestartable(null, monitorStage)));
    restartAble.push(yield spawn(makeRestartable(null, pollBetshopConfig, userId)));
    // Disabled - feature does not work
    // restartAble.push(yield spawn(makeRestartable(null, loadOddsMarketPackages)));
    restartAble.push(yield spawn(makeRestartable(null, fishnetSaga)));
    restartAble.push(yield spawn(makeRestartable(null, fishnetCleanup)));


    yield fork(onAutoSport);

    yield take(LOGGED_IN_FAILURE);

    throw new DisplayError('User', 'Logged Out');
  } finally {
    for (let i = 0; i < restartAble.length; i++) {
      const saga = restartAble[i];
      sagaCancel(saga);
    }

    yield put(srLiveLoadError('Reload SRLive to get new clients widgetloader'));
  }
}

function* lcrSaga() {
  let userId = null;
  let saga = null;

  yield spawn(makeRestartable(null, runLoginAuthCheckSaga));
  let { payload: { userId: newUserId } } = yield take(START_LCR);

  // spawn detached, respawns if they error
  yield spawn(makeRestartable(null, runLoggerSaga));
  yield spawn(makeRestartable(null, loadPlayer));
  yield spawn(makeRestartable(null, loadSir));
  yield spawn(makeRestartable(null, monitorSrLive));

  for (;;) {
    if (newUserId && newUserId !== userId) {
      userId = newUserId;
      saga = sagaCancel(saga);
      saga = yield spawn(makeRestartable({ maxWait: 30 * SEC }, userSaga, userId));
    }
    ({ payload: { userId: newUserId } } = yield take(START_LCR));
  }
}

export default lcrSaga;
