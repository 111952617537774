// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IssuesStage__container___1dHzQ{background:#1a1a1a;display:-ms-flexbox;display:flex;-ms-flex-flow:column;flex-flow:column;height:100%;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;-ms-flex-item-align:center;align-self:center;width:100%}.IssuesStage__messageBox___20jip{max-width:340px}.IssuesStage__messageBoxTechnicalIssues___1z0AL{max-width:540px}.IssuesStage__message___3e9da{margin:20px 20px;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;border:1px solid #fff;border-radius:4px}.IssuesStage__title___11sf-{color:#000;background-color:#fff;padding:8px 15px;border-top-right-radius:3px;border-top-left-radius:3px;display:-ms-flexbox;display:flex;-ms-flex-flow:row;flex-flow:row;-ms-flex-pack:center;justify-content:center;font-size:24px;-ms-flex-align:center;align-items:center}.IssuesStage__icon___3gbLY{margin:0px 10px}.IssuesStage__messageBody___28AhI{text-align:center;font-size:25px;padding:15px}a{color:#1b7398;text-decoration:none}", ""]);
// Exports
exports.locals = {
	"container": "IssuesStage__container___1dHzQ",
	"messageBox": "IssuesStage__messageBox___20jip",
	"messageBoxTechnicalIssues": "IssuesStage__messageBoxTechnicalIssues___1z0AL",
	"message": "IssuesStage__message___3e9da",
	"title": "IssuesStage__title___11sf-",
	"icon": "IssuesStage__icon___3gbLY",
	"messageBody": "IssuesStage__messageBody___28AhI"
};
module.exports = exports;
