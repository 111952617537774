import { createReducer } from 'utils';
import {
  PAYMENT_CHECK_SUCCESS,
  PAYMENT_CHECK_FAILED,
  LOGGED_IN_SUCCESS,
  LOGGED_IN_FAILURE,
  ACCOUNT_DISABLED,
  ACCOUNT_ENABLED,
  SET_BID,
} from 'constants/actions';
import { createSelector } from 'reselect';

const initialState = {
  paymentCheckSucces: null,
  isLoggedIn: null,
  isOperator: 0,
  accountActive: true,
  bId: null,
};

export default createReducer(initialState, {
  [PAYMENT_CHECK_FAILED]: state => ({
    ...state,
    paymentCheckSucces: false,
  }),
  [PAYMENT_CHECK_SUCCESS]: state => ({
    ...state,
    paymentCheckSucces: true,
  }),
  [ACCOUNT_DISABLED]: state => ({
    ...state,
    accountActive: false,
  }),
  [ACCOUNT_ENABLED]: state => ({
    ...state,
    accountActive: true,
  }),
  [LOGGED_IN_SUCCESS]: (state, payload) => ({
    ...state,
    isLoggedIn: true,
    isOperator: payload,
  }),
  [LOGGED_IN_FAILURE]: state => ({
    ...state,
    isLoggedIn: false,
    isOperator: 0,
  }),
  [SET_BID]: (state, payload) => ({
    ...state,
    bId: payload,
  }),
});

export const getPaymentCheckSuccess = createSelector(
  state => state.paymentCheckSucces,
  paymentCheckSucces => paymentCheckSucces,
);

export const getLoggedInStatus = createSelector(
  state => state.isLoggedIn,
  isLoggedIn => isLoggedIn,
);
export const getAccountActiveStatus = createSelector(
  state => state.accountActive,
  accountActive => accountActive,
);

export const getIsOperator = createSelector(
  state => state.isOperator,
  isOperator => isOperator,
);

export const getBId = createSelector(
  state => state.bId,
  bId => bId,
);
