import {
  fork,
  put,
  select,
  apply,
  delay,
  takeLatest,
} from 'redux-saga/effects';
import { HighlightsOfTheDayRequest } from 'bcms-api';
import { updateHighlights, updateFunFactIdx } from 'actions/highlights';
import {
  getUserId,
  getStreamingFeedsURL,
  getHOTDPhrases,
  getHOTDMatch,
  getChannelId,
  getIsOperator,
} from 'reducers/index';
import { take } from 'lodash';
import { BLOCK_HERE, SEC } from '../../constants/app';
import { UPDATE_MATCH_FUNFACTS } from '../../actions/fishnet/actionTypes';
import { changeHOTDMatch } from '../../actions/bcmsConfig';

const POLL_INTERVAL = 60 * 1000;

function* pollHighlights(userId, channelId) {
  const baseUrl = yield select(getStreamingFeedsURL);
  const isOperator = yield select(getIsOperator);

  const request = new HighlightsOfTheDayRequest(baseUrl, userId, channelId, isOperator);
  let matchId = null;
  let first = true;
  for (;;) {
    const highlights = yield apply(request, request.get);
    if (
      (highlights && !highlights.error)
      && (highlights.data && highlights.data.length)
      && (matchId !== highlights.data[0]) // only update if new id is different
    ) {
      // eslint-disable-next-line prefer-destructuring
      matchId = parseInt(highlights.data[0], 10);
      yield put(updateHighlights(matchId)); // update state with new id

      // if query param is used selector will not return the new one we updated
      const usedId = yield select(getHOTDMatch);

      // hack to not be blocked until betshop config polling on initial start
      // also don't run action if query param used
      if (first && usedId === matchId) {
        first = false;
        yield put(changeHOTDMatch(usedId));
      }
    }
    yield delay(POLL_INTERVAL);
  }
}

function* monitorHOTD() {
  const phrases = yield select(getHOTDPhrases);

  if (phrases.length) {
    for (let i = 0; ; i++) {
      yield put(updateFunFactIdx(i % phrases.length));
      yield delay(30 * SEC);
    }
  }
}

export default function* runHighlights() {
  try {
    const userId = yield select(getUserId);
    const channelId = yield select(getChannelId);

    yield fork(pollHighlights, userId, channelId);
    yield takeLatest([UPDATE_MATCH_FUNFACTS], monitorHOTD);

    yield take(BLOCK_HERE);
  } finally {
    yield put(updateFunFactIdx(null));
  }
}
