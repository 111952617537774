import React from 'react';

import { useImportImageNewDesign } from 'hooks';

import styles from './Footer.scss';

const Footer = () => {
  const facebookIcon = useImportImageNewDesign('auth/login/icons/fb.png');
  const twitterIcon = useImportImageNewDesign('auth/login/icons/tw.png');
  const linkedinIcon = useImportImageNewDesign('auth/login/icons/ln.png');
  const youtubeIcon = useImportImageNewDesign('auth/login/icons/yt.png');
  const instagramIcon = useImportImageNewDesign('auth/login/icons/ig.png');

  return (
    <footer
      className={`${styles.footer} `}
    >
      <div className={'flex w-full justify-center'}>
        <div className={'flex items-start justify-center text-left w-2/3'}>
          <div className={'flex flex-col items-start w-1/3'}>
            <h1 className='mb-4 flex justify-center uppercase'>Sportradar AG</h1>
            <p>Feldlistrasse 2<br />
              <span>CH-9000 St. Gallen <br /> Switzerland</span>
            </p>
            <p>VAT identification number<br />
              CHE-113.910.142 MWST
            </p>
            <p className={'mt-8'}>© Sportradar AG, St. Gallen Switzerland</p>
          </div>
          <div className={'flex flex-col w-1/3'}>
            <p className='hidden-xs'>Office: +41 71 517 72 00<br />
              Fax: +41 71 517 72 99<br />
              Mail:
              <a href='mailto:info@betradar.com' target='_blank' rel='noreferrer'> Info@betradar.com</a><br />
              <a className={'ml-8'} href='mailto:sales@betradar.com' target='_blank' rel='noreferrer'>Sales@betradar.com</a>
            </p>
            <h1>Sportradar is social</h1>
            <div className={`${styles.social_icons} flex flex-row gap-x-8 mb-8 mt-2`}>
              <a href='https://www.facebook.com/pages/Sportradar/391162040945181' target='blank'>
                <div><img src={facebookIcon} alt={'Facebook icon'} /></div>
              </a>
              <a href='https://twitter.com/Sportradar' target='blank'>
                <div><img src={twitterIcon} alt={'Twitter icon'} /></div>
              </a>
              <a href='https://www.linkedin.com/company/sportradar/' target='blank'>
                <div><img src={linkedinIcon} alt={'Linkedin icon'} /></div>
              </a>
              <a
                href='https://www.instagram.com/sportradarofficial/'
                target='_blank'
                rel='noreferrer'
              >
                <div><img className={`${styles.iglogo}`} src={instagramIcon} alt={'Instagram icon'} /></div>
              </a>
              <a href='https://www.youtube.com/user/BetradarOfficial' target='blank'>
                <div><img src={youtubeIcon} alt={'Youtube icon'} /></div>
              </a>
            </div>
            <p>
              <span><a href='https://betradar.com/about-us/imprint/'>Imprint</a></span>
              <span><a href='https://betradar.com/about-us/privacy/'>Privacy Notice</a></span>
              <span><a href='https://betradar.com/about-us/cookies/'>Cookie Notice</a></span>
              <span><a href='https://betradar.com/about-us/faq/'>FAQ</a></span>
              <span><a href='https://betradar.com/sitemap/'>Sitemap</a></span>
            </p>
          </div>
          <div className={'flex flex-col w-1/3'}>
            <h1>Newsletter Signup</h1>
            <h2>Signup for our newsletter and never miss an update.<br />Please enter your e-mail address.</h2>
            <span>
              <button
                type='button'
                className={`${styles.subscribe_button}`}
                data-toggle='modal'
                data-target='#subscription'
              >Subscribe
              </button>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
