import {
  put,
  select,
  fork,
  call,
  takeLatest,
  delay,
} from 'redux-saga/effects';
import {
  BETTING_SUGGESTION_EVENT,
} from 'constants/actions';
import {
  clearBettingSuggestionEvents,
  initialWaitOverBS,
  showLatestBettingSuggestionEvent,
  newBettingSuggestionEvent,
  hideLatestBettingSuggestionEvent,
} from 'actions/components';
import {
  getMatchCurPeriod,
  getMatchGameScore,
  getMatchPeriodsSets,
  getMatchSportId,
  getMatchStatusName,
} from 'reducers/index';
import TranslatorDict from 'utils/Translator';
import {
  BETTING_SUGGESTION_DURATION,
  BETTING_SUGGESTION_INITIAL_WAIT,
  BETTING_SUGGESTION_WAIT_TIME,
} from 'constants/app';
import { UPDATE_MATCH_DELTA } from '../../actions/fishnet/actionTypes';
import { eventTypeId as eId, sport } from '../../constants/enum';
import { waitForMatchData } from '../utilLCR';


const GOAL = 'GOAL';
const RED_CARD = 'RED_CARD';
const SET_STARTING_NOW = 'SET_STARTING_NOW';
const START_OF_HALF = 'START_OF_HALF';
const PERIOD_END = 'PERIOD_END';
const TIMEOUT = 'TIMEOUT';
const SET_IS_DRAWING = 'SET_IS_DRAWING';

const eventMapping = {
  [START_OF_HALF]: {
    sentence: '{X} starting now',
    matchStatus: true,
  },
  [SET_STARTING_NOW]: {
    sentence: '{X} starting now',
    matchStatus: true,

  },
  [PERIOD_END]: {
    sentence: '{X}',
    matchStatus: true,
  },
  [GOAL]: {
    sentence: 'Goal',
    extra: '',
  },
  [RED_CARD]: {
    sentence: 'Red card',
    extra: '',
  },
  [TIMEOUT]: {
    sentence: 'Timeout',
    extra: '',
  },
  [SET_IS_DRAWING]: {
    sentence: '{X} is drawing to a close',
    matchStatus: true,
  },


};

function* handleEvent() {
  // wait before showing BS
  yield delay(BETTING_SUGGESTION_WAIT_TIME);
  yield put(showLatestBettingSuggestionEvent());

  yield delay(BETTING_SUGGESTION_DURATION);
  yield put(hideLatestBettingSuggestionEvent());
}

function* addEvent(matchId, seenEvents, bsEvent, e) {
  let translatedString = TranslatorDict.t(eventMapping[bsEvent].sentence);

  if (eventMapping[bsEvent].matchStatus) {
    const status = yield select(getMatchStatusName, matchId);
    translatedString = translatedString.replace('{X}', status);
  } else if (eventMapping[bsEvent].extra) {
    const extraTranslation = TranslatorDict.t(eventMapping[bsEvent].extra);
    translatedString = translatedString.replace('{X}', extraTranslation);
  }


  const newEvent = {
    key: bsEvent,
    name: translatedString,
    ffEvent: {
      ...e,
    },
  };

  yield put(newBettingSuggestionEvent(newEvent));
  seenEvents.add(e._id);
}


export function* gotEvent(matchId, sportId, seenEvents, payload) {
  try {
    if (payload.payload
       && payload.payload.entities
       && payload.payload.entities.match
       && payload.payload.entities.match[matchId]
       && payload.payload.entities.match[matchId].events
    ) {
      const events = Object.values(payload.payload.entities.match[matchId].events);

      // eslint-disable-next-line no-restricted-syntax
      for (const e of events) {
        if (!seenEvents.has(e._id)) {
          const typeId = parseInt(e._typeid, 10);

          if (sportId === sport.tennis && eId.score_change_tennis) {
            const periods = yield select(getMatchPeriodsSets, matchId);
            const periodNum = yield select(getMatchCurPeriod, matchId);
            const gameScore = yield select(getMatchGameScore, matchId);
            const curPeriod = periods['p' + periodNum];

            if (
              curPeriod
              && (curPeriod.home === 0 && curPeriod.away === 0)
              && gameScore
              && (
                (gameScore.home === 0 && gameScore.away === 15)
                || (gameScore.away === 0 && gameScore.home === 15)
              )
            ) {
              // first score of set
              yield call(addEvent, matchId, seenEvents, SET_STARTING_NOW, e);
            } else if (
              curPeriod
              && curPeriod.home === 4 && curPeriod.away === 4
              && gameScore
              && (gameScore.home === 0 && gameScore.away === 0)
            ) {
              // tied 4-4
              yield call(addEvent, matchId, seenEvents, SET_IS_DRAWING, e);
            }
          } else if (
            (sportId === sport.soccer || sportId === sport.ice_hockey)
            && typeId === eId.goal
          ) {
            // goal soccer or hockey
            yield call(addEvent, matchId, seenEvents, GOAL, e);
          } else if ((typeId >= eId.score_after_period_1 && typeId <= eId.score_after_period_5)) {
            // end of period
            yield call(addEvent, matchId, seenEvents, PERIOD_END, e);
          } else if ((typeId === eId.timeout)) {
            // timeout
            yield call(addEvent, matchId, seenEvents, TIMEOUT, e);
          }
        }
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  } finally {
    yield put(clearBettingSuggestionEvents());
  }
}


function* waitOver() {
  yield delay(BETTING_SUGGESTION_INITIAL_WAIT);
  yield put(initialWaitOverBS());
}


function* watchEvents(matchId) {
  const seenEvents = new Set();

  yield fork(waitOver); // don't show betting suggestion right after changing match
  yield call(waitForMatchData, matchId);
  const sportId = yield select(getMatchSportId, matchId);


  yield takeLatest([UPDATE_MATCH_DELTA], gotEvent, matchId, sportId, seenEvents);
  // debounce, cancels previous handleEvent and wait new X seconds
  yield takeLatest([BETTING_SUGGESTION_EVENT], handleEvent);
}

export default watchEvents;
