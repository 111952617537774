/* eslint-disable operator-linebreak */
import { combineReducers } from 'redux';
import bcmsConfig, * as fromBcmsConfig from './bcmsConfig';
import crawler, * as fromCrawler from './crawler';
import srLive, * as srliveState from './srLive';
import highlights, * as highlightsState from './highlights';
import stages, * as stagesState from './stages';
import fishnetFeeds, * as fromFishnetFeeds from './fishnetFeeds';
import liveplayer, * as liveplayerState from './liveplayer';
import stream, * as streamState from './stream';
import marketPackages, * as marketPackagesState from './marketPackages';
import authorization, * as authorizationState from './authorization';
import logger, * as loggerState from './logger';
import sir, * as sirState from './sir';
import components, * as compsState from './components';


const appReducer = combineReducers({
  bcmsConfig,
  components,
  crawler,
  srLive,
  highlights,
  stages,
  fishnetFeeds,
  liveplayer,
  stream,
  marketPackages,
  authorization,
  logger,
  sir,
});

// reseting some of the states could be done here state={...state, fishnetFeeds: undefined}
const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;

// Selectors:
export const getBcmsFeedStage =
  state => fromBcmsConfig.getStage(state.bcmsConfig);
export const getQueryStreamUrl =
  state => fromBcmsConfig.getQueryStreamUrl(state.bcmsConfig);
export const getQueryFeed =
  state => fromBcmsConfig.getQueryFeed(state.bcmsConfig);
export const getUseRefresh =
  state => fromBcmsConfig.getUseRefresh(state.bcmsConfig);

// config
export const getBaseUrl =
  state => fromBcmsConfig.getBaseUrl(state.bcmsConfig);
export const getStreamingFeedsURL =
  state => fromBcmsConfig.getStreamingFeedsURL(state.bcmsConfig);
export const getStreamUrl =
  state => fromBcmsConfig.getStreamUrl(state.bcmsConfig);
export const getLcrStreamUrl =
  state => fromBcmsConfig.getLcrStreamUrl(state.bcmsConfig);
export const getWorldLotteryUrl =
  state => fromBcmsConfig.getWorldLotteryUrl(state.bcmsConfig);
export const getLcrWorldLotteryUrl =
  state => fromBcmsConfig.getLcrWorldLotteryUrl(state.bcmsConfig);

export const getAuthPollingInterval =
  state => fromBcmsConfig.getAuthPollingInterval(state.bcmsConfig);
export const getAuthUrl =
  state => fromBcmsConfig.getAuthUrl(state.bcmsConfig);
export const getUserId =
  state => fromBcmsConfig.getUserId(state.bcmsConfig);
export const getChannelId =
  state => fromBcmsConfig.getChannelId(state.bcmsConfig);
export const getStreamId =
  state => fromBcmsConfig.getStreamId(state.bcmsConfig);
export const getMatchIdVisibility =
  state => fromBcmsConfig.getMatchIdVisibility(state.bcmsConfig);
export const getLogoUrl =
  state => fromBcmsConfig.getLogoUrl(state.bcmsConfig);
export const getOddsBreak =
  state => fromBcmsConfig.getOddsBreak(state.bcmsConfig);
export const getShowLmt =
  state => fromBcmsConfig.getShowLmt(state.bcmsConfig);
export const getColor =
  (state, props) => fromBcmsConfig.getColor(state.bcmsConfig, props);
export const getSeparatorColor =
  state => fromBcmsConfig.getSeparatorColor(state.bcmsConfig);
export const getFontColor =
  state => fromBcmsConfig.getFontColor(state.bcmsConfig);
export const getBgColor =
  state => fromBcmsConfig.getBgColor(state.bcmsConfig);
export const getLogoBgColor =
  state => fromBcmsConfig.getLogoBgColor(state.bcmsConfig);
export const getIsMatchBreak =
  state => fromBcmsConfig.getIsMatchBreak(state.bcmsConfig);
export const getMatchId =
  state => fromBcmsConfig.getMatchId(state.bcmsConfig);
export const getPrimaryMatchId =
  state => fromBcmsConfig.getPrimaryMatchId(state.bcmsConfig);
export const getEncodingPlatform =
  state => fromBcmsConfig.getEncodingPlatform(state.bcmsConfig);
export const getScoreBreak =
  state => fromBcmsConfig.getScoreBreak(state.bcmsConfig);
export const getClockBreak =
  state => fromBcmsConfig.getClockBreak(state.bcmsConfig);
export const getTicker =
  state => fromBcmsConfig.getTicker(state.bcmsConfig);
export const getLastMatch =
  state => fromBcmsConfig.getLastMatch(state.bcmsConfig);
export const getBetshopId =
  state => fromBcmsConfig.getBetshopId(state.bcmsConfig);
export const getChannelTrailer = // dont use this, use cached URL instead
  state => fromBcmsConfig.getChannelTrailer(state.bcmsConfig);
export const getMarketingVideo = // dont use this, use cached URL instead
  state => fromBcmsConfig.getMarketingVideo(state.bcmsConfig);
export const getRefresh =
  state => fromBcmsConfig.getRefresh(state.bcmsConfig);
export const getLayout =
  state => fromBcmsConfig.getLayout(state.bcmsConfig);
export const getQueryStatSlide =
  state => fromBcmsConfig.getQueryStatSlide(state.bcmsConfig);
export const getWorldLotteryStreamId =
  state => fromBcmsConfig.getWorldLotteryStreamId(state.bcmsConfig);
export const getTemporaryStage =
  state => fromBcmsConfig.getTemporaryStage(state.bcmsConfig);
export const getClientAlias =
  state => fromBcmsConfig.getClientAlias(state.bcmsConfig);
export const getClientId =
  state => fromBcmsConfig.getClientId(state.bcmsConfig);
export const getLanguage =
  state => fromBcmsConfig.getLanguage(state.bcmsConfig);
export const getVolumeSettings =
  state => fromBcmsConfig.getVolumeSettings(state.bcmsConfig);
export const getDisableCSSAnimations =
  state => fromBcmsConfig.getDisableCSSAnimations(state.bcmsConfig);
export const getVideoBreak =
  state => fromBcmsConfig.getVideoBreak(state.bcmsConfig);
export const getStatsBreak =
  state => fromBcmsConfig.getStatsBreak(state.bcmsConfig);
export const getLmtColors =
  state => fromBcmsConfig.getLmtColors(state.bcmsConfig);
export const getOddsPanelIframeURL =
  state => fromBcmsConfig.getOddsPanelIframeURL(state.bcmsConfig);
export const getOddsPanelType =
  state => fromBcmsConfig.getOddsPanelType(state.bcmsConfig);
export const getScoreboardRefresh =
  state => fromBcmsConfig.getScoreboardRefresh(state.bcmsConfig);
export const getIsNewFrontEnd =
  state => fromBcmsConfig.getIsNewFrontEnd(state.bcmsConfig);
export const getIsNewDesign =
  state => fromBcmsConfig.getIsNewDesign(state.bcmsConfig);
export const showMatchIdComp =
  (state, props) => fromBcmsConfig.showMatchIdComp(state.bcmsConfig, props);
export const getUseReplayAPI =
  (state, props) => fromBcmsConfig.getUseReplayAPI(state.bcmsConfig, props);
export const getUseProd =
  state => fromBcmsConfig.getUseProd(state.bcmsConfig);
export const getIsLightMode =
  state => fromBcmsConfig.getIsLightMode(state.bcmsConfig);
export const getShowOdds =
  state => fromBcmsConfig.getShowOdds(state.bcmsConfig);
export const getSwapOverUnder =
  state => fromBcmsConfig.getSwapOverUnder(state.bcmsConfig);
export const getUseNewOddspanel =
  state => fromBcmsConfig.getUseNewOddspanel(state.bcmsConfig);
export const getRotateOddspanel =
  state => fromBcmsConfig.getRotateOddspanel(state.bcmsConfig);
export const getUseAutoStage =
  state => fromBcmsConfig.getUseAutoStage(state.bcmsConfig);
export const getInitialStageEnded =
  state => fromBcmsConfig.getInitialStageEnded(state.bcmsConfig);
export const getUseSRDev =
  state => fromBcmsConfig.getUseSRDev(state.bcmsConfig);
export const getShowPlayerUI =
  state => fromBcmsConfig.getShowPlayerUI(state.bcmsConfig);
export const getAutoSport =
  state => fromBcmsConfig.getAutoSport(state.bcmsConfig);
export const getAutoSportMatches =
  state => fromBcmsConfig.getAutoSportMatches(state.bcmsConfig);
export const getReadyMatchId =
  state => fromBcmsConfig.getReadyMatchId(state.bcmsConfig);
export const getUseStopTime =
  state => fromBcmsConfig.getUseStopTime(state.bcmsConfig);
// Odds format
export const getOddsFormat =
  (state, srliveAlias) => fromBcmsConfig.getOddsFormat(state.bcmsConfig, srliveAlias);

export const getIsUsOddsFormatEnabled =
  state => fromBcmsConfig.getIsUsOddsFormatEnabled(state.bcmsConfig);


// Crawler selectors
export const getMessage =
  state => fromCrawler.getMessage(state.crawler);
export const getFirstMessage =
  state => fromCrawler.getFirstMessage(state.crawler);
export const getMessages =
  state => fromCrawler.getMessages(state.crawler);
export const getMessageTemplates =
  state => fromCrawler.getMessageTemplates(state.crawler);
export const getVisibility =
  state => fromCrawler.getVisibility(state.crawler);
export const getCrawlerType =
  state => fromCrawler.getCrawlerType(state.crawler);
export const getCancelled =
  state => fromCrawler.getCancelled(state.crawler);
export const getCrawlerEvent =
  state => fromCrawler.getCrawlerEvent(state.crawler);
export const getMarketingInterval =
  state => fromCrawler.getMarketingInterval(state.crawler);
export const getLiveInterval =
  state => fromCrawler.getLiveInterval(state.crawler);

// Stream selectors
export const getStreamUrls =
  state => streamState.getStreamUrls(state.stream);
export const getStreamRequestError =
  state => streamState.getStreamRequestError(state.stream);
export const getDebugPlayerUrl =
  state => streamState.getDebugPlayerUrl(state.stream);
export const getStreamRequestSettings =
  state => streamState.getStreamRequestSettings(state.stream);

// Player selectors
export const getLiveplayerInstance =
  state => liveplayerState.getLiveplayerInstance(state.liveplayer);
export const getStreamPlayingStatus =
  state => liveplayerState.getStreamPlayingStatus(state.liveplayer);
export const getIsPlayerLoaded =
  state => liveplayerState.getIsPlayerLoaded(state.liveplayer);
export const getIsPlayerLoading =
  state => liveplayerState.getIsPlayerLoading(state.liveplayer);

// SIR selectors
export const getIsSirLoaded =
  state => sirState.getIsSirLoaded(state.sir);
export const getIsSirLoading =
  state => sirState.getIsSirLoading(state.sir);

// Fishnet feeds - Next matches
export const getNextMatches =
    state => fromFishnetFeeds.getNextMatches(state.fishnetFeeds);

/**
 * To check if next matches are initially loaded (first time), to display "No scheduled matches"
 * This was implemented to prevent bug described below:
 * Sometimes, there is no data (due to network delay) and for very short period we could display "No scheduled matches"
 * what is incorrect
 */
export const getIsNextMatchesLoaded =
  state => fromFishnetFeeds.getIsNextMatchesLoaded(state.fishnetFeeds);


// Fishnet feeds - Next matches by ID
export const getNextMatchesById =
  state => fromFishnetFeeds.getNextMatchesById(state.fishnetFeeds);
export const getNextMatchDisplaySolution =
  (state, props) => fromFishnetFeeds.getNextMatchDisplaySolution(state.fishnetFeeds, props);
export const getNextMatchStatus =
  (state, props) => fromFishnetFeeds.getNextMatchStatus(state.fishnetFeeds, props);
export const getNextMatchStatusMessage =
  (state, props) => fromFishnetFeeds.getNextMatchStatusMessage(state.fishnetFeeds, props);
export const getNextMatchTimestamp =
  (state, props) => fromFishnetFeeds.getNextMatchTimestamp(state.fishnetFeeds, props);
export const showNextMatchRealCategory =
  (state, props) => fromFishnetFeeds.showNextMatchRealCategory(state.fishnetFeeds, props);

// Fishnet feeds
export const getMatchStatus =
  (state, props) => fromFishnetFeeds.getMatchStatus(state.fishnetFeeds, state.bcmsConfig, props);
export const getMatchStatusName =
  (state, props) => fromFishnetFeeds.getMatchStatusName(state.fishnetFeeds, props);
export const getMatchSportId =
  (state, props) => fromFishnetFeeds.getMatchSportId(state.fishnetFeeds, props);
export const getMatchIdsWithOdds =
  (state, props) => fromFishnetFeeds.getMatchIdsWithOdds(state.fishnetFeeds, props);
export const getPreMatchOdds =
  (state, props) => fromFishnetFeeds.getPreMatchOdds(state.fishnetFeeds, props);
export const getMatchTeams =
  (state, props) => fromFishnetFeeds.getMatchTeams(state.fishnetFeeds, props);
export const getMatchTeamsNames =
  (state, props) => fromFishnetFeeds.getMatchTeamsNames(state.fishnetFeeds, props);
export const getMatchSportName =
  (state, props) => fromFishnetFeeds.getMatchSportName(state.fishnetFeeds, props);
export const getRealCategoryName =
  (state, props) => fromFishnetFeeds.getRealCategoryName(state.fishnetFeeds, props);
export const getTournamentName =
  (state, props) => fromFishnetFeeds.getTournamentName(state.fishnetFeeds, props);
export const getTournamentFlag =
  (state, props) => fromFishnetFeeds.getTournamentFlag(state.fishnetFeeds, props);
export const getClientMatchId =
  (state, props) => fromFishnetFeeds.getClientMatchId(state.fishnetFeeds, props);
export const getMatchInfo =
  (state, props) => fromFishnetFeeds.getMatchInfo(state.fishnetFeeds, props);
export const getMatchCurPeriod =
  (state, props) => fromFishnetFeeds.getMatchCurPeriod(state.fishnetFeeds, props);
export const getMatchPeriodsBasketballHockey =
  (state, props) => fromFishnetFeeds.getMatchPeriodsBasketballHockey(state.fishnetFeeds, props);
export const getMatchPeriods =
(state, props) => fromFishnetFeeds.getMatchPeriods(state.fishnetFeeds, props);
export const getMatchPeriodsSets =
  (state, props) => fromFishnetFeeds.getMatchPeriodsSets(state.fishnetFeeds, props);
export const getMatchGameScore =
  (state, props) => fromFishnetFeeds.getMatchGameScore(state.fishnetFeeds, props);
export const getMatchBasketballBonus =
  (state, props) => fromFishnetFeeds.getMatchBasketballBonus(state.fishnetFeeds, props);
export const getMatchFoulsCurPer =
  (state, props) => fromFishnetFeeds.getMatchFoulsCurPer(state.fishnetFeeds, props);
export const getMatchDirectFoulsCurPer =
  (state, props) => fromFishnetFeeds.getMatchDirectFoulsCurPer(state.fishnetFeeds, props);
export const getMatchOvertimePeriods =
  (state, props) => fromFishnetFeeds.getMatchOvertimePeriods(state.fishnetFeeds, props);
export const getMatch =
  (state, props) => fromFishnetFeeds.getMatch(state.fishnetFeeds, props);
export const getMatchDetails =
  (state, props) => fromFishnetFeeds.getMatchDetails(state.fishnetFeeds, props);
export const getMatchStadium =
  (state, props) => fromFishnetFeeds.getMatchStadium(state.fishnetFeeds, props);
export const getMatchCities =
  (state, props) => fromFishnetFeeds.getMatchCities(state.fishnetFeeds, props);
export const getMatchTournament =
  (state, props) => fromFishnetFeeds.getMatchTournament(state.fishnetFeeds, props);
export const getMatchSport =
  (state, props) => fromFishnetFeeds.getMatchSport(state.fishnetFeeds, props);
export const getMatchSeason =
  (state, props) => fromFishnetFeeds.getMatchSeason(state.fishnetFeeds, props);
export const getMatchReferee =
  (state, props) => fromFishnetFeeds.getMatchReferee(state.fishnetFeeds, props);
export const getMatchManager =
  (state, props) => fromFishnetFeeds.getMatchManager(state.fishnetFeeds, props);
export const getMatchJerseys =
  (state, props) => fromFishnetFeeds.getMatchJerseys(state.fishnetFeeds, props);
export const getMatchRealCategory =
  (state, props) => fromFishnetFeeds.getMatchRealCategory(state.fishnetFeeds, props);
export const getMatchSeeding =
  (state, props) => fromFishnetFeeds.getMatchSeeding(state.fishnetFeeds, props);
export const getTeamYellows =
  (state, matchId, team) => fromFishnetFeeds.getTeamYellows(state.fishnetFeeds, matchId, team);
export const getTeamReds =
  (state, matchId, team) => fromFishnetFeeds.getTeamReds(state.fishnetFeeds, matchId, team);
export const getTeamSubs =
  (state, matchId, team) => fromFishnetFeeds.getTeamSubs(state.fishnetFeeds, matchId, team);
export const getTournamentId =
  (state, matchId) => fromFishnetFeeds.getTournamentId(state.fishnetFeeds, matchId);
export const getSeasonId =
  (state, matchId) => fromFishnetFeeds.getSeasonId(state.fishnetFeeds, matchId);
export const getMatchTableStr =
  (state, matchId) => fromFishnetFeeds.getMatchTableStr(state.fishnetFeeds, matchId);
export const getMatchTable =
  (state, matchId) => fromFishnetFeeds.getMatchTable(state.fishnetFeeds, matchId);
export const getMatchHomeScored =
  (state, matchId) => fromFishnetFeeds.getMatchHomeScored(state.fishnetFeeds, matchId);
export const getMatchAwayScored =
  (state, matchId) => fromFishnetFeeds.getMatchAwayScored(state.fishnetFeeds, matchId);
export const getMatchHomeConceded =
  (state, matchId) => fromFishnetFeeds.getMatchHomeConceded(state.fishnetFeeds, matchId);
export const getMatchAwayConceded =
  (state, matchId) => fromFishnetFeeds.getMatchAwayConceded(state.fishnetFeeds, matchId);
export const getMatchHomeStatsMatchOU =
  (state, matchId) => fromFishnetFeeds.getMatchHomeStatsMatchOU(state.fishnetFeeds, matchId);
export const getMatchAwayStatsMatchOU =
  (state, matchId) => fromFishnetFeeds.getMatchAwayStatsMatchOU(state.fishnetFeeds, matchId);
export const getMatchHomeScoredPeriod = (state, matchId, min) => (
  fromFishnetFeeds.getMatchHomeScoredPeriod(state.fishnetFeeds, matchId, min)
);
export const getMatchAwayScoredPeriod = (state, matchId, min) => (
  fromFishnetFeeds.getMatchAwayScoredPeriod(state.fishnetFeeds, matchId, min)
);
export const getMatchHomeLeagueRank =
  (state, matchId) => fromFishnetFeeds.getMatchHomeLeagueRank(state.fishnetFeeds, matchId);
export const getMatchAwayLeagueRank =
  (state, matchId) => fromFishnetFeeds.getMatchAwayLeagueRank(state.fishnetFeeds, matchId);
export const getMatchHomeAttStrength =
  (state, matchId) => fromFishnetFeeds.getMatchHomeAttStrength(state.fishnetFeeds, matchId);
export const getMatchAwayAttStrength =
  (state, matchId) => fromFishnetFeeds.getMatchAwayAttStrength(state.fishnetFeeds, matchId);
export const getMatchHomeUid =
  (state, matchId) => fromFishnetFeeds.getMatchHomeUid(state.fishnetFeeds, matchId);
export const getMatchAwayUid =
  (state, matchId) => fromFishnetFeeds.getMatchAwayUid(state.fishnetFeeds, matchId);
export const getMatchHomeLastMResult =
  (state, matchId, nrOfMatches) => fromFishnetFeeds
    .getMatchHomeLastMResult(state.fishnetFeeds, matchId, nrOfMatches);
export const getMatchAwayLastMResult =
  (state, matchId, nrOfMatches) => fromFishnetFeeds
    .getMatchAwayLastMResult(state.fishnetFeeds, matchId, nrOfMatches);
export const getMatchHomeLastM =
    (state, matchId, nrOfMatches) => fromFishnetFeeds.getMatchHomeLastM(
      state.fishnetFeeds, matchId, nrOfMatches,
    );
export const getMatchAwayLastM =
    (state, matchId, nrOfMatches) => fromFishnetFeeds.getMatchAwayLastM(
      state.fishnetFeeds, matchId, nrOfMatches,
    );
export const getMatchHomeForm =
(state, matchId, nrOfMatches) => fromFishnetFeeds
  .getMatchHomeForm(state.fishnetFeeds, matchId, nrOfMatches);
export const getMatchAwayForm =
    (state, matchId, nrOfMatches) => fromFishnetFeeds
      .getMatchAwayForm(state.fishnetFeeds, matchId, nrOfMatches);
export const getMatchPrevMeetings =
    (state, matchId) => fromFishnetFeeds.getMatchPrevMeetings(state.fishnetFeeds, matchId);

export const getMatchFirstHalfScore =
    (state, matchId) => fromFishnetFeeds.getMatchFirstHalfScore(state.fishnetFeeds, matchId);
export const getTeamCorners =
    (state, matchId, team) => fromFishnetFeeds.getTeamCorners(state.fishnetFeeds, matchId, team);
export const getNonMainMatches =
    (state, matchId) => fromFishnetFeeds.getNonMainMatches(state.fishnetFeeds, matchId);
export const getMatches =
    state => fromFishnetFeeds.getMatches(state.fishnetFeeds);
export const getMatchPTime =
  (state, matchId) => fromFishnetFeeds.getMatchPTime(state.fishnetFeeds, matchId, state.bcmsConfig);
export const getMatchPeriodLength =
  (state, matchId) => fromFishnetFeeds.getMatchPeriodLength(state.fishnetFeeds, matchId);
export const getMatchNumPeriods =
  (state, matchId) => fromFishnetFeeds.getMatchNumPeriods(state.fishnetFeeds, matchId);
export const getMatchOvertimeLength =
  (state, matchId) => fromFishnetFeeds.getMatchOvertimeLength(state.fishnetFeeds, matchId);
export const getMatchStartTime =
  (state, matchId) => fromFishnetFeeds.getMatchStartTime(state.fishnetFeeds, matchId);
export const getMatchEvents =
  (state, matchId) => fromFishnetFeeds.getMatchEvents(state.fishnetFeeds, matchId);
export const getMatchEventsLastN =
  (state, matchId, n) => fromFishnetFeeds.getMatchEventsLastN(state.fishnetFeeds, matchId, n);
export const getMatchTimeEvents =
  (state, matchId) => fromFishnetFeeds.getMatchTimeEvents(state.fishnetFeeds, matchId);
export const getMatchSuspended =
  (state, matchId) => fromFishnetFeeds.getMatchSuspended(
    state.fishnetFeeds, matchId, state.bcmsConfig,
  );
export const getMatchTimeInfo =
  (state, matchId) => fromFishnetFeeds.getMatchTimeInfo(state.fishnetFeeds, matchId);
export const getMatchDetailsStat =
  (state, matchId, stat) => fromFishnetFeeds.getMatchDetailsStat(
    state.fishnetFeeds, matchId, stat,
  );
export const getMatchTennisDouble =
  (state, matchId) => fromFishnetFeeds.getMatchTennisDouble(state.fishnetFeeds, matchId);

export const getMatchResult =
  (state, matchId) => fromFishnetFeeds.getMatchResult(state.fishnetFeeds, matchId);
export const getMatchRound =
  (state, matchId) => fromFishnetFeeds.getMatchRound(state.fishnetFeeds, matchId);
export const getMatchRoundName =
  (state, matchId) => fromFishnetFeeds.getMatchRoundName(state.fishnetFeeds, matchId);
export const getMatchGround =
  (state, matchId) => fromFishnetFeeds.getMatchGround(state.fishnetFeeds, matchId);
export const getMatchGroundId =
  (state, matchId) => fromFishnetFeeds.getMatchGroundId(state.fishnetFeeds, matchId);
export const getMatchWinPctGround =
  (state, matchId, ground) => fromFishnetFeeds.getMatchWinPctGround(
    state.fishnetFeeds, matchId, ground,
  );
export const getMatchTeamsRank =
  (state, matchId) => fromFishnetFeeds.getMatchTeamsRank(state.fishnetFeeds, matchId);
//
// Market packages
export const getMarketPackages =
  state => marketPackagesState.getMarketPackages(state.marketPackages);

export const getMarketPackagesLoading =
  state => marketPackagesState.getMarketPackagesLoading(state.marketPackages);

// Authorization
export const getPaymentCheckSuccess =
  state => authorizationState.getPaymentCheckSuccess(state.authorization);
export const getLoggedInStatus =
  state => authorizationState.getLoggedInStatus(state.authorization);

export const getAccountActiveStatus =
  state => authorizationState.getAccountActiveStatus(state.authorization);


export const getIsOperator =
  state => authorizationState.getIsOperator(state.authorization);

export const getBId =
  state => authorizationState.getBId(state.authorization);

// Stages
export const getStageConfig =
  (state, stage) => stagesState.getStageConfig(state.stages, stage);
export const getPreMatchHideOpener =
  state => stagesState.getPreMatchHideOpener(state.stages);
export const getStageDuration =
  (state, stage) => stagesState.getStageDuration(state.stages, stage);
export const getSubstages =
  state => stagesState.getSubstages(state.stages);
export const getChannelTrailerCachedUrl =
  state => stagesState.getChannelTrailerCachedUrl(state.stages);
export const getMarketingVideoCachedUrl =
  state => stagesState.getMarketingVideoCachedUrl(state.stages);
export const getActiveStage =
  state => stagesState.getActiveStage(state.stages);
export const getShownStage =
  state => stagesState.getShownStage(state.stages);
export const getStageRefresh =
  state => stagesState.getStageRefresh(state.stages);
export const showStageSeparator =
  state => stagesState.showStageSeparator(state.stages);
export const getStaticVideoEnded =
  state => stagesState.getStaticVideoEnded(state.stages);
export const getActiveTempStage =
  state => stagesState.getActiveTempStage(state.stages);

export const getTechnicalIssuesStatus =
  state => stagesState.getTechnicalIssuesStatus(state.stages);

// Srlive
export const srliveLoaded =
  state => srliveState.srliveLoaded(state.srLive);

// Logger
export const getActiveMessages =
  state => loggerState.getActiveMessages(state.logger);
export const getLogMessagesTimeout =
    state => loggerState.getLogMessagesTimeout(state.logger);

// React Components
export const getSBCompConfig =
  (state, comp, idx) => compsState.getSBCompConfig(state.components, comp, idx);
export const getSBCompKey =
  (state, comp, idx) => compsState.getSBCompKey(state.components, comp, idx);
export const getSBCompAllSlides =
  (state, comp) => compsState.getSBCompAllSlides(state.components, comp);
export const getSBCompActiveSlides =
  (state, comp) => compsState.getSBCompActiveSlides(state.components, comp);
export const getSBCompSlideIdx =
  (state, comp) => compsState.getSBCompSlideIdx(state.components, comp);


export const getSSCompConfig =
  (state, comp, idx) => compsState.getSSCompConfig(state.components, idx);
export const getSSCompKey =
  (state, comp, idx) => compsState.getSSCompKey(state.components, idx);
export const getSSCompAllSlides =
  state => compsState.getSSCompAllSlides(state.components);
export const getSSCompDynamicSlides =
  state => compsState.getSSCompDynamicSlides(state.components);
/**
 * Pre-match active slides IDs
 * @return [] IDs of slides with flag active = true
 */
export const getSSCompActivePreMatchSlideIds =
  state => compsState.getSSCompActivePreMatchSlideIds(state.components);
export const getSSCompActiveBreakSlideIds =
  state => compsState.getSSCompActiveBreakSlideIds(state.components);
export const getSSCompSlideIdx =
  state => compsState.getSSCompSlideIdx(state.components);

/**
 * Get Sub-stage (slide) for Pre-Match stage
 */
export const getPreMatchSlideIdx =
  state => {
    // override idx from query param
    const queryParamStatSlide = fromBcmsConfig.getQueryStatSlide(state.bcmsConfig);
    const curPreMatchIdx = compsState.getSSCompSlideIdx(state.components);

    return queryParamStatSlide != null
      ? queryParamStatSlide
      : curPreMatchIdx;
  };
export const getBSLatest =
  state => compsState.getBSLatest(state.components);
export const getBSVisibility =
state => compsState.getBSVisibility(state.components);

export const getHOTDMatch =
  state => highlightsState.getHOTDMatch(state.highlights);
export const getHOTDPhrases =
  state => highlightsState.getHOTDPhrases(state.highlights);
export const getHOTDPhraseIdx =
  state => highlightsState.getHOTDPhraseIdx(state.highlights);
