
import {
  put,
  select,
  apply,
  all,
  call,
} from 'redux-saga/effects';
import {
  getClientAlias,
  getLanguage,
  getQueryFeed,
  getMatchHomeUid,
  getMatchAwayUid,
  getMatchTennisDouble,
} from 'reducers';
import { StatsTeamInfo } from 'fishnet-api';
import * as config from 'Config/srlive';
import { updateStatsTeamInfoFailed, updateStatsTeamInfo } from 'actions/fishnet/index';
import { pollTillResponse, takeMatchUpdate } from '../utilLCR';

function* loadStatsTeamInfo(matchId, offset) {
  const clientAlias = yield select(getClientAlias);
  const language = yield select(getLanguage);
  const qFeed = offset > 0 ? config.fnReplayUrl : yield select(getQueryFeed);
  const tId1 = yield select(getMatchHomeUid, matchId);
  const tId2 = yield select(getMatchAwayUid, matchId);
  const isDouble = yield select(getMatchTennisDouble, matchId);

  const request1 = new StatsTeamInfo(
    tId1,
    clientAlias,
    language,
    isDouble,
    qFeed,
  );

  const request2 = new StatsTeamInfo(
    tId2,
    clientAlias,
    language,
    isDouble,
    qFeed,
  );

  const [team1, team2] = yield all([
    apply(request1, request1.get),
    apply(request2, request2.get),
  ]);

  if ((team1 && !team1.error) && (team2 && !team2.error)) {
    const lastX = {
      [matchId]: {
        home: team1,
        away: team2,
      },
    };

    yield put(updateStatsTeamInfo(lastX, matchId));
  } else {
    yield put(updateStatsTeamInfoFailed(null, matchId));
    throw new Error('Update stats_team_lastx failed');
  }
}

// request once
function* pollStatsTeamInfo(matchId, offset) {
  yield call(takeMatchUpdate, matchId); // wait on one succesful match_info / match_timelinedelta
  yield call(pollTillResponse, { tries: 3 }, loadStatsTeamInfo, matchId, offset); // poll this endpoint once
}

export default pollStatsTeamInfo;
