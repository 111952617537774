/*
 * action types
 */

import {
  UPDATE_STREAM_URLS,
  STREAM_URLS_REQUEST_ERROR,
  UPDATE_ACTIVE_VIDEO_HANDLER,
  POLL_STREAM_URLS,
  CANCEL_POLLING_STREAM_URLS,
} from 'constants/actions';

/*
 * action creators
 */
export function updateStreamUrls(data) {
  return { type: UPDATE_STREAM_URLS, payload: data };
}

export function pollStreamUrls() {
  return { type: POLL_STREAM_URLS };
}
export function cancelPollingStreamUrls() {
  return { type: CANCEL_POLLING_STREAM_URLS };
}

export function streamUrlsRequestError(error) {
  return { type: STREAM_URLS_REQUEST_ERROR, payload: error };
}

export function updateActiveVideoHandler(handler) {
  return { type: UPDATE_ACTIVE_VIDEO_HANDLER, payload: handler };
}
