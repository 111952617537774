import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty as _isEmpty } from 'lodash';
import { redirectNewHostToClient } from 'utils/Url';

import styles from 'newDesign/modules/auth/styles/AuthStyles.scss';
import { useDispatch } from 'react-redux';
import { LCR_AUTH_CHECK } from 'constants/actions';
import { HTTP_STATUS_CODE_LOCKED, HTTP_STATUS_CODE_UNAUTHORIZED_LOCATION } from 'constants/bcms';

const LoginForm = () => {
  const signInMessage = 'Sign In';

  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({});
  const [buttonText, setButtonText] = useState(signInMessage);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const usernameInputRef = useRef(null);

  useEffect(() => {
    dispatch({
      type: LCR_AUTH_CHECK,
    });
  }, [dispatch]);

  const validateValues = () => {
    const errorsObject = {};
    if (_isEmpty(username)) {
      errorsObject.usernameError = 'Please fill username field!';
    }

    if (_isEmpty(password)) {
      errorsObject.passwordError = 'Please fill password field!';
    }
    return errorsObject;
  };

  const handleSubmit = event => {
    event.preventDefault();
    const validationErrors = validateValues();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setSubmitting(true);
      setButtonText('Submitting...');
    }
  };

  const handleRememberMeCheckbox = () => {
    setRememberMe(!rememberMe);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      setErrorMessage('');

      // TODO: Integration, post MVP, move to auth service
      fetch('/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username.trim(),
          password,
          remember_me: rememberMe,
        }),
      })
        .then(response => {
          if (!response.ok) {
            if (response.status === HTTP_STATUS_CODE_UNAUTHORIZED_LOCATION) {
              throw new Error('You are not authorized to connect from this location.');
            } else if (response.status === HTTP_STATUS_CODE_LOCKED) {
              throw new Error('Your account is disabled.');
            }

            throw new Error('Authentication failed. Please try again.');
          } else {
            return response.json();
          }
        })
        .then(data => {
          if (data.userId) {
            setButtonText('Redirecting...');
            setTimeout(() => {
              setButtonText(signInMessage);
            }, 10000);
            return redirectNewHostToClient(data.userId);
          }

          return setErrorMessage('Authentication failed. Please try again.');
        })
        .catch(error => {
          setButtonText(signInMessage);
          setErrorMessage(error.message);
        })
        .finally(() => setSubmitting(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, submitting]);

  useEffect(() => {
    usernameInputRef.current.focus();
  }, []);

  return (
    <form className='px-8 pt-6 pb-8 mb-4 w-1/2' onSubmit={handleSubmit}>
      <div className='mb-4'>
        <label className={`${styles.form_label}`} htmlFor='username'>
          Username
          <input
            className={`w-full py-2 px-3 ${styles.login_input}`}
            name='username'
            type='text'
            ref={usernameInputRef}
            value={username}
            onChange={e => setUsername(e.target.value)}
            style={{
              border: errors.usernameError ? '1px solid red' : null,
            }}
          />
        </label>
        {errors.usernameError && (
          <p className={`${styles.error_message}`}>{errors.usernameError}</p>
        )}
      </div>
      <div className='mb-6'>
        <label className={`${styles.form_label}`} htmlFor='password'>
          Password
          <input
            className={`w-full py-2 px-3 ${styles.login_input}`}
            name='password'
            type='password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            style={{
              border: errors.passwordError ? '1px solid red' : null,
            }}
          />
        </label>
        {errors.passwordError && (
          <p className={`${styles.error_message}`}>{errors.passwordError}</p>
        )}
      </div>
      <div className='flex items-center justify-between'>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <label
          className='cursor-pointer block text-gray-700 text-sm font-bold'
          htmlFor='checkbox'
          onClick={handleRememberMeCheckbox}
        >
          <input
            name='remember_me'
            className={'mr-2'}
            type='checkbox'
            checked={rememberMe}
            onChange={handleRememberMeCheckbox}
          />
          Stay signed in?
        </label>
        <Link
          className='inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800'
          to={'/forgot-password'}
        >
          Forgot Password?
        </Link>
      </div>
      <div className='flex items-center justify-between'>
        <button
          className={'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-4 rounded focus:outline-none focus:shadow-outline w-full'}
          type='submit'
          disabled={submitting}
        >
          {buttonText}
        </button>
      </div>
      {errorMessage && (
        <div className={'flex flex-row mt-2'}>
          <h1 style={{ color: 'red' }}>{errorMessage}</h1>
        </div>
      )}
    </form>
  );
};

export default LoginForm;
