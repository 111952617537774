import {
  call,
  all,
  takeLatest,
  select,
  put,
} from 'redux-saga/effects';
import { getUseReplayAPI } from 'reducers';
import {
  CHANGE_MAIN_MATCH, CHANGE_LAST_MATCH, CHANGE_HOTD_MATCH,
} from 'constants/actions';
import { log } from 'actions/logger';
import pollTimelineDelta from './timelinedelta';
import pollTimeline from './timeline';
import pollMatchInfo from './matchinfo';
import pollMatchDetails from './matchdetails';
import pollTournamentLiveTable from './tournamentlivetable';
import pollStatsSeasonOverUnder from './statsSeasonOverUnder';
import pollStatsMatchOverUnder from './statsMatchOverUnder';
import pollStatsSeasonGoals from './statsSeasonGoals';
import pollStatsMatch from './statsMatchSituation';
import pollStatsTeamLastX from './statsTeamLastX';
import pollOdds from './odds';
import getOffset from '../replay';
import { transformJsError } from '../../utils/index';
import pollStatsTeamVersus from './statsTeamVersus';
import pollStatsTeamWiningPct from './statsTeamWinningPercent';
import pollStatsTeamInfo from './statTeamInfo';
import pollFunFact from './funfacts';
import watchEvents from './watchEvents';
import { clearBettingSuggestionEvents } from '../../actions/components';

function* pollFishnet(action) {
  try {
    const { payload: matchId } = action;

    const live = true;
    const qReplay = yield select(getUseReplayAPI);

    let offset = -1;
    if (qReplay && qReplay !== '0') {
      offset = yield call(getOffset, matchId, qReplay);
    }

    if (matchId) {
      yield all([
        call(pollMatchInfo, matchId, offset),
        call(pollOdds, matchId, live, offset),
        call(pollStatsTeamLastX, matchId, offset),
        call(pollMatchDetails, matchId, offset),
        call(pollTimelineDelta, matchId, offset),
        call(pollTournamentLiveTable, matchId, offset),
        call(pollStatsSeasonOverUnder, matchId, offset),
        call(pollStatsSeasonGoals, matchId, offset),
        call(pollStatsMatchOverUnder, matchId, offset),
        call(pollStatsMatch, matchId, offset),
        call(pollTimeline, matchId, offset),
        call(pollStatsTeamVersus, matchId, offset),
        call(pollStatsTeamWiningPct, matchId, offset),
        call(pollStatsTeamInfo, matchId, offset),
        call(watchEvents, matchId),
      ]);
    }
  } catch (error) {
    yield put(log(transformJsError(error)));
  } finally {
    yield put(clearBettingSuggestionEvents());
  }
}

function* lastMatchPoller(action) {
  const { payload: matchId } = action;

  if (matchId) {
    yield call(pollMatchInfo, matchId);
    yield all([
      call(pollOdds, matchId),
      call(pollTimelineDelta, matchId),
    ]);
  }
}

function* HOTDPoller(action) {
  const { payload: matchId } = action;

  if (matchId > 0) {
    yield all([
      call(pollMatchInfo, matchId),
      call(pollOdds, matchId, false),
      call(pollFunFact, matchId, false),
    ]);
  }
}

export default function* fishnetSaga() {
  yield takeLatest([CHANGE_MAIN_MATCH], pollFishnet);
  yield takeLatest([CHANGE_LAST_MATCH], lastMatchPoller);
  yield takeLatest([CHANGE_HOTD_MATCH], HOTDPoller);
}
