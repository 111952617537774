import {
  put,
  select,
  fork,
  call,
} from 'redux-saga/effects';
import {
  updateScorboardTop,
} from 'actions/components';
import {
  getMatchId,
  getMatchStatus,
  getMatchTeams,
  getSBCompConfig,
  getMatchHomeLastMResult,
  getMatchAwayLastMResult,
  getMatchDetailsStat,
} from 'reducers';
import {
  UPDATE_MATCH_INFO,
  UPDATE_MATCH_DETAILS,
  UPDATE_MATCH_DELTA,
  UPDATE_STATS_TEAM_LASTX,
} from 'constants/actions';
import {
  isFirstHalfDone,
} from 'utils/matchstatus';
import {
  SCOREBOARD_TOP,
  SBT_NATIONALITY,
  SBT_LAST_MATCHES,
  SBT_1ST_HALF_SCORE,
  SBT_PUCK_POSSESION,
  SBT_GOALS_IN_POWERPLAY,
  SBT_GOALS_SHORTHANDED,
} from 'constants/components';
import { sagaRunTakeLatest } from '../../utilSagas';

function* onMatchInfoTop() {
  const matchId = yield select(getMatchId);
  const teams = yield select(getMatchTeams, matchId);

  if (teams) {
    yield put(updateScorboardTop({
      slide: SBT_NATIONALITY,
      status: !!teams.home[0].cc && !!teams.away[0].cc,
    }));
  }

  const status = yield select(getMatchStatus, matchId);
  yield put(updateScorboardTop({
    slide: SBT_1ST_HALF_SCORE,
    status: isFirstHalfDone(status),
  }));
}

function* getStat(key) {
  const mId = yield select(getMatchId);
  const config = (yield select(getSBCompConfig, SCOREBOARD_TOP, key));
  const mdKey = config ? config.key : null;


  return yield select(getMatchDetailsStat, mId, mdKey);
}

function* onMatchDetails() {
  yield put(updateScorboardTop({
    slide: SBT_PUCK_POSSESION,
    status: (yield call(getStat, SBT_PUCK_POSSESION)) != null,
  }));

  yield put(updateScorboardTop({
    slide: SBT_GOALS_IN_POWERPLAY,
    status: (yield call(getStat, SBT_GOALS_IN_POWERPLAY)) != null,
  }));

  yield put(updateScorboardTop({
    slide: SBT_GOALS_SHORTHANDED,
    status: (yield call(getStat, SBT_GOALS_SHORTHANDED)) != null,
  }));
}

function* onTeamLastX() {
  const matchId = yield select(getMatchId);
  const home = yield select(getMatchHomeLastMResult, matchId);
  const away = yield select(getMatchAwayLastMResult, matchId);

  yield put(updateScorboardTop({
    slide: SBT_LAST_MATCHES,
    status: !!((home && home.length) && (away && away.length)),
  }));
}

const handballTop = [
  fork(sagaRunTakeLatest([UPDATE_STATS_TEAM_LASTX], onTeamLastX)),
  fork(sagaRunTakeLatest([UPDATE_MATCH_INFO, UPDATE_MATCH_DELTA], onMatchInfoTop)),
  fork(sagaRunTakeLatest([UPDATE_MATCH_DETAILS], onMatchDetails)),
];

export default handballTop;
