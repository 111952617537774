import {
  put,
  select,
  take,
  race,
  fork,
  delay,
  takeEvery,
} from 'redux-saga/effects';
import {
  HIGHLIGHTS_LOAD_SUCCESS,
  SET_STATIC_VIDEO_ENDED,
} from 'constants/actions';
import { STAGE_ANNOUNCEMENT_LOOP } from 'constants/bcms';
import { switchAnnouncementLoopSlide } from 'actions/stages';
import runAnnouncementStage from 'sagas/stages/announcement';
import runHighlights from 'sagas/stages/highlights';
import { getStageConfig } from 'reducers';

function* runAnnouncementLoopStage() {
  let stageConf = yield select(getStageConfig, STAGE_ANNOUNCEMENT_LOOP);

  yield fork(runHighlights);
  yield fork(runAnnouncementStage);

  let showHighlights = false;
  yield takeEvery(HIGHLIGHTS_LOAD_SUCCESS, action => {
    const { payload } = action;
    if (!payload || !payload.length) {
      showHighlights = false;
    } else {
      showHighlights = true;
    }
  });

  for (;;) {
    // Race between custom slide interval to be triggered or default interval delay.
    // This logic helps showing trailer video slide (announcement loop stage)
    // for as as much time as its length is.
    yield race({
      customInterval: take(SET_STATIC_VIDEO_ENDED),
      defaultInterval: delay(stageConf.defaultInterval),
    });
    stageConf = yield select(getStageConfig, STAGE_ANNOUNCEMENT_LOOP);

    // Calculate next slide idx
    let currentSlideIdx = (stageConf.currentSlideIdx + 1) % stageConf.slides.length;

    // skip highlights
    if (!showHighlights && currentSlideIdx === stageConf.slides.length - 1) {
      currentSlideIdx = (currentSlideIdx + 1) % stageConf.slides.length;
    }

    yield put(switchAnnouncementLoopSlide(currentSlideIdx));
  }
}

export default runAnnouncementLoopStage;
