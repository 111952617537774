import {
  fork,
  put,
  select,
  call,
  take,
  apply,
  delay,
} from 'redux-saga/effects';
import {
  enqueueMessage,
  dequeueMessage,
  setCurrentMessage,
  visibilityOff,
  visibilityOn,
} from 'actions/crawler';
import {
  getMessage,
  getMessageTemplates,
  getFirstMessage,
  getMarketingInterval,
  getPrimaryMatchId,
  getVisibility,
  getMatchStatus,
  getLiveInterval,
  getIsMatchBreak,
  getCrawlerType,
  getCrawlerEvent,
  getClientAlias,
  getLanguage,
  getMatchId,
  getQueryFeed,
} from 'reducers';
import {
  BCMS_BETSHOP_CONFIG_LOAD_SUCCESS,
} from 'constants/actions';
import {
  LIVE_CRAWLER_TYPE,
  MARKETING_CRAWLER_TYPE,
  IMPORTANT_CRAWLER_TYPE,
  BREAK_CRAWLER_TYPE,
  DISABLED,
  LIVE_AND_MESSAGES,
} from 'constants/bcms';
import TranslatorCpm from 'utils/Translator';
import { repeatText } from 'utils';
import { MatchInfoRequest } from 'fishnet-api';
import { sagaRunning } from './utilSagas';

function* cunstructText(template, type) {
  if (!template) {
    return '';
  }

  if (type === LIVE_CRAWLER_TYPE) {
    return repeatText(TranslatorCpm.t(template), 20);
  } else if (type === MARKETING_CRAWLER_TYPE) {
    // do nothing
  } else if (type === IMPORTANT_CRAWLER_TYPE) {
    const message = TranslatorCpm.t(template);
    const event = yield select(getCrawlerEvent);
    return message.replace('[E]', event);
  } else if (type === BREAK_CRAWLER_TYPE) {
    const matchId = yield select(getPrimaryMatchId);
    // Improvement: consider to optimize three step calls to single load match info call
    const clientAlias = yield select(getClientAlias);
    const language = yield select(getLanguage);
    const qFeed = yield select(getQueryFeed);

    const request = new MatchInfoRequest(matchId, clientAlias, language, qFeed);
    const info = yield apply(request, request.get);
    if (!info) {
      return TranslatorCpm.t(template);
    }

    const matchData = info.entities.match[matchId];
    // Improvement: maybe that match is already in state if so use state
    const home = matchData.teams.home[0].name;
    // Improvement: maybe that match is already in state if so use state
    const away = matchData.teams.away[0].name;
    // Improvement: maybe that match is already in state if so use state
    const sport = matchData.sportName;
    const event = `${home} : ${away} (${sport})`;

    const message = TranslatorCpm.t(template);
    return message.replace('[E]', event);
  }
  return TranslatorCpm.t(template);
}

function* createMessage(type) {
  const msgTemplates = yield select(getMessageTemplates);
  const msg = msgTemplates[type];
  msg.text = yield call(cunstructText, msg.template, type);
  return msg;
}

function* addMessage(type, msg = null) {
  let priority;
  switch (type) {
    case IMPORTANT_CRAWLER_TYPE:
      priority = 1;
      break;
    case BREAK_CRAWLER_TYPE:
      priority = 2;
      break;
    case LIVE_CRAWLER_TYPE:
      priority = 4;
      break;
    case MARKETING_CRAWLER_TYPE:
      priority = 4;
      break;
    default:
      priority = 4;
      break;
  }

  const message = msg || (yield call(createMessage, type));

  yield put(
    enqueueMessage({
      priority,
      element: message,
    }),
  );
}

function* isNewImporantMsg() {
  const imp = yield call(createMessage, IMPORTANT_CRAWLER_TYPE);
  const msgTotalTime = ((imp.duration + imp.interval) * imp.iterations);
  const timeNow = Date.now();

  // expired
  if (imp.timestamp + msgTotalTime < timeNow) {
    return false;
  }
  const currentMessage = yield select(getMessage);

  // same message
  if (
    currentMessage
    && currentMessage.template === imp.template
    && currentMessage.timestamp === imp.timestamp
  ) {
    return false;
  }

  // wrong match, dont let it create new msg
  if (imp.matchId !== (yield select(getMatchId))) {
    return false;
  }

  if (imp.cancelled) {
    return false;
  }

  return true;
}

function* isImporantMsgCancelled() {
  const imp = yield call(createMessage, IMPORTANT_CRAWLER_TYPE);
  const currentMessage = yield select(getMessage);

  // wrong match, cancel current msg
  if (imp.matchId !== (yield select(getMatchId))) {
    return currentMessage && currentMessage.type === IMPORTANT_CRAWLER_TYPE;
  }

  return currentMessage && currentMessage.type === IMPORTANT_CRAWLER_TYPE && imp.cancelled;
}


function* selectNextCrawler() {
  const queueEl = yield select(getFirstMessage);
  if (!queueEl) {
    return null;
  }
  yield put(setCurrentMessage(queueEl.element));
  yield put(dequeueMessage());

  return queueEl.element;
}

function* showCrawlerMessage() {
  try {
    const message = yield call(selectNextCrawler);
    if (!message) {
      return;
    }

    let showCounter = message.iterations;
    while (showCounter > 0) {
      yield put(visibilityOn());
      yield delay(message.duration);
      yield put(visibilityOff());
      yield delay(message.interval);
      showCounter -= 1;
    }
  } finally {
    yield put(setCurrentMessage(null));
    yield put(visibilityOff());
  }
}

function* waitForCancellation() {
  for (;;) {
    const isVisible = yield select(getVisibility);
    if (isVisible) {
      yield delay(100);
    } else {
      return true;
    }
  }
}

function* cancelTask(task) {
  if (task && task.isRunning()) {
    task.cancel();
    yield call(waitForCancellation); // blocks
  }
}


function* checkForNextCrawler() {
  let showCrawlerTask;
  let wasBreak = false;

  for (let i = 0; i < 1000; i++) {
    yield take(BCMS_BETSHOP_CONFIG_LOAD_SUCCESS);
    const isBreak = yield select(getIsMatchBreak);

    if ((yield call(isNewImporantMsg))) {
      // cancel current msg and add new imporant msg
      yield call(cancelTask, showCrawlerTask);
      yield call(addMessage, IMPORTANT_CRAWLER_TYPE);
      showCrawlerTask = yield fork(showCrawlerMessage);
    } else if ((yield call(isImporantMsgCancelled)) && sagaRunning(showCrawlerTask)) {
      // cancel current important msg
      yield call(cancelTask, showCrawlerTask);
    } else if (wasBreak && !isBreak) {
      // break crawler has been shown and now disabled
      yield call(cancelTask, showCrawlerTask);
      wasBreak = false;
    } else if (isBreak && !wasBreak) {
      // match in break, show break crawler
      yield call(addMessage, BREAK_CRAWLER_TYPE);
      showCrawlerTask = yield fork(showCrawlerMessage);
      wasBreak = true;
    } else if (sagaRunning(showCrawlerTask)) {
      // a crawler running, do nothing
    } else if (!(yield select(getFirstMessage))) {
      // queue empty, do nothing
    } else {
      // show live or marketing msg
      showCrawlerTask = yield fork(showCrawlerMessage);
    }
  }
}

function* getInterval(type) {
  if (type === LIVE_CRAWLER_TYPE) {
    return yield select(getLiveInterval);
  }
  return yield select(getMarketingInterval);
}

function* addMsgOnTimer(type) {
  let timer = yield call(getInterval, type);
  let message = yield call(createMessage, type);
  let msgTotalTime = ((message.duration + message.interval) * message.iterations);
  let timeSinceLastIter = 0;

  let lastTime = Date.now();

  // add first time
  let currTimer = (yield call(getInterval)) + msgTotalTime;
  for (;;) {
    yield take(BCMS_BETSHOP_CONFIG_LOAD_SUCCESS);

    message = yield call(createMessage, type);
    msgTotalTime = ((message.duration + message.interval) * message.iterations);
    timer = (yield call(getInterval)) + msgTotalTime; // spawn it x time after it is complete
    if (timer !== msgTotalTime) { // don't add msg if timer = 0
      const timeNow = Date.now();

      timeSinceLastIter = timeNow - lastTime;
      currTimer += timeSinceLastIter; // ca. 5 secs
      lastTime = timeNow;

      if (timer <= currTimer) {
        const matchStatus = (yield select(getMatchStatus, (yield select(getMatchId))));

        const isNotLive = (
          matchStatus <= 0
        || matchStatus >= 70
        );

        const onlyLive = !(
          type === LIVE_CRAWLER_TYPE
        && isNotLive
        );
        // message has duration and only show live crawler if match is live
        if (message.duration > 0 && onlyLive) {
          yield call(addMessage, type, message);
        }
        currTimer = 0;
      }
    }
  }
}


function* runCrawlerSaga() {
  const crawlerType = yield select(getCrawlerType);
  if (crawlerType === DISABLED) {
    return;
  } else if (crawlerType === LIVE_AND_MESSAGES) {
    yield fork(addMsgOnTimer, LIVE_CRAWLER_TYPE);
  }

  yield fork(addMsgOnTimer, MARKETING_CRAWLER_TYPE);

  yield fork(checkForNextCrawler);
}

export default runCrawlerSaga;
