import {
  call,
  put,
  select,
  apply,
  fork,
  take,
  delay,
  all,
} from 'redux-saga/effects';
import {
  getUserId,
  getNextMatchesById,
  getStreamingFeedsURL,
  getAutoSport,
  getAutoSportMatches,
  getChannelId,
  getIsOperator,
} from 'reducers';
import { NextMatchesRequest } from 'bcms-api';
import updateNextMatches from 'actions/nextMatches';
import { NEXT_MATCHES_LOAD_SUCCESS, UPDATE_MATCH_INFO, SET_AS_MATCHES } from 'constants/actions';
import { MATCHES_SHOWN, NEXT_MATCHES_POLL_INTERVAL } from 'constants/fishnet';
import { STAGE_ANNOUNCEMENT } from 'constants/bcms';
import { setStageReady } from 'actions/stages';
import pollMatchInfo from 'sagas/fishnet/matchinfo';
import pollOdds from 'sagas/fishnet/odds';
import { CHANNELS_ESOCCER_ONLY } from 'constants/app';
import { sagaCancel } from '../utilSagas';


function* loadNextMatches(userId, channelId, noMatches) {
  let matches = [];

  if ((yield select(getAutoSport)) || CHANNELS_ESOCCER_ONLY.includes(channelId)) {
    matches = yield select(getAutoSportMatches);

    if (!(matches && matches.length)) {
      yield take(SET_AS_MATCHES);
      matches = yield select(getAutoSportMatches);
    }
    matches = matches.sort((a, b) => ((a.timestamp > b.timestamp) ? 1 : -1))
      .filter(e => e.statusId === 0)
      .splice(0, noMatches);
  } else {
    const baseUrl = yield select(getStreamingFeedsURL);
    const isOperator = yield select(getIsOperator);

    const request = new NextMatchesRequest(baseUrl, userId, channelId, isOperator);
    const nextMatches = yield apply(request, request.get);
    matches = nextMatches
      .filter(match => match.alternativeEvent === '0')
      .splice(0, noMatches);
  }

  yield put(updateNextMatches(matches));
}

function* pollNextMatches(userId, channelId, noMatches) {
  for (;;) {
    try {
      yield call(loadNextMatches, userId, channelId, noMatches);
      yield delay(NEXT_MATCHES_POLL_INTERVAL);
    } catch (error) {
      yield delay(NEXT_MATCHES_POLL_INTERVAL);
    }
  }
}

function* monitorMatch(matchId) {
  const live = false;
  try {
    yield all([
      call(pollMatchInfo, matchId),
      call(pollOdds, matchId, live),
    ]);
  } catch (error) {
    // Keep other matches running
  }
}


function includesEvery(arr, arr2) {
  return arr.every(i => arr2.includes(i));
}

function* stageReadyAnn(matches) {
  const cpy = [...matches];
  const arr = [];

  for (;;) {
    const {
      payload: { result },
    } = yield take(UPDATE_MATCH_INFO);

    arr.push(result);

    if (includesEvery(cpy, arr)) {
      yield put(setStageReady(STAGE_ANNOUNCEMENT));
      return;
    }
  }
}

function* watchNextMatches() {
  const matchMonitors = {};
  let firstLoad = true;
  for (;;) {
    yield take(NEXT_MATCHES_LOAD_SUCCESS);
    const nextMatches = yield select(getNextMatchesById);
    const newMatches = nextMatches.filter(id => !matchMonitors[id]);
    const removedMatches = Object.keys(matchMonitors).filter(
      id => nextMatches.indexOf(id) === -1,
    );
    for (let index = 0; index < removedMatches.length; index += 1) {
      const id = removedMatches[index];
      sagaCancel(matchMonitors[id]);
      delete matchMonitors[id];
    }

    if (firstLoad) {
      yield fork(stageReadyAnn, newMatches);
      firstLoad = false;
    }

    for (let index = 0; index < newMatches.length; index += 1) {
      matchMonitors[index] = yield fork(monitorMatch, newMatches[index]);
    }
  }
}

export default function* runAnnouncementStage() {
  const userId = yield select(getUserId);
  const channelId = yield select(getChannelId);

  yield fork(watchNextMatches);
  yield call(pollNextMatches, userId, channelId, MATCHES_SHOWN);
}
