/*
 * action types
 */

import {
  NEXT_MATCHES_LOAD_SUCCESS,
} from 'constants/actions';

/*
 * action creators
 */

export default function updateNextMatches(response) {
  return { type: NEXT_MATCHES_LOAD_SUCCESS, payload: response };
}
