import {
  put,
  select,
  apply,
  call,
} from 'redux-saga/effects';
import {
  getClientAlias,
  getLanguage,
  getQueryFeed,
  getSeasonId,
  getMatchTeams,
} from 'reducers';
import { StatsSeasonOverUnderRequest } from 'fishnet-api';
import * as config from 'Config/srlive';
import { updateStatsSeasonOverUnderFailed, updateStatsSeasonOverUnder } from 'actions/fishnet/index';
import { pollTillResponse, takeMatchUpdate } from '../utilLCR';

function* loadOverUnder(matchId, offset) {
  const clientAlias = yield select(getClientAlias);
  const language = yield select(getLanguage);
  const qFeed = offset > 0 ? config.fnReplayUrl : yield select(getQueryFeed);
  const sId = yield select(getSeasonId, matchId);

  if (!sId) {
    return;
  }

  const request = new StatsSeasonOverUnderRequest(
    sId,
    clientAlias,
    language,
    qFeed,
  );
  const response = yield apply(request, request.get);

  const teams = yield select(getMatchTeams, matchId);

  if (response && !response.error) {
    const homeUid = teams.home[0].uniqueId;
    const awayUid = teams.away[0].uniqueId;

    const overUnder = {
      [matchId]: {
        home: response[homeUid],
        away: response[awayUid],
      },
    };
    yield put(updateStatsSeasonOverUnder(overUnder, matchId));
  } else if (response && response.error) {
    if (response.message === 'Friendly club tournaments are not supported.') {
      return;
    }
    yield put(updateStatsSeasonOverUnderFailed(response, matchId));
    throw response;
  } else {
    throw new Error('Update stats_season_overunder failed');
  }
}

// request once
function* pollStatsSeasonOverUnder(matchId, offset) {
  yield call(takeMatchUpdate, matchId); // wait on one succesful match_info / match_timelinedelta
  yield call(pollTillResponse, {}, loadOverUnder, matchId, offset); // poll this endpoint once
}

export default pollStatsSeasonOverUnder;
