import {
  put,
  select,
  fork,
  call,
} from 'redux-saga/effects';
import {
  updateScorboardBottom,
  updateScorboardTop,
} from 'actions/components';
import {
  getMatchId,
  getMatchStatus,
  getMatchTable,
  getMatchTeams,
  getMatchTournament,
  getSBCompConfig,
  getMatchHomeLastMResult,
  getMatchAwayLastMResult,
  getMatchDetailsStat,
} from 'reducers';
import {
  UPDATE_MATCH_INFO,
  UPDATE_TOURNAMENT_LIVETABLE,
  UPDATE_MATCH_DETAILS,
  UPDATE_MATCH_DELTA,
  UPDATE_STATS_TEAM_LASTX,
} from 'constants/actions';
import {
  isFirstHalfDone,
} from 'utils/matchstatus';
import {
  SBB_LEAGUE_STANDINGS,
  SBB_TOURNAMENT_INFO,
  SBT_LEAGUE_RANK,
  SCOREBOARD_TOP,
  SBT_NATIONALITY,
  SBT_LAST_MATCHES,
  SBT_1ST_HALF_SCORE,
  SBT_BIGGEST_LEAD,
  SBT_SERVICE_POINTS_WON,
  SBT_POINTS_WON,
  SBT_MAX_POINTS_IN_A_ROW,
} from 'constants/components';
import { sagaRunTakeLatest } from '../../utilSagas';

// SCOREBOARD Bottom

export function* onMatchInfo() {
  const tour = yield select(getMatchTournament, yield select(getMatchId));

  yield put(updateScorboardBottom({
    slide: SBB_TOURNAMENT_INFO,
    status: !!tour,
  }));
}

export function* onTable() {
  const matchId = yield select(getMatchId);
  const table = yield select(getMatchTable, matchId);
  const teams = yield select(getMatchTeams, matchId);

  yield put(updateScorboardBottom({
    slide: SBB_LEAGUE_STANDINGS,
    status: !!(table && table.length && teams),
  }));
}

// SCOREBOARD TOP

function* onMatchInfoTop() {
  const matchId = yield select(getMatchId);
  const teams = yield select(getMatchTeams, matchId);

  if (teams) {
    yield put(updateScorboardTop({
      slide: SBT_NATIONALITY,
      status: !!teams.home[0].cc && !!teams.away[0].cc,
    }));
  }

  const status = yield select(getMatchStatus, matchId);
  yield put(updateScorboardTop({
    slide: SBT_1ST_HALF_SCORE,
    status: isFirstHalfDone(status),
  }));
}

function* getStat(key) {
  const mId = yield select(getMatchId);
  const config = (yield select(getSBCompConfig, SCOREBOARD_TOP, key));
  const mdKey = config ? config.key : null;

  return yield select(getMatchDetailsStat, mId, mdKey);
}

function* onMatchDetails() {
  yield put(updateScorboardTop({
    slide: SBT_SERVICE_POINTS_WON,
    status: (yield call(getStat, SBT_SERVICE_POINTS_WON)) != null,
  }));

  yield put(updateScorboardTop({
    slide: SBT_BIGGEST_LEAD,
    status: (yield call(getStat, SBT_BIGGEST_LEAD)) != null,
  }));

  yield put(updateScorboardTop({
    slide: SBT_POINTS_WON,
    status: (yield call(getStat, SBT_POINTS_WON)) != null,
  }));

  yield put(updateScorboardTop({
    slide: SBT_MAX_POINTS_IN_A_ROW,
    status: (yield call(getStat, SBT_MAX_POINTS_IN_A_ROW)) != null,
  }));
}

function* onTableTop() {
  const matchId = yield select(getMatchId);
  const table = yield select(getMatchTable, matchId);
  const teams = yield select(getMatchTeams, matchId);

  yield put(updateScorboardTop({
    slide: SBT_LEAGUE_RANK,
    status: !!(table && table.length && teams),
  }));
}

function* onTeamLastX() {
  const matchId = yield select(getMatchId);
  const home = yield select(getMatchHomeLastMResult, matchId);
  const away = yield select(getMatchAwayLastMResult, matchId);

  yield put(updateScorboardTop({
    slide: SBT_LAST_MATCHES,
    status: !!((home && home.length) && (away && away.length)),
  }));
}

export const ttTop = [
  fork(sagaRunTakeLatest([UPDATE_STATS_TEAM_LASTX], onTeamLastX)),
  fork(sagaRunTakeLatest([UPDATE_MATCH_INFO, UPDATE_MATCH_DELTA], onMatchInfoTop)),
  fork(sagaRunTakeLatest([UPDATE_MATCH_DETAILS], onMatchDetails)),
  fork(sagaRunTakeLatest([UPDATE_TOURNAMENT_LIVETABLE], onTableTop)),
];

export const ttBottom = [
  fork(sagaRunTakeLatest([UPDATE_MATCH_INFO, UPDATE_MATCH_DELTA], onMatchInfo)),
  fork(sagaRunTakeLatest([UPDATE_TOURNAMENT_LIVETABLE], onTable)),
];
