import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty as _isEmpty } from 'lodash';
import PasswordFooter from 'newDesign/modules/auth/footer/passwordFooter/PasswordFooter';
import { LCR_AUTH_CHECK } from 'constants/actions';

import styles from 'newDesign/modules/auth/styles/AuthStyles.scss';
import { isOldHostName, redirectToForgotPassword } from 'utils/Url';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formSubmittedSuccessfully, SetFormSubmittedSuccessfully] = useState(false);

  useEffect(() => {
    dispatch({
      type: LCR_AUTH_CHECK,
    });
  }, [dispatch]);

  const validateValue = usernameValue => {
    const errorObject = {};

    if (_isEmpty(usernameValue)) {
      errorObject.emptyUsername = 'Please fill required username field';
    }

    return errorObject;
  };

  const handleChange = event => {
    setUsername(event.target.value);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      setErrorMessage('');

      // TODO: Integration, post MVP, move to auth service
      fetch('/auth/password-reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
        }),
      })
        .then(() => {
          setUsername('');
          setSuccessMessage('Check your inbox, we have sent you instructions on how to reset your password. If you don\'t receive this email, please contact our support team.');
          SetFormSubmittedSuccessfully(true);
        })
        .catch(() => setErrorMessage('An error occurred. Please try again.'))
        .finally(() => setSubmitting(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleSubmit = event => {
    event.preventDefault();
    const validationErrors = validateValue(username);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setSubmitting(true);
    }
  };

  if (isOldHostName()) {
    redirectToForgotPassword();

    return null;
  }

  return (
    <Fragment>
      <div className={'flex min-h-screen w-full'}>
        <div className={`flex w-full flex-col ${styles.password_wrapper}`}>
          <div className={'flex h-full flex-col box-border px-4'}>
            <div className={'mt-20'} />
            <div className={'flex mb-8'}>
              <h1 className={`font-second relative w-full ${styles.forgot_password_header}`}>Forgotten your password?
                <div
                  className={`w-full ${styles.password_line}`}
                />
              </h1>
            </div>

            {!formSubmittedSuccessfully && (
              <Fragment>
                <div className={'flex flex-row'}>
                  <p>Enter your username and we will send you instructions on how to reset your password.</p>
                </div>
                <div className={'flex flex-row justify-center items-center w-1/4 mt-4'}>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <label htmlFor='username'>Username<span style={{ color: 'red' }}>*</span>
                        <input
                          type='text'
                          name='username'
                          onChange={handleChange}
                          value={username}
                          className={`ml-4 py-2 px-3 ${styles.login_input}`}
                          style={{
                            border: errors.emptyUsername ? '1px solid red' : null,
                          }}
                        />
                      </label>
                      {errors.emptyUsername && (
                      <p className={`${styles.error_message} flex justify-center`}>{errors.emptyUsername}</p>
                      )}
                    </div>
                    <div className={'flex flex-row justify-center'}>
                      <button type='submit' className={`${styles.submit_button}`} disabled={submitting}>
                        {submitting ? 'Submitting...' : 'Submit'}
                      </button>
                    </div>
                  </form>
                </div>
              </Fragment>
            )}

            {successMessage && (
            <div className={'flex flex-row'}>
              <h1>{successMessage}</h1>
            </div>
            )}

            {errorMessage && (
            <div className={'flex flex-row'}>
              <h1 style={{ color: 'red' }}>{errorMessage}</h1>
            </div>
            )}
          </div>
          <PasswordFooter />
        </div>
      </div>
    </Fragment>
  );
};

export default ForgotPassword;
