import { createReducer } from 'utils';
import { createSelector } from 'reselect';
import {
  PRE_MATCH_HIDE_OPENER,
  MATCH_BREAK_STAGE_SWITCH_SLIDE,
  MATCH_BREAK_UPDATE_SLIDES,
  ANNOUNCEMENTLOOP_STAGE_SWITCH_SLIDE,
  SET_SUBSTAGES,
  CACHE_STATIC_VIDEOS,
  SET_ACTIVE_STAGE,
  SET_SHOWN_STAGE,
  SET_STATIC_VIDEO_ENDED,
  SET_STATIC_VIDEO_PLAYING,
  SET_ACTIVE_TEMP_STAGE,
  TECHNICAL_ISSUES,
} from 'constants/actions';
import {
  STAGE_ANNOUNCEMENT,
  STAGE_HIGHLIGHT,
  STAGE_PRE_MATCH,
  STAGE_BREAK,
  STAGE_ANNOUNCEMENT_LOOP,
  STAGE_POST_MATCH,
  CHANNEL_TRAILER,
  MATCH_BREAK_STATS,
  STAGE_WORLD_LOTTERY,
  STAGE_LIVE,
  STAGE_INITIAL,
} from 'constants/bcms';

const initialState = {
  activeStage: null,
  shownStage: 0,
  refresh: 0,
  technicalIssuesStatus: null,

  staticVideoEnded: false,
  stages: {
    [STAGE_PRE_MATCH]: {
      hideOpener: false,
      openerDuration: 10,
      useSeparator: true,
    },
    [STAGE_POST_MATCH]: {
      useSeparator: true,
    },
    [STAGE_ANNOUNCEMENT_LOOP]: {
      currentSlideIdx: 0,
      defaultInterval: 60000,
      useSeparator: false,
      slides: [CHANNEL_TRAILER, STAGE_ANNOUNCEMENT, STAGE_HIGHLIGHT],
    },
    [STAGE_ANNOUNCEMENT]: {
      useSeparator: false,
      duration: 10000,
    },
    [STAGE_BREAK]: {
      currentSlideIdx: 0,
      defaultInterval: 60000,
      allSlides: [CHANNEL_TRAILER, MATCH_BREAK_STATS],
      dynamicSlides: [],
      useSeparator: false,
    },
    [STAGE_LIVE]: {
      useSeparator: true,
    },
    [STAGE_WORLD_LOTTERY]: {
      useSeparator: false,
    },
    [STAGE_HIGHLIGHT]: {
      useSeparator: true,
    },
    [STAGE_INITIAL]: {
      useSeparator: false,
      duration: 10000,
    },
  },
  substages: {
    // 1: Channel trailer
    // 3: Game announcement
    // 5: Match opener
    // 6: Pre-match stats
    // 9: Match-break stats
    // 10: Post-match stats
  },
  staticVideos: {
    channelTrailerCachedUrl: null,
    marketingVideoCachedUrl: null,
  },
  activeTempStage: null,
};

export default createReducer(initialState, {
  [PRE_MATCH_HIDE_OPENER]: state => ({
    ...state,
    stages: {
      ...state.stages,
      [STAGE_PRE_MATCH]: {
        ...state.stages[STAGE_PRE_MATCH],
        hideOpener: true,
      },
    },
  }),
  [SET_SUBSTAGES]: (state, payload) => ({
    ...state,
    stages: {
      ...state.stages,
      [STAGE_PRE_MATCH]: {
        ...state.stages[STAGE_PRE_MATCH],
        hideOpener: false, // reset
      },
    },
    substages: {
      ...payload,
    },
  }),
  [ANNOUNCEMENTLOOP_STAGE_SWITCH_SLIDE]: (state, payload) => ({
    ...state,
    stages: {
      ...state.stages,
      [STAGE_ANNOUNCEMENT_LOOP]: {
        ...state.stages[STAGE_ANNOUNCEMENT_LOOP],
        currentSlideIdx: payload,
      },
    },
  }),
  [MATCH_BREAK_STAGE_SWITCH_SLIDE]: (state, payload) => ({
    ...state,
    stages: {
      ...state.stages,
      [STAGE_BREAK]: {
        ...state.stages[STAGE_BREAK],
        currentSlideIdx: payload,
      },
    },
  }),
  [MATCH_BREAK_UPDATE_SLIDES]: (state, payload) => ({
    ...state,
    stages: {
      ...state.stages,
      [STAGE_BREAK]: {
        ...state.stages[STAGE_BREAK],
        dynamicSlides: payload,
      },
    },
  }),
  [CACHE_STATIC_VIDEOS]: (state, payload) => ({
    ...state,
    staticVideos: {
      ...state.staticVideos,
      ...payload,
    },
  }),
  [SET_ACTIVE_STAGE]: (state, payload) => ({
    ...state,
    activeStage: payload.stageId,
    staticVideoEnded: false,
  }),
  [SET_SHOWN_STAGE]: (state, payload) => ({
    ...state,
    shownStage: payload ? payload.stageId : 0,
    refresh: payload ? payload.refresh : 0,
  }),
  [SET_STATIC_VIDEO_ENDED]: state => ({
    ...state,
    staticVideoEnded: true,
    activeTempStage: null,
  }),
  [SET_STATIC_VIDEO_PLAYING]: state => ({
    ...state,
    staticVideoEnded: false,
    activeTempStage: null,
  }),
  [SET_ACTIVE_TEMP_STAGE]: (state, tempStage) => ({
    ...state,
    activeTempStage: tempStage,
    staticVideoEnded: false,
  }),
  [TECHNICAL_ISSUES]: (state, status) => ({
    ...state,
    technicalIssuesStatus: status,
  }),
});

export const getStageConfig = (state, stage) => state.stages[stage];

export const getPreMatchHideOpener = (
  state => (state.stages[STAGE_PRE_MATCH] ? state.stages[STAGE_PRE_MATCH].hideOpener : null)
);

export const getStageDuration = createSelector(
  (state, stageId) => state.stages[stageId],
  stage => (stage ? stage.duration : null),
);

export const getSubstages = createSelector(
  state => state.substages,
  substages => (substages
    ? Object.keys(substages).map(k => parseInt(k, 10))
    : []),
);

export const getChannelTrailerCachedUrl = createSelector(
  state => state.staticVideos.channelTrailerCachedUrl,
  channelTrailerUrl => channelTrailerUrl,
);

export const getMarketingVideoCachedUrl = createSelector(
  state => state.staticVideos.marketingVideoCachedUrl,
  marketingVideoUrl => marketingVideoUrl,
);

export const getActiveStage = createSelector(
  state => state.activeStage,
  stage => stage,
);

export const getShownStage = createSelector(
  state => state.shownStage,
  stage => stage,
);

export const getStageRefresh = createSelector(
  state => state.refresh,
  refresh => refresh,
);

export const showStageSeparator = createSelector(
  [
    state => state.activeStage,
    state => state.stages,
    state => state.activeTempStage,
  ],
  (activeStage, stages, activeTempStage) => (
    activeStage && !activeTempStage
      ? stages[activeStage].useSeparator
      : false),
);

export const getStaticVideoEnded = createSelector(
  state => state.staticVideoEnded,
  isEnded => isEnded,
);

export const getActiveTempStage = createSelector(
  state => state.activeTempStage,
  tempStage => tempStage,
);

export const getTechnicalIssuesStatus = createSelector(
  state => state.technicalIssuesStatus,
  status => status,
);
