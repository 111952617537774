import 'semantic-ui-css/components/grid.min.css';
import 'styles/tailwind.css';
import 'styles/GridOverride.css';
import 'babel-polyfill'; // NOTE: 'babel-polyfill' needed for redux-saga generator function
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import styles from 'styles/app.scss';
import 'newDesign/styles/style.scss';
import { START_LCR } from 'constants/actions';
import { HashRouter, Route, Redirect } from 'react-router-dom';
import configureStore from './stores';
import LoadingSpinner from './views/widgets/LoadingSpinner';
import Login from './newDesign/modules/auth/login/Login';
import ForgotPassword from './newDesign/modules/auth/forgotPassword/ForgotPassword';
import ResetPassword from './newDesign/modules/auth/resetPassword/ResetPassword';
import { isNewHostName } from './utils/Url';

// lazy load to reduce page load time
const Root = lazy(() => import('./containers/Root'));

const countOccurences = (str, searchString) => {
  const regex = new RegExp(searchString, 'g');
  return (str.match(regex) || []).length;
};

function bootstrapLCR() {
  // Required for SRLive
  document.getElementsByTagName('html')[0].setAttribute('id', 'SRLive');
  const app = document.createElement('div');
  app.className = styles.app;
  document.body.appendChild(app);

  const store = configureStore(window.__INITIAL_STATE__);

  const render = App => {
    ReactDOM.render(
      <HashRouter>
        <Suspense fallback={<LoadingSpinner />}>
          <Provider store={store}>
            <Route
              exact
              path='/login'
              render={() => (
                <Login />
              )}
            />
            <Route
              exact
              path='/forgot-password'
              render={() => (
                <ForgotPassword />
              )}
            />
            <Route
              exact
              path='/reset-password'
              render={() => (
                <ResetPassword />
              )}
            />
            <Route
              exact
              path='/client/:userId'
              render={props => {
                const { match } = props;
                let qUrl = null;
                let queryStr = props.location.search;

                const cond1 = countOccurences(queryStr, 'streamUrl=');
                const cond2 = countOccurences(queryStr, '\\[');
                const cond3 = countOccurences(queryStr, '\\]');

                // get url inside []
                if (cond1 === 1 && cond2 === 1 && cond3 === 1) {
                  const startIdx = queryStr.indexOf('streamUrl=');
                  const firstBracket = queryStr.indexOf('[');
                  const endIdx = queryStr.indexOf(']');
                  qUrl = queryStr.slice(firstBracket + 1, endIdx);

                  queryStr = queryStr.slice(0, startIdx) + queryStr.slice(endIdx + 2);
                }

                const searchParams = new URLSearchParams(queryStr);

                const parseNumber = (str, defaultVal = null) => {
                  const num = parseInt(searchParams.get(str), 10);
                  return (typeof num === 'number' && !Number.isNaN(num)) ? num : defaultVal;
                };

                const parseBool = (str, defaultVal = undefined) => (
                  parseInt(searchParams.get(str), 10) >= 1
                    ? true
                    : parseInt(searchParams.get(str), 10) === 0
                      ? false
                      : defaultVal
                );

                const qMMStatus = parseNumber('mmStatus');
                const qAutoStage = parseNumber('aStage', 0);
                const qOddsRot = parseNumber('oddsRot', 0);
                const qAutoSport = parseNumber('as');
                const qStatSlide = parseNumber('ss');
                const qSRDev = parseNumber('srDev', 0);
                const newFE = parseNumber('new', 1);
                /*
                  newFE = 1 = force new react comps for all
                  newFE = 2 = prematch new, oddspanel old
                */

                const qLastMatchId = parseNumber('lm');
                const qHOTD = parseNumber('hotd');
                let qMatchId = parseNumber('matchId');
                let qStage = parseNumber('stage');
                let qLayout = parseNumber('layout');
                let qTmpStage = parseNumber('tStage');

                // short versions
                qMatchId = parseNumber('m', qMatchId);
                qStage = parseNumber('s', qStage);
                qLayout = parseNumber('l', qLayout);
                if (parseNumber('x')) {
                  qTmpStage = -1;
                }

                const qMirrorProd = parseBool('useProd');
                const qOldFeeds = parseBool('oldFeeds');
                const qPlayerUI = parseBool('ui');
                const qRefresh = parseBool('refresh', true);
                const qLMT = parseBool('lmt', undefined);
                const qLightMode = parseBool('lightMode', undefined);
                const qRelease = parseBool('lsrelease', false);
                const qTT = parseBool('tt', false);
                const qStopTime = parseBool('st', false);

                const qLang = searchParams.get('lang');
                const qFeed = searchParams.get('fnFeed');
                const qReplay = searchParams.get('replay');

                store.dispatch({
                  type: START_LCR,
                  payload: {
                    userId: parseInt(match.params.userId, 10),
                    newFE,
                    qMatchId,
                    qStage,
                    qTmpStage,
                    qUrl,
                    qLang,
                    qFeed,
                    qMirrorProd,
                    qAutoStage,
                    qLayout,
                    qMMStatus,
                    qReplay,
                    qDateNow: parseInt(Date.now() / 1000, 10),
                    qLMT,
                    qLightMode,
                    qRelease,
                    qRefresh,
                    qOddsRot,
                    qSRDev,
                    qAutoSport: qTT === true ? 20 : qAutoSport,
                    qStopTime,
                    qLastMatchId,
                    qPlayerUI,
                    qOldFeeds,
                    qStatSlide,
                    qHOTD,
                  },
                });
                return <App />;
              }}
            />
            {isNewHostName() && <Route exact path='/' render={() => <Redirect to='/login' />} />}
          </Provider>
        </Suspense>
      </HashRouter>,
      app,
    );
  };

  render(Root);

  // Hot Module Replacement API
  if (module.hot) {
    module.hot.accept('./containers/Root.js', () => {
      render(Root);
    });
  }
}

bootstrapLCR();
