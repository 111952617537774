import { createReducer } from 'utils';
import {
  SRLIVE_LOADING,
  SRLIVE_LOAD_SUCCESS,
  SRLIVE_LOAD_ERROR,
} from 'constants/actions';

const initialState = {
  isLoading: false,
  error: null,
  SRLiveLoaded: null,
};

export default createReducer(initialState, {
  [SRLIVE_LOADING]: state => (
    { ...state, SRLiveLoaded: false, isLoading: true }
  ),
  [SRLIVE_LOAD_SUCCESS]: state => (
    {
      ...state, SRLiveLoaded: true, isLoading: false,
    }
  ),
  [SRLIVE_LOAD_ERROR]: (state, payload) => (
    {
      ...state, isLoading: false, SRLiveLoaded: false, error: payload,
    }
  ),
});

export const srliveLoaded = state => state.SRLiveLoaded;
