import {
  call,
  put,
  select,
  fork,
} from 'redux-saga/effects';
import {
  updateScorboardBottom,
  updateScorboardTop,
} from 'actions/components';
import {
  getMatchId,
  getMatchTeams,
  getMatchTournament,
  getSBCompConfig,
  getMatchHomeLastMResult,
  getMatchAwayLastMResult,
  getMatchDetailsStat,
} from 'reducers';
import {
  UPDATE_MATCH_INFO,
  UPDATE_MATCH_DETAILS,
  UPDATE_MATCH_DELTA,
  UPDATE_STATS_TEAM_LASTX,
} from 'constants/actions';

import {
  SBB_TOURNAMENT_INFO,
  SCOREBOARD_TOP,
  SBT_NATIONALITY,
  SBT_LAST_MATCHES,
  SBT_POINTS_WON,
  SBT_BREAK_POINTS_WON,
  SBT_SERVICE_POINTS_WON,
  SBT_SERVICE_GAMES_WON,
  SBT_TIEBREAKS_WON,
  SBT_1ST_SERVE_SUCCESSFUL,
  SBT_GAMES_WON,
  SBT_ACES,
  SBT_FORM_PERC,
  SBT_DOUBLE_FAULTS,
} from 'constants/components';
import { sagaRunTakeLatest } from '../../utilSagas';

// SCOREBOARD Bottom

function* onMatchInfo() {
  const tour = yield select(getMatchTournament, yield select(getMatchId));

  yield put(updateScorboardBottom({
    slide: SBB_TOURNAMENT_INFO,
    status: !!tour,
  }));
}

function* onMatchInfoTop() {
  const matchId = yield select(getMatchId);
  const teams = yield select(getMatchTeams, matchId);

  if (teams) {
    yield put(updateScorboardTop({
      slide: SBT_NATIONALITY,
      status: !!teams.home[0].cc,
    }));
  }
}

function* getStat(key) {
  const mId = yield select(getMatchId);
  const config = (yield select(getSBCompConfig, SCOREBOARD_TOP, key));
  const mdKey = config ? config.key : null;

  return yield select(getMatchDetailsStat, mId, mdKey);
}

function* onMatchDetails() {
  yield put(updateScorboardTop({
    slide: SBT_ACES,
    status: (yield call(getStat, SBT_ACES)) != null,
  }));

  yield put(updateScorboardTop({
    slide: SBT_POINTS_WON,
    status: (yield call(getStat, SBT_POINTS_WON)) != null,
  }));

  yield put(updateScorboardTop({
    slide: SBT_SERVICE_GAMES_WON,
    status: (yield call(getStat, SBT_SERVICE_GAMES_WON)) != null,

  }));

  yield put(updateScorboardTop({
    slide: SBT_SERVICE_POINTS_WON,
    status: (yield call(getStat, SBT_SERVICE_POINTS_WON)) != null,

  }));

  yield put(updateScorboardTop({
    slide: SBT_BREAK_POINTS_WON,
    status: (yield call(getStat, SBT_BREAK_POINTS_WON)) != null,

  }));

  yield put(updateScorboardTop({
    slide: SBT_TIEBREAKS_WON,
    status: (yield call(getStat, SBT_TIEBREAKS_WON)) != null,
  }));

  yield put(updateScorboardTop({
    slide: SBT_GAMES_WON,
    status: (yield call(getStat, SBT_GAMES_WON)) != null,
  }));

  yield put(updateScorboardTop({
    slide: SBT_1ST_SERVE_SUCCESSFUL,
    status: (yield call(getStat, SBT_1ST_SERVE_SUCCESSFUL)) != null,
  }));

  yield put(updateScorboardTop({
    slide: SBT_DOUBLE_FAULTS,
    status: (yield call(getStat, SBT_DOUBLE_FAULTS)) != null,
  }));
}

function* onTeamLastX() {
  const matchId = yield select(getMatchId);
  const home = yield select(getMatchHomeLastMResult, matchId);
  const away = yield select(getMatchAwayLastMResult, matchId);

  yield put(updateScorboardTop({
    slide: SBT_LAST_MATCHES,
    status: !!((home && home.length) && (away && away.length)),
  }));

  yield put(updateScorboardTop({
    slide: SBT_FORM_PERC,
    status: !!((home && home.length) && (away && away.length)),
  }));
}


export const tennisTop = [
  fork(sagaRunTakeLatest([UPDATE_MATCH_INFO, UPDATE_MATCH_DELTA], onMatchInfoTop)),
  fork(sagaRunTakeLatest([UPDATE_MATCH_DETAILS], onMatchDetails)),
  fork(sagaRunTakeLatest([UPDATE_STATS_TEAM_LASTX], onTeamLastX)),
];

export const tennisBottom = [
  fork(sagaRunTakeLatest([UPDATE_MATCH_INFO, UPDATE_MATCH_DELTA], onMatchInfo)),
];
