import React, { useEffect } from 'react';
import style from 'styles/widgets/IssuesStage.scss';
import cssModule from 'react-css-modules';
import { useDispatch } from 'react-redux';
import { LCR_AUTH_CHECK } from 'constants/actions';


const ExpirationStage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: LCR_AUTH_CHECK,
    });
  }, [dispatch]);

  return (
    <div styleName='container'>
      <div styleName='messageBox'>
        <div styleName='message'>
          <div styleName='title'>
            Account disabled
          </div>
          <div styleName='messageBody'>
            Your account is disabled, please contact your sales representative or{' '}
            <a href='mailto:sales@betradar.com'>sales@betradar.com</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default cssModule(ExpirationStage, style);
