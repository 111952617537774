import {
  call,
  put,
} from 'redux-saga/effects';
import {
  playerLoading,
  playerLoadSuccess,
  playerLoadError,
} from 'actions/liveplayer';
import loadScript from 'load-script';
import { scriptUrl, styleUrl } from 'Config/player';


const fetchScript = url => new Promise((resolve, reject) => loadScript(url, {}, err => {
  if (err) {
    reject(err);
  }
  resolve();
}));


const fetchStyle = url => new Promise((resolve, reject) => {
  if (!url) {
    reject(new Error(`Could not load "${url}"`));
  }

  const link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.onerror = err => {
    const msg = new Error(`Could not load ${err.target.href}`);
    reject(msg);
  };
  link.onload = () => {
    resolve();
  };
  link.href = url;
  const headScript = document.querySelector('script');
  headScript.parentNode.insertBefore(link, headScript);
});

function* loadPlayer() {
  yield put(playerLoading());
  try {
    yield call(fetchScript, scriptUrl);
    yield call(fetchStyle, styleUrl);
    yield put(playerLoadSuccess());
  } catch (err) {
    yield put(playerLoadError('Loading player failed: ' + err.message));
    throw err;
  }
}

export default loadPlayer;
