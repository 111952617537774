import { createReducer } from 'utils';
import { createSelector } from 'reselect';
import {
  SCOREBOARD_BOTTOM_UPDATE_SLIDES,
  SCOREBOARD_BOTTOM_SWITCH_SLIDE,
  SCOREBOARD_TOP_UPDATE_SLIDES,
  SCOREBOARD_TOP_SWITCH_SLIDE,
  STATSLIDES_SWITCH_SLIDE,
  STATSLIDES_UPDATE_SLIDES,
  SCOREBOARD_STOP,
  BETTING_SUGGESTION_EVENT,
  CLEAR_BETTING_SUGGESTIONS,
  SHOW_BETTING_SUGGESTION_EVENT,
  HIDE_BETTING_SUGGESTION_EVENT,
  INITIAL_WAIT_OVER,
} from 'constants/actions';
import {
  SCOREBOARD_TOP,
  SCOREBOARD_BOTTOM,
  SBB_TOURNAMENT_INFO,
  SBB_LEAGUE_STANDINGS,
  SBT_SLIDE_DURATION,
  SBT_LAST_MATCHES,
  SBT_GOALS_SCORED,
  SBT_GOALS_CONCEDED,
  SBT_LEAGUE_RANK,
  SBT_CORNER_KICKS,
  SBT_ATT_STRENGTH,
  SBT_GOALS_SCORED_16_30,
  SBT_GOALS_CONCEDED_16_30,
  SBT_1ST_HALF_SCORE,
  SBT_FORM_PERC,
  SBT_NATIONALITY,
  SBT_ENDED,
  SBT_MAX_POINTS_IN_A_ROW,
  SBT_POINTS_WON,
  SBT_1ST_SERVE_PTS_WON,
  SBT_2ND_SERVE_PTS_WON,
  SBT_BREAK_POINTS_WON,
  SBT_SERVICE_POINTS_WON,
  SBT_MAX_GAMES_IN_A_ROW,
  SBT_SERVICE_GAMES_WON,
  SBT_TIEBREAKS_WON,
  SBT_1ST_SERVE_SUCCESSFUL,
  SBT_RECEIVER_POINTS_WON,
  SBT_2ND_SERVE_SUCCESSFUL,
  SBT_GAMES_WON,
  SBT_ACES,
  SBT_DOUBLE_FAULTS,
  SBT_FT,
  SBT_REBOUNDS,
  SBT_BIGGEST_LEAD,
  SBT_3PT,
  SBT_2PT,
  SBT_TIME_SPENT_IN_LEAD,
  SBT_PUCK_POSSESION,
  SBT_GOALS_IN_POWERPLAY,
  SBT_GOALS_SHORTHANDED,
  SBT_SERVICE_ERRORS,
  SBT_POINTS_PER_MATCH,
  SBT_SETS_PER_MATCH,
  SBT_SUSPENSIONS,
  STATSLIDES,
  SS_LAST_MATCHES,
  SS_PREVIOUS_MEETINGS,
  SS_LIVESTATS,
  SS_SLIDE_DURATION,
  SBB_LEAGUE_STANDINGS_DURATION,
  SBB_TOURNAMENT_INFO_DURATION,
  SS_FORM_OVERALL,
  SS_FORM_CLAY,
  SS_FORM_INDOOR,
  SS_FORM_HARDCOURT,
  SS_FORM_GRASS,
  BETTINGSUGGESTIONS,
  SS_TIMELINE,
  SS_LEAGUE_TABLE,
  SS_BREAK_STAGE,
} from 'constants/components';
import { each as _each } from 'lodash';
import { MIN, SEC } from '../constants/app';

const topSlides = [
  SBT_LAST_MATCHES,
  SBT_GOALS_SCORED,
  SBT_GOALS_CONCEDED,
  SBT_LEAGUE_RANK,
  SBT_ATT_STRENGTH,
  SBT_NATIONALITY,
  SBT_CORNER_KICKS,
  SBT_1ST_HALF_SCORE,
  SBT_ENDED,
  SBT_GOALS_SCORED_16_30,
  SBT_GOALS_CONCEDED_16_30,
  SBT_FORM_PERC,
  SBT_POINTS_WON,
  SBT_BREAK_POINTS_WON,
  SBT_DOUBLE_FAULTS,
  SBT_SERVICE_GAMES_WON,
  SBT_MAX_POINTS_IN_A_ROW,
  SBT_1ST_SERVE_PTS_WON,
  SBT_2ND_SERVE_PTS_WON,
  SBT_SERVICE_POINTS_WON,
  SBT_MAX_GAMES_IN_A_ROW,
  SBT_TIEBREAKS_WON,
  SBT_RECEIVER_POINTS_WON,
  SBT_2ND_SERVE_SUCCESSFUL,
  SBT_GAMES_WON,
  SBT_ACES,
  SBT_1ST_SERVE_SUCCESSFUL,
  SBT_FT,
  SBT_REBOUNDS,
  SBT_BIGGEST_LEAD,
  SBT_3PT,
  SBT_2PT,
  SBT_TIME_SPENT_IN_LEAD,
  SBT_PUCK_POSSESION,
  SBT_GOALS_IN_POWERPLAY,
  SBT_GOALS_SHORTHANDED,
  SBT_SERVICE_ERRORS,
  SBT_POINTS_PER_MATCH,
  SBT_SETS_PER_MATCH,
  SBT_SUSPENSIONS,
].sort((a, b) => a - b); // asc order

const statslides = [
  SS_LAST_MATCHES,
  SS_PREVIOUS_MEETINGS,
  SS_LIVESTATS,
  SS_FORM_OVERALL,
  SS_FORM_CLAY,
  SS_FORM_INDOOR,
  SS_FORM_HARDCOURT,
  SS_FORM_GRASS,
  SS_TIMELINE,
  SS_LEAGUE_TABLE,
].sort((a, b) => a - b); // asc order

const statslidesActive = statslides.reduce((acc, cur) => {
  acc[cur] = {
    active: false,
  };
  return acc;
}, {});

const statslidesConfigs = statslides.reduce((acc, cur) => {
  switch (cur) {
    case SS_TIMELINE:
      acc[cur] = {
        duration: 90 * SEC,
      };
      break;
    case SS_LEAGUE_TABLE:
      acc[cur] = {
        duration: 55 * SEC,
      };
      break;
    default:
      acc[cur] = {
        duration: SS_SLIDE_DURATION,
        higherIsBetter: true,
      };
      break;
  }

  return acc;
}, {});

const topActive = topSlides.reduce((acc, cur) => {
  acc[cur] = {
    active: false,
  };
  return acc;
}, {});

const topConfigs = topSlides.reduce((acc, cur) => {
  if (cur === SBT_ENDED) {
    acc[cur] = {
      duration: 60 * MIN,
    };
  } else {
    acc[cur] = {
      duration: SBT_SLIDE_DURATION,
      higherIsBetter: true,
    };
  }

  // defines config for MatchDetails slide
  if (cur === SBT_ACES) {
    acc[cur] = {
      ...acc[cur],
      key: '130',
      text: 'ACES',
      iconSportDependent: {
        '5': 'aces',
        '23': 'acesVolley',
        '34': 'acesVolley',
      },
    };
  } else if (cur === SBT_POINTS_WON) {
    acc[cur] = {
      ...acc[cur],
      key: '136',
      text: 'POINTS WON',
      icon: 'pointsWon',
      iconSportDependent: {
        '5': 'pointsWon',
        '20': 'pointsWon',
        '23': 'pointsWonVolley',
        '34': 'pointsWonVolley',
      },
    };
  } else if (cur === SBT_SERVICE_GAMES_WON) {
    acc[cur] = {
      ...acc[cur],
      key: '145',
      text: 'SERVICE GAMES WON',
      icon: '',
    };
  } else if (cur === SBT_SERVICE_POINTS_WON) {
    acc[cur] = {
      ...acc[cur],
      key: '141',
      text: 'SERVICE POINTS WON',
      iconSportDependent: {
        '5': 'servicePointsWon',
        '23': 'servicePointsWonVolley',
        '34': 'servicePointsWonVolley',
      },
    };
  } else if (cur === SBT_BREAK_POINTS_WON) {
    acc[cur] = {
      ...acc[cur],
      key: '139',
      text: 'BREAK POINTS WON',
      icon: 'breakPointsWon',
    };
  } else if (cur === SBT_TIEBREAKS_WON) {
    acc[cur] = {
      ...acc[cur],
      key: '1188',
      text: 'TIEBREAKS WON',
      icon: '',
    };
  } else if (cur === SBT_GAMES_WON) {
    acc[cur] = {
      ...acc[cur],
      key: 'gameswon',
      text: 'GAMES WON',
      icon: '',
    };
  } else if (cur === SBT_1ST_SERVE_SUCCESSFUL) {
    acc[cur] = {
      ...acc[cur],
      key: '1410',
      text: '1ST SERVE SUCCESSFUL',
      icon: '',
    };
  } else if (cur === SBT_DOUBLE_FAULTS) {
    acc[cur] = {
      ...acc[cur],
      key: '132',
      text: 'DOUBLE FAULTS',
      icon: 'doubleFaults',
      higherIsBetter: false,

    };
  } else if (cur === SBT_MAX_POINTS_IN_A_ROW) {
    acc[cur] = {
      ...acc[cur],
      key: '134',
      text: 'Max Points in a Row"',
      icon: 'pointsWon',
      iconSportDependent: {
        '20': 'pointsWon',
        '23': 'pointsWonVolley',
        '34': 'pointsWonVolley',
      },
    };
  } else if (cur === SBT_3PT) {
    acc[cur] = {
      ...acc[cur],
      key: 'threepointers',
      text: '3PT',
      icon: 'threepointers',
    };
  } else if (cur === SBT_2PT) {
    acc[cur] = {
      ...acc[cur],
      key: 'twopointers',
      text: '3PT',
      icon: 'twopointers',
    };
  } else if (cur === SBT_FT) {
    acc[cur] = {
      ...acc[cur],
      key: '1065',
      text: '3PT',
      icon: 'freethrows',
    };
  } else if (cur === SBT_REBOUNDS) {
    acc[cur] = {
      ...acc[cur],
      key: '1067',
      text: '3PT',
      icon: 'rebounds',
    };
  } else if (cur === SBT_BIGGEST_LEAD) {
    acc[cur] = {
      ...acc[cur],
      key: '1619',
      text: '3PT',
      icon: '',
    };
  } else if (cur === SBT_TIME_SPENT_IN_LEAD) {
    acc[cur] = {
      ...acc[cur],
      key: '1654',
      text: 'Time in Lead',
      icon: '',
    };
  } else if (cur === SBT_PUCK_POSSESION) {
    acc[cur] = {
      ...acc[cur],
      key: '110',
      text: 'PUCK POSSSESION',
      percentage: true,
      iconSportDependent: {
        '4': 'puck',
        '6': 'handball',
      },
    };
  } else if (cur === SBT_GOALS_IN_POWERPLAY) {
    acc[cur] = {
      ...acc[cur],
      key: '1072',
      text: 'GOALS IN POWERPLAY',
      iconSportDependent: {
        '4': 'puck',
        '6': 'handball',
      },
    };
  } else if (cur === SBT_GOALS_SHORTHANDED) {
    acc[cur] = {
      ...acc[cur],
      key: '1073',
      text: 'GOALS SHORT-HANDED',
      iconSportDependent: {
        '4': 'puck',
        '6': 'handball',
      },
    };
  } else if (cur === SBT_RECEIVER_POINTS_WON) {
    acc[cur] = {
      ...acc[cur],
      key: '1410',
      text: 'RECEIVER POINTS WON',
      iconSportDependent: {
        '23': 'receiverPointsWonVolley',
        '34': 'receiverPointsWonVolley',
      },
    };
  } else if (cur === SBT_SERVICE_ERRORS) {
    acc[cur] = {
      ...acc[cur],
      key: '1077',
      text: 'SERVICE ERRORS',
      iconSportDependent: {
        '23': 'serviceErrors',
        '34': 'serviceErrors',
      },
    };
  } else if (cur === SBT_SUSPENSIONS) {
    acc[cur] = {
      ...acc[cur],
      key: '1070',
      text: 'SUSPENSIONS',
      icon: 'suspensions',
      higherIsBetter: false,

    };
  }


  return acc;
}, {});

const initialState = {
  scoreboard: {
    [SCOREBOARD_TOP]: {
      currentSlideIdx: null,
      allSlides: [...topSlides],
      dynamicSlides: {
        ...topActive,
      },
      configs: {
        ...topConfigs,
      },
    },
    [SCOREBOARD_BOTTOM]: {
      currentSlideIdx: null,
      allSlides: [SBB_TOURNAMENT_INFO, SBB_LEAGUE_STANDINGS],
      dynamicSlides: {
        [SBB_TOURNAMENT_INFO]: {
          active: false,
        },
        [SBB_LEAGUE_STANDINGS]: {
          active: false,
        },
      },
      configs: {
        [SBB_TOURNAMENT_INFO]: {
          useSeparator: false,
          duration: SBB_TOURNAMENT_INFO_DURATION,
        },
        [SBB_LEAGUE_STANDINGS]: {
          duration: SBB_LEAGUE_STANDINGS_DURATION,
          useSeparator: false,
        },
      },
    },
  },
  [STATSLIDES]: {
    currentSlideIdx: null,
    allSlides: [...statslides],
    dynamicSlides: {
      ...statslidesActive,
    },
    configs: {
      ...statslidesConfigs,
    },
  },
  [BETTINGSUGGESTIONS]: {
    showLatest: false,
    initialWaitOver: false,
    latest: null,
    recent: [],
  },
};

export default createReducer(initialState, {
  [SCOREBOARD_TOP_SWITCH_SLIDE]: (state, payload) => ({
    ...state,
    scoreboard: {
      ...state.scoreboard,
      [SCOREBOARD_TOP]: {
        ...state.scoreboard[SCOREBOARD_TOP],
        currentSlideIdx: payload,
      },
    },
  }),
  [SCOREBOARD_TOP_UPDATE_SLIDES]: (state, payload) => ({
    ...state,
    scoreboard: {
      ...state.scoreboard,
      [SCOREBOARD_TOP]: {
        ...state.scoreboard[SCOREBOARD_TOP],
        dynamicSlides: {
          ...state.scoreboard[SCOREBOARD_TOP].dynamicSlides,
          [payload.slide]: {
            active: payload.status,
          },
        },
      },
    },
  }),
  [SCOREBOARD_BOTTOM_SWITCH_SLIDE]: (state, payload) => ({
    ...state,
    scoreboard: {
      ...state.scoreboard,
      [SCOREBOARD_BOTTOM]: {
        ...state.scoreboard[SCOREBOARD_BOTTOM],
        currentSlideIdx: payload,
      },
    },
  }),
  [SCOREBOARD_BOTTOM_UPDATE_SLIDES]: (state, payload) => ({
    ...state,
    scoreboard: {
      ...state.scoreboard,
      [SCOREBOARD_BOTTOM]: {
        ...state.scoreboard[SCOREBOARD_BOTTOM],
        dynamicSlides: {
          ...state.scoreboard[SCOREBOARD_BOTTOM].dynamicSlides,
          [payload.slide]: {
            active: payload.status,
          },
        },
      },
    },
  }),
  [SCOREBOARD_STOP]: state => ({
    ...state,
    scoreboard: {
      ...state.scoreboard,
      [SCOREBOARD_TOP]: {
        ...state.scoreboard[SCOREBOARD_TOP],
        dynamicSlides: {
          ...topActive,
        },
        currentSlideIdx: null,

      },
    },
  }),
  [STATSLIDES_SWITCH_SLIDE]: (state, payload) => ({
    ...state,
    [STATSLIDES]: {
      ...state[STATSLIDES],
      currentSlideIdx: payload,

    },
  }),
  [STATSLIDES_UPDATE_SLIDES]: (state, payload) => ({
    ...state,
    [STATSLIDES]: {
      ...state[STATSLIDES],
      dynamicSlides: {
        ...state[STATSLIDES].dynamicSlides,
        [payload.slide]: {
          active: payload.status,
        },
      },
    },
  }),
  [BETTING_SUGGESTION_EVENT]: (state, payload) => ({
    ...state,
    [BETTINGSUGGESTIONS]: {
      ...state[BETTINGSUGGESTIONS],
      latest: { ...payload },
      showLatest: false,
      recent: [
        ...[payload], // latest first
        ...state[BETTINGSUGGESTIONS].recent,
      ].slice(0, 10) // keep max 10
      ,
    },
  }),
  [SHOW_BETTING_SUGGESTION_EVENT]: state => ({
    ...state,
    [BETTINGSUGGESTIONS]: {
      ...state[BETTINGSUGGESTIONS],
      showLatest: true,
    },
  }),
  [HIDE_BETTING_SUGGESTION_EVENT]: state => ({
    ...state,
    [BETTINGSUGGESTIONS]: {
      ...state[BETTINGSUGGESTIONS],
      showLatest: false,
    },
  }),
  [CLEAR_BETTING_SUGGESTIONS]: state => ({
    ...state,
    [BETTINGSUGGESTIONS]: {
      ...state[BETTINGSUGGESTIONS],
      latest: null,
      showLatest: false,
      initialWaitOver: false,
      recent: [],
    },
  }),
  [INITIAL_WAIT_OVER]: state => ({
    ...state,
    [BETTINGSUGGESTIONS]: {
      ...state[BETTINGSUGGESTIONS],
      initialWaitOver: true,
    },
  }),

});

export const getSBComp = createSelector(
  (state, comp) => state.scoreboard[comp],
  comp => comp,
);

export const getSBCompConfig = createSelector(
  getSBComp,
  (state, comp, idx) => idx,
  (comp, idx) => {
    if (idx == null) {
      return comp.configs;
    }

    return (idx >= 0 ? comp.configs[idx] : comp.configs);
  },

);


export const getSBCompKey = createSelector(
  getSBComp,
  (state, comp, idx) => idx,
  (comp, idx) => {
    if (idx == null) {
      return '';
    }

    return comp.configs[idx].key;
  },

);

export const getSBCompAllSlides = createSelector(
  getSBComp,
  comp => comp.allSlides,
);

export const getSBCompActiveSlides = createSelector(
  getSBComp,
  comp => comp.dynamicSlides,
);

export const getSBCompSlideIdx = createSelector(
  getSBComp,
  comp => comp.currentSlideIdx,
);


/**
 * STATSLIDES
 */

export const getSSComp = createSelector(
  state => state[STATSLIDES],
  comp => comp,
);

export const getSSCompConfig = createSelector(
  getSSComp,
  (state, comp, idx) => idx,
  (comp, idx) => {
    if (idx == null) {
      return comp.configs;
    }

    return (idx >= 0 ? comp.configs[idx] : comp.configs);
  },
);

export const getSSCompKey = createSelector(
  getSSComp,
  (state, comp, idx) => idx,
  (comp, idx) => {
    if (idx == null) {
      return '';
    }

    return comp.configs[idx].key;
  },
);

export const getSSCompAllSlides = createSelector(
  getSSComp,
  comp => comp.allSlides,
);

export const getSSCompActivePreMatchSlideIds = createSelector(
  getSSComp,
  comp => {
    const activeSlideIds = [];

    _each(comp.dynamicSlides, (slide, slideId) => {
      const activeSlideId = parseInt(slideId, 10);
      // In dynamic slides presented all available slides with data for both pre-match and brake stages
      // Match statistics slide (id:2) and Timeline slide (id:8) related for Brake stage
      // Filtering for Pre Match stage implemented by skipping slides related to the Brake stage
      if (slide.active && !SS_BREAK_STAGE.includes(activeSlideId)) {
        activeSlideIds.push(activeSlideId);
      }
    });

    return activeSlideIds;
  },
);

export const getSSCompActiveBreakSlideIds = createSelector(
  getSSComp,
  comp => {
    const activeSlideIds = [];

    _each(comp.dynamicSlides, (slide, slideId) => {
      const activeSlideId = parseInt(slideId, 10);

      if (slide.active && SS_BREAK_STAGE.includes(activeSlideId)) {
        activeSlideIds.push(activeSlideId);
      }
    });

    return activeSlideIds;
  },
);

export const getSSCompDynamicSlides = createSelector(
  getSSComp,
  comp => comp.dynamicSlides,
);

export const getSSCompSlideIdx = createSelector(
  getSSComp,
  comp => comp.currentSlideIdx,
);

export const getBSLatest = createSelector(
  state => state[BETTINGSUGGESTIONS],
  bs => (bs && bs.latest ? bs.latest : null),
);

export const getBSVisibility = createSelector(
  state => state[BETTINGSUGGESTIONS],
  bs => bs && (bs.showLatest && bs.initialWaitOver),
);
