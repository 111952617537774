import { APIError } from 'utils/LoggerItem';

function handleJSONResponse(response) {
  return response.json().then(json => {
    if (response.ok) {
      return json;
    }
    const error = {
      ...json,
      status: response.status,
      statusText: response.statusText,
    };
    return Promise.reject(error);
  });
}

function handleTextResponse(response) {
  return response.text().then(text => {
    if (response.ok) {
      return text;
    }
    const error = {
      status: response.status,
      statusText: response.statusText,
      err: text,
    };
    return Promise.reject(error);
  });
}

function handleResponse(response) {
  const contentType = response.headers.get('content-type');

  if (contentType && contentType.includes('application/json')) {
    return handleJSONResponse(response);
  } else if (contentType && contentType.includes('text/html')) {
    return handleTextResponse(response);
  }
  // f.e. XML response
  return Promise.reject(new Error(`Sorry, content-type ${contentType} not supported`));
}

function get(url, options = undefined) {
  const getOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
    ...options,
  };

  return window
    .fetch(url, getOptions)
    .then(handleResponse)
    .catch(error => {
      // 403s with response ends up here too
      // check for real errors
      if (error && error.stack && error.message) {
        return new APIError('LCR Request error', error.message);
      }
      return error;
    });
}

export default { get };
