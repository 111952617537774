/*
 * action types
 */

import {
  MARKET_PACKAGES_LOAD_SUCCESS,
  MARKET_PACKAGES_LOADING,
} from 'constants/actions';

/*
 * action creators
 */

export function updateMarketPackages(response) {
  return { type: MARKET_PACKAGES_LOAD_SUCCESS, payload: response };
}

export function marketPackagesLoading() {
  return { type: MARKET_PACKAGES_LOADING };
}
