/*
 * action types
 */

import {
  PRE_MATCH_HIDE_OPENER,
  MATCH_BREAK_STAGE_SWITCH_SLIDE,
  MATCH_BREAK_UPDATE_SLIDES,
  ANNOUNCEMENTLOOP_STAGE_SWITCH_SLIDE,
  SET_SUBSTAGES,
  CACHE_STATIC_VIDEOS,
  SET_ACTIVE_STAGE,
  SET_SHOWN_STAGE,
  SET_ACTIVE_MATCH,
  SET_ACTIVE_TEMP_STAGE,
  SET_STATIC_VIDEO_ENDED,
  SET_STATIC_VIDEO_PLAYING,
  UNINTENDED_STAGE_CANCEL,
  STAGE_READY,
  TECHNICAL_ISSUES,
} from 'constants/actions';

/*
 * action creators
 */

export function setStageReady(stageId) {
  return {
    type: STAGE_READY,
    payload: stageId,
  };
}

/* Pre match stage actions */
export function hideOpener() {
  return {
    type: PRE_MATCH_HIDE_OPENER,
  };
}

/* Announcement loop stage actions */
export function switchAnnouncementLoopSlide(idx) {
  return {
    type: ANNOUNCEMENTLOOP_STAGE_SWITCH_SLIDE,
    payload: idx,
  };
}

/* Match break stage actions */
export function switchMatchBreakSlide(idx) {
  return {
    type: MATCH_BREAK_STAGE_SWITCH_SLIDE,
    payload: idx,
  };
}

export function updateBreakSlides(slides) {
  return {
    type: MATCH_BREAK_UPDATE_SLIDES,
    payload: slides,
  };
}

export function setSubstages(substages) {
  return {
    type: SET_SUBSTAGES,
    payload: substages,
  };
}

export function cacheStaticVideos(bloblUrls) {
  return {
    type: CACHE_STATIC_VIDEOS,
    payload: bloblUrls,
  };
}

export function setActiveStage(stageId, didInitalStage) {
  return {
    type: SET_ACTIVE_STAGE,
    payload: {
      stageId,
      didInitalStage,
    },
  };
}

export function setShownStage(stageId) {
  return {
    type: SET_SHOWN_STAGE,
    payload: {
      stageId,
      refresh: Date.now(),
    },
  };
}

export function setActiveMatch(matchId) {
  return {
    type: SET_ACTIVE_MATCH,
    payload: matchId,
  };
}

export function setUnintendedStageCancel(stageId, fallbackId) {
  return {
    type: UNINTENDED_STAGE_CANCEL,
    payload: {
      stageId,
      fallbackId,
    },
  };
}

export function setStaticVideoEnded() {
  return {
    type: SET_STATIC_VIDEO_ENDED,
  };
}

export function setStaticVideoPlaying() {
  return {
    type: SET_STATIC_VIDEO_PLAYING,
  };
}

export function setActiveTempStage(stage) {
  return {
    type: SET_ACTIVE_TEMP_STAGE,
    payload: stage,
  };
}


export function setTechnicalIssuesStatus(status) {
  return {
    type: TECHNICAL_ISSUES,
    payload: status,
  };
}
