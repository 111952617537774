import lcrRequest from 'lcrRequest';
import { transformAPIError } from 'utils';
import { HTTP_STATUS_CODE_FORBIDDEN } from 'constants/bcms';
import { isOldHostName } from 'utils/Url';

export default class CheckAuthorization {
  constructor(url, userId, isOperator = 0) {
    this.url = `${url}`;

    if (userId) {
      // Remove once BCMS is not needed
      if (isOldHostName()) {
        this.url += `?userid=${userId}`;
      } else if (isOperator) {
        this.url += `?user_id=${userId}`;
      }
    }
  }

  get() {
    const options = { credentials: 'include' };

    return lcrRequest
      .get(this.url, options)
      .then(response => {
        if (response.error) {
          throw response;
        }

        if (response && response.isAuthorized) {
          return response;
        }

        return {
          'isAuthorized': 0,
          'code': response.status || HTTP_STATUS_CODE_FORBIDDEN,
          'userId': null,
          'token': null,
        };
      })
      .catch(transformAPIError);
  }
}
