// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoadingSpinner__spinnerWrapper___3DlR8{height:100%;width:100%;display:-ms-flexbox;display:flex;-ms-flex:1;flex:1;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}.LoadingSpinner__spinner___1plC-{width:40px;height:40px;background-color:#0072b1;border-radius:100%;animation:LoadingSpinner__sk-scaleout___3KMiN 1s infinite ease-in-out}@keyframes LoadingSpinner__sk-scaleout___3KMiN{0%{transform:scale(0)}100%{transform:scale(3);opacity:0}}", ""]);
// Exports
exports.locals = {
	"spinnerWrapper": "LoadingSpinner__spinnerWrapper___3DlR8",
	"spinner": "LoadingSpinner__spinner___1plC-",
	"sk-scaleout": "LoadingSpinner__sk-scaleout___3KMiN"
};
module.exports = exports;
