import {
  put,
  select,
  fork,
  call,
} from 'redux-saga/effects';
import {
  updateScorboardTop,
} from 'actions/components';
import {
  getMatchId,
  getMatchTeams,
  getSBCompConfig,
  getMatchHomeLastMResult,
  getMatchAwayLastMResult,
  getMatchDetailsStat,
  getMatchHomeScored,
  getMatchAwayScored,
  getMatchHomeConceded,
  getMatchAwayConceded,
} from 'reducers';
import {
  UPDATE_MATCH_INFO,
  UPDATE_MATCH_DETAILS,
  UPDATE_MATCH_DELTA,
  UPDATE_STATS_TEAM_LASTX,
  UPDATE_STATS_SEASON_GOALS,
} from 'constants/actions';
import {
  SCOREBOARD_TOP,
  SBT_NATIONALITY,
  SBT_LAST_MATCHES,
  SBT_PUCK_POSSESION,
  SBT_GOALS_IN_POWERPLAY,
  SBT_GOALS_SHORTHANDED,
  SBT_GOALS_SCORED,
  SBT_GOALS_CONCEDED,
  SBT_SUSPENSIONS,
} from 'constants/components';
import { sagaRunTakeLatest } from '../../utilSagas';

function* onMatchInfoTop() {
  const matchId = yield select(getMatchId);
  const teams = yield select(getMatchTeams, matchId);

  if (teams) {
    yield put(updateScorboardTop({
      slide: SBT_NATIONALITY,
      status: !!teams.home[0].cc && !!teams.away[0].cc,
    }));
  }
}

function* getStat(key) {
  const mId = yield select(getMatchId);
  const config = (yield select(getSBCompConfig, SCOREBOARD_TOP, key));
  const mdKey = config ? config.key : null;


  return yield select(getMatchDetailsStat, mId, mdKey);
}

function* onMatchDetails() {
  yield put(updateScorboardTop({
    slide: SBT_PUCK_POSSESION,
    status: (yield call(getStat, SBT_PUCK_POSSESION)) != null,
  }));

  yield put(updateScorboardTop({
    slide: SBT_GOALS_IN_POWERPLAY,
    status: (yield call(getStat, SBT_GOALS_IN_POWERPLAY)) != null,
  }));

  yield put(updateScorboardTop({
    slide: SBT_GOALS_SHORTHANDED,
    status: (yield call(getStat, SBT_GOALS_SHORTHANDED)) != null,
  }));

  yield put(updateScorboardTop({
    slide: SBT_SUSPENSIONS,
    status: (yield call(getStat, SBT_SUSPENSIONS)) != null,
  }));
}

function* onStatsGoals() {
  const matchId = yield select(getMatchId);

  const homeS = yield select(getMatchHomeScored, matchId);
  const awayS = yield select(getMatchAwayScored, matchId);

  yield put(updateScorboardTop({
    slide: SBT_GOALS_SCORED,
    status: (homeS !== null && awayS !== null),
  }));

  const homeC = yield select(getMatchHomeConceded, matchId);
  const awayC = yield select(getMatchAwayConceded, matchId);

  yield put(updateScorboardTop({
    slide: SBT_GOALS_CONCEDED,
    status: (homeC !== null && awayC !== null),
  }));
}

function* onTeamLastX() {
  const matchId = yield select(getMatchId);
  const home = yield select(getMatchHomeLastMResult, matchId);
  const away = yield select(getMatchAwayLastMResult, matchId);

  yield put(updateScorboardTop({
    slide: SBT_LAST_MATCHES,
    status: !!((home && home.length) && (away && away.length)),
  }));
}

const icehockeyTop = [
  fork(sagaRunTakeLatest([UPDATE_STATS_TEAM_LASTX], onTeamLastX)),
  fork(sagaRunTakeLatest([UPDATE_MATCH_INFO, UPDATE_MATCH_DELTA], onMatchInfoTop)),
  fork(sagaRunTakeLatest([UPDATE_MATCH_DETAILS], onMatchDetails)),
  fork(sagaRunTakeLatest([UPDATE_STATS_SEASON_GOALS], onStatsGoals)),
];

export default icehockeyTop;
