import React from 'react';

import { useImportImageNewDesign } from 'hooks';
import { isOldHostName, redirectNewHostToLogin } from 'utils/Url';
import Footer from 'newDesign/modules/auth/footer/Footer';
import LoginForm from 'newDesign/modules/auth/login/loginForm/LoginForm';

import styles from 'newDesign/modules/auth/login/Login.scss';
import {
  getAccountActiveStatus,
  getTechnicalIssuesStatus,
} from 'reducers';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DisabledAccount from 'views/widgets/DisabledAccount';
import TechnicalIssuesStage from '../../../../views/widgets/TechnicalIssues';


const mapStateToProps = state => ({
  accountActive: getAccountActiveStatus(state),
  technicalIssuesStatus: getTechnicalIssuesStatus(state),
});

const Login = ({ accountActive, technicalIssuesStatus }) => {
  const clientLogo = useImportImageNewDesign('auth/login/logoCtrl.png');
  const devices = useImportImageNewDesign('auth/login/devices.png');

  if (technicalIssuesStatus) {
    return <TechnicalIssuesStage />;
  }

  if (!accountActive) {
    return <DisabledAccount />;
  }

  if (isOldHostName()) {
    redirectNewHostToLogin();
    return null;
  }

  return (
    <div className={'flex w-full min-h-screen'}>
      <div className={`flex w-full flex-col ${styles.login_wrapper}`}>
        <div className={'flex flex-col h-full justify-between'}>
          <div className={'flex flex-row w-full max-w-full'}>
            <div
              className={`box-border flex text-left align-middle mt-16 pr-16 w-1/2 justify-end ${styles.login_info}`}
            >
              <div>
                <img className={`${styles.client_logo}`} src={clientLogo} alt={'Betradar logo'} />
                <div className={`${styles.info_text}`}>
                  For information about Betradar´s Streaming products visit our
                  <a
                    style={{
                      display: 'inline',
                    }}
                    href='https://betradar.com/live-streaming/'
                  > website
                  </a>
                </div>
                <img className={`${styles.devices}`} src={devices} alt='Devices' />
              </div>
            </div>
            <div className={`${styles.border_middle}`} />
            <div className={`${styles.login_form_wrapper}`}>
              <div className={`${styles.login_text}`}>
                <strong className={`${styles.login_text_strong}`}>Live Channel</strong> Login
              </div>
              <LoginForm />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  accountActive: PropTypes.bool,
  technicalIssuesStatus: PropTypes.bool,
};

Login.defaultProps = {
  accountActive: true,
  technicalIssuesStatus: false,
};

export default connect(mapStateToProps)(Login);
