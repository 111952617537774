import React from 'react';
import style from 'styles/widgets/IssuesStage.scss';
import cssModule from 'react-css-modules';

const TechnicalIssuesStage = () => (
  <div styleName='container'>
    <div styleName='messageBoxTechnicalIssues'>
      <div styleName='message'>
        <div styleName='title'>
          Technical disruption
        </div>
        <div styleName='messageBody'>
          We apologise for the current technical disruption and the inconvenience this causes.
          We are working hard to resolve the problem as quickly as possible and thank you for your understanding.
        </div>
      </div>
    </div>
  </div>
);

export default cssModule(TechnicalIssuesStage, style);
