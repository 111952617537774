import { createReducer } from 'utils';
import {
  MARKET_PACKAGES_LOAD_SUCCESS,
  MARKET_PACKAGES_LOADING,
} from 'constants/actions';

const initialState = {
  marketPackages: [],
  isLoading: false,
};
export default createReducer(initialState, {
  [MARKET_PACKAGES_LOAD_SUCCESS]: (state, payload) => (
    {
      ...state,
      isLoading: false,
      marketPackages: payload,
    }
  ),
  [MARKET_PACKAGES_LOADING]: state => (
    {
      ...state,
      marketPackages: [],
      isLoading: true,
    }
  ),
});

export const getMarketPackages = state => state.marketPackages;

export const getMarketPackagesLoading = state => state.isLoading;
