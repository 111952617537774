import { createReducer } from 'utils';
import {
  BCMS_BETSHOP_CONFIG_LOAD_SUCCESS,
  ENQUEUE_MESSAGE,
  DEQUEUE_MESSAGE,
  SET_MESSAGE,
  CANCEL_MESSAGE,
  TOGGLE_VISIBILITY,
  VISIBILITY_ON,
  VISIBILITY_OFF,
} from 'constants/actions';
import {
  LIVE_CRAWLER_TYPE,
  MARKETING_CRAWLER_TYPE,
  IMPORTANT_CRAWLER_TYPE,
  BREAK_CRAWLER_TYPE,
} from 'constants/bcms';

const initialState = {
  ui: {
    messageVisible: false,
    currentMessage: undefined,
  },
  settings: {
    crawlerType: undefined,
    hometeam: undefined,
    awayteam: undefined,
  },
  messages: [],
  messageTemplates: {
    [BREAK_CRAWLER_TYPE]: {
      background: '#000000',
      duration: 45000,
      interval: 60000,
      iterations: 5,
      text: undefined,
      template: 'crawlermatchbreak2',
      type: BREAK_CRAWLER_TYPE,
    },
    [LIVE_CRAWLER_TYPE]: {
      background: false,
      duration: 30000,
      interval: 1000,
      iterations: 1,
      text: undefined,
      template: 'Live',
      type: LIVE_CRAWLER_TYPE,
    },
    [MARKETING_CRAWLER_TYPE]: {
      background: '#000000',
      duration: 35000,
      interval: 10000,
      iterations: 1,
      text: undefined,
      template: undefined,
      type: MARKETING_CRAWLER_TYPE,
    },
    [IMPORTANT_CRAWLER_TYPE]: {
      background: '#000000',
      duration: 60000,
      interval: 60000,
      iterations: 1,
      cancelled: false,
      text: undefined,
      template: undefined,
      matchId: -1,
      type: IMPORTANT_CRAWLER_TYPE,
    },
  },
};

// Reducer
export default createReducer(initialState, {
  [BCMS_BETSHOP_CONFIG_LOAD_SUCCESS]: (state, payload) => {
    const { crawler, ...bcmsData } = payload;

    // extract and chamel-case the props from feed
    const {
      cancelled,
      duration,
      // enabled, Needed ??
      awayteam: awayTeam,
      hometeam: homeTeam,
      interval,
      iterations,
      marketingmessage: marketingMessage,
      marketingmessageinterval: marketingMessageInterval,
      template,
      timestamp,
      matchid: matchId,
      type,
    } = crawler;
    const background = bcmsData.color[3];
    const totalTime = (
      state.messageTemplates[IMPORTANT_CRAWLER_TYPE].duration
        + state.messageTemplates[IMPORTANT_CRAWLER_TYPE].interval)
      * state.messageTemplates[IMPORTANT_CRAWLER_TYPE].iterations;
    return {
      ...state,
      settings: {
        type,
        ...state.settings,
        hometeam: homeTeam,
        awayteam: awayTeam,
      },
      messageTemplates: {
        ...state.messageTemplates,
        [LIVE_CRAWLER_TYPE]: {
          ...state.messageTemplates[LIVE_CRAWLER_TYPE],
          duration: duration * 1000,
          liveInterval: interval * 1000,
          background: `#${background}`,
        },
        [MARKETING_CRAWLER_TYPE]: {
          ...state.messageTemplates[MARKETING_CRAWLER_TYPE],
          template: marketingMessage,
          marketingInterval: marketingMessageInterval * 1000 * 60,
        },
        [IMPORTANT_CRAWLER_TYPE]: {
          ...state.messageTemplates[IMPORTANT_CRAWLER_TYPE],
          iterations: iterations === 0 ? 100 : iterations,
          template,
          cancelled,
          matchId,
          timestamp: timestamp * 1000,
          triggerTime: new Date(timestamp * 1000).toLocaleString(),
          endTime: new Date(timestamp * 1000 + totalTime).toLocaleString(),
        },
      },
    };
  },
  [ENQUEUE_MESSAGE]: (state, payload) => {
    const pQueue = [...state.messages];
    let contain = false;

    // lowest priority first in queue
    // add if priority is lower than one in the queue
    for (let i = 0; i < pQueue.length; i++) {
      if (payload.priority < pQueue[i].priority) {
        // add at index i
        pQueue.splice(i, 0, payload);
        contain = true;
        break;
      }
    }

    // add at the end of the queue
    if (!contain) {
      pQueue.push(payload);
    }

    return {
      ...state,
      messages: pQueue,
    };
  },
  [DEQUEUE_MESSAGE]: state => {
    const pQueue = [...state.messages];
    pQueue.shift();

    return {
      ...state,
      messages: pQueue,
    };
  },
  [SET_MESSAGE]: (state, payload) => ({
    ...state,
    ui: {
      ...state.ui,
      currentMessage: payload ? { ...payload } : null,
    },
  }),
  [CANCEL_MESSAGE]: state => {
    const pQueue = [...state.messages].filter(e => e.element.type !== IMPORTANT_CRAWLER_TYPE);
    return {
      ...state,
      ui: {
        ...state.ui,
        currentMessage: undefined,
      },
      messages: pQueue,
    };
  },
  [TOGGLE_VISIBILITY]: state => ({
    ...state,
    ui: {
      ...state.ui,
      messageVisible: !state.ui.messageVisible,
    },
  }),
  [VISIBILITY_ON]: state => ({
    ...state,
    ui: {
      ...state.ui,
      messageVisible: true,
    },
  }),
  [VISIBILITY_OFF]: state => ({
    ...state,
    ui: {
      ...state.ui,
      messageVisible: false,
    },
  }),
});

export const getMessage = state => (state.ui.currentMessage);
export const getFirstMessage = state => (state.messages.length ? state.messages[0] : null);

export const getMessages = state => state.messages;
export const getMessageTemplates = state => state.messageTemplates;

export const getMarketingInterval = state => (
  state.messageTemplates[MARKETING_CRAWLER_TYPE].marketingInterval
);

export const getLiveInterval = state => (
  state.messageTemplates[LIVE_CRAWLER_TYPE].liveInterval
);

export const getVisibility = state => state.ui.messageVisible;
export const getCrawlerType = state => state.settings.type;
export const getCrawlerEvent = state => (`${state.settings.hometeam} - ${state.settings.awayteam}`);
