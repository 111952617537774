import {
  put,
  select,
  fork,
  take,
  all,
  delay,
} from 'redux-saga/effects';
import {
  updateScorboardBottom,
  switchScorboardBottom,
  switchScorboardTop,
  updateScorboardTop,
} from 'actions/components';
import {
  getMatchId,
  getMatchStatus,
  getMatchTable,
  getMatchTeams,
  getMatchTournament,
  getSBCompAllSlides,
  getSBCompActiveSlides,
  getSBCompConfig,
  getMatchHomeLastMResult,
  getMatchAwayLastMResult,
} from 'reducers';
import {
  UPDATE_MATCH_INFO,
  UPDATE_TOURNAMENT_LIVETABLE,
  SCOREBOARD_BOTTOM_UPDATE_SLIDES,
  SCOREBOARD_TOP_UPDATE_SLIDES,
  UPDATE_MATCH_DELTA,
  UPDATE_STATS_TEAM_LASTX,
} from 'constants/actions';
import {
  isEnded,
} from 'utils/matchstatus';
import {
  SBB_LEAGUE_STANDINGS,
  SBB_TOURNAMENT_INFO,
  SBT_LEAGUE_RANK,
  SCOREBOARD_BOTTOM,
  SCOREBOARD_TOP,
  SBT_NATIONALITY,
  SBT_LAST_MATCHES,
  SBT_ENDED,
} from 'constants/components';
import { sagaCancel, sagaRunTakeLatest } from '../../utilSagas';

// SCOREBOARD Bottom

function* onMatchInfo() {
  const tour = yield select(getMatchTournament, yield select(getMatchId));

  yield put(updateScorboardBottom({
    slide: SBB_TOURNAMENT_INFO,
    status: !!tour,
  }));
}

function* onTable() {
  const matchId = yield select(getMatchId);
  const table = yield select(getMatchTable, matchId);
  const teams = yield select(getMatchTeams, matchId);

  yield put(updateScorboardBottom({
    slide: SBB_LEAGUE_STANDINGS,
    status: !!(table && table.length && teams),
  }));
}

// SCOREBOARD TOP

function* onMatchInfoTop() {
  const matchId = yield select(getMatchId);
  const teams = yield select(getMatchTeams, matchId);

  if (teams) {
    yield put(updateScorboardTop({
      slide: SBT_NATIONALITY,
      status: !!teams.home[0].cc && !!teams.away[0].cc,
    }));
  }
}

function* onTableTop() {
  const matchId = yield select(getMatchId);
  const table = yield select(getMatchTable, matchId);
  const teams = yield select(getMatchTeams, matchId);

  yield put(updateScorboardTop({
    slide: SBT_LEAGUE_RANK,
    status: !!(table && table.length && teams),
  }));
}


function* onTeamLastX() {
  const matchId = yield select(getMatchId);
  const home = yield select(getMatchHomeLastMResult, matchId);
  const away = yield select(getMatchAwayLastMResult, matchId);

  yield put(updateScorboardTop({
    slide: SBT_LAST_MATCHES,
    status: !!((home && home.length) && (away && away.length)),
  }));
}

export function* generalTopRow(matchId, sagas) {
  // toggles slides ready on actions
  let allOn;
  if (!sagas) {
    allOn = yield all([
      fork(sagaRunTakeLatest([UPDATE_STATS_TEAM_LASTX], onTeamLastX)),
      fork(sagaRunTakeLatest([UPDATE_MATCH_INFO, UPDATE_MATCH_DELTA], onMatchInfoTop)),
      fork(sagaRunTakeLatest([UPDATE_TOURNAMENT_LIVETABLE], onTableTop)),
    ]);
  } else {
    allOn = yield all(sagas);
  }

  yield all([
    take([SCOREBOARD_TOP_UPDATE_SLIDES]),
    delay(200), // wait a little, lastx is first and requires two requests
  ]);
  let idx = 0;
  let idxMod = 0;
  let setIdx = -1;
  let numChecked = 0;
  const config = yield select(getSBCompConfig, SCOREBOARD_TOP);

  // controls current index for top row
  for (;;) {
    const allSlides = yield select(getSBCompAllSlides, SCOREBOARD_TOP);
    const activeSlides = yield select(getSBCompActiveSlides, SCOREBOARD_TOP);
    const status = yield select(getMatchStatus, matchId);

    if (isEnded(status)) {
      yield put(updateScorboardTop({
        slide: SBT_ENDED,
        status: true,
      }));

      // cancel the sagas
      for (let i = 0; i < allOn.length; i++) {
        sagaCancel(allOn[i]);
      }
      // lock it to ended
      idxMod = SBT_ENDED; // slide has an hour duration
    } else {
      idxMod = idx % allSlides.length;
    }
    numChecked += 1;

    if (activeSlides[idxMod].active) {
      if (setIdx !== idxMod) {
        yield put(switchScorboardTop(idxMod));
        setIdx = idxMod;
      }

      yield delay(config[allSlides[idxMod]].duration);
    } else if (numChecked >= allSlides.length * 2 && setIdx === -1) {
      yield take(SCOREBOARD_TOP_UPDATE_SLIDES);
      numChecked = 0;
    }

    idx += 1;
    yield delay(100);
  }
}

export function* generalBottomRow(sagas) {
  if (!sagas) {
    yield all([
      fork(sagaRunTakeLatest([UPDATE_MATCH_INFO, UPDATE_MATCH_DELTA], onMatchInfo)),
      fork(sagaRunTakeLatest([UPDATE_TOURNAMENT_LIVETABLE], onTable)),
    ]);
  } else {
    yield all(sagas);
  }

  yield take([SCOREBOARD_BOTTOM_UPDATE_SLIDES]);

  let idx = 0;
  let idxMod = 0;
  let setIdx = -1;
  let numChecked = 0;
  const config = yield select(getSBCompConfig, SCOREBOARD_BOTTOM);

  // controls current index for bottom row
  for (;;) {
    const allSlides = yield select(getSBCompAllSlides, SCOREBOARD_BOTTOM);
    const activeSlides = yield select(getSBCompActiveSlides, SCOREBOARD_BOTTOM);
    idxMod = idx % allSlides.length;
    numChecked += 1;

    if (activeSlides[idxMod].active) {
      if (setIdx !== idxMod) {
        yield put(switchScorboardBottom(idxMod));
        setIdx = idxMod;
      }

      yield delay(config[idxMod].duration);
    } else if (numChecked >= allSlides.length && setIdx === -1) {
      yield take(SCOREBOARD_BOTTOM_UPDATE_SLIDES);
      numChecked = 0;
    }

    idx += 1;
    yield delay(100);
  }
}
