import { useEffect, useState } from 'react';

function useImportImageFacelift(filePath) {
  const [image, setImage] = useState('');

  useEffect(() => {
    let isMounted = true;
    import(`newDesign/styles/images/${filePath}`)
      .then(img => (isMounted ? setImage(img.default) : null))
      .catch(() => null);

    // eslint-disable-next-line no-return-assign
    return () => isMounted = false;
  }, [filePath]);
  return image;
}

export default useImportImageFacelift;
