import {
  put,
  select,
  apply,
  fork,
  call,
  delay,
} from 'redux-saga/effects';
import {
  getClientAlias,
  getLanguage,
  getQueryFeed,
  getTournamentId,
  getMatchStatus,
} from 'reducers';
import { TournamentLiveTableRequest } from 'fishnet-api';
import * as config from 'Config/srlive';
import { TOURNAMENT_INTERVAL } from 'constants/fishnet';
import { updateTournamentLiveTableFailed, updateTournamentLiveTable } from 'actions/fishnet/index';
import { isEnded } from 'utils/matchstatus';
import { MIN } from 'constants/app';
import { pollFishnet, takeMatchUpdate } from '../utilLCR';
import { sagaTryLog } from '../utilSagas';

function* loadTable(matchId, offset) {
  const clientAlias = yield select(getClientAlias);
  const language = yield select(getLanguage);
  const qFeed = offset > 0 ? config.fnReplayUrl : (yield select(getQueryFeed));
  const tId = yield select(getTournamentId, matchId);

  const request = new TournamentLiveTableRequest(tId, clientAlias, language, qFeed);
  const response = yield apply(request, request.get);


  if (response && !response.error) {
    const table = {
      matchId,
      table: response,
    };
    yield put(updateTournamentLiveTable(table, matchId));
  } else if (response && response.error) {
    yield put(updateTournamentLiveTableFailed(response, matchId));
    throw response;
  } else {
    throw new Error('Update tournament_livetable failed');
  }
}

// atleast once and then once after match end
function* pollTournamentLiveTable(matchId, offset) {
  yield call(takeMatchUpdate, matchId); // wait on one succesful match_info / match_timelinedelta

  // poll on interval TOURNAMENT_INTERVAL
  yield fork(
    pollFishnet,
    { pollInterval: TOURNAMENT_INTERVAL },
    loadTable,
    matchId,
    offset,
  );

  // update after match ended
  for (;;) {
    const status = yield select(getMatchStatus, matchId);

    if (isEnded(status)) {
      yield delay(1 * MIN);
      yield call(sagaTryLog(loadTable, matchId, offset));
      return;
    }

    yield call(takeMatchUpdate, matchId);
  }
}

export default pollTournamentLiveTable;
