export const sport = {
  all: 0,
  soccer: 1,
  basketball: 2,
  baseball: 3,
  ice_hockey: 4,
  tennis: 5,
  handball: 6,
  floorball: 7,
  golf: 9,
  motor_sport: 11,
  rugby: 12,
  aussie_rules: 13,
  bandy: 15,
  football: 16,
  cycling: 17,
  snooker: 19,
  table_tennis: 20,
  cricket: 21,
  darts: 22,
  volleyball: 23,
  field_hockey: 24,
  waterpolo: 26,
  futsal: 29,
  badminton: 31,
  bowls: 32,
  beach_volley: 34,
  formula_1: 40,
  softball: 54,
  beach_soccer: 60,
  pesapallo: 61,
  esoccer: 137,
  ebasketball: 153,
};

export const eSports = [
  sport.esoccer,
  sport.ebasketball,
];

export const eventType = {
  goal: 'goal',
  penalty_missed: 'penalty_missed',
  penalty_rewarded: 'penalty_rewarded',
  touchstart: 'touchstart',
  mousemove: 'mousemove',
  periodscore: 'periodscore',
  card: 'card',
  throwin: 'throwin',
  shotontarget: 'shotontarget',
  shotblocked: 'shotblocked',
  shotofftarget: 'shotofftarget',
  freekick: 'freekick',
  corner: 'corner',
  substitution: 'substitution',
  match_started: 'match_started',
  match_ended: 'match_ended',
  currentperiod: 'currentperiod',
  formation: 'formation',
  penaltyshotout: 'Penalty',
  penalty: 'penalty',
  periodstart: 'periodstart',
  injurytimeshown: 'injurytimeshown',
  ballcoordinates: 'ballcoordinates',
  possible_goal: 'possible_goal',
  interrupted: 'interrupted',
  score_change_tennis: 'score_change_tennis',
  first_serve_fault: 'first_serve_fault',
  offside: 'offside',
  three_way: '3way',
  goal_kick: 'goal_kick',
  goalkeeper_saved: 'goalkeeper_saved',
  cardred: 'cardred',
  cardyellowred: 'cardyellowred',
  cardyellow: 'cardyellow',
  matchsituation: 'matchsituation',
  unknown: 'unknown',
  _undefined: 'undefined',
  attempt_missed: 'attempt_missed',
  free_throws_awarded: 'free_throws_awarded',
  foul: 'foul',
  timeout: 'timeout',
  rebound: 'rebound',
};

export const eventStatus = {
  goal: 'goal',
  penalty: {
    goal: 'goal',
    miss: 'miss',
  },
  goalTypeId: {
    soccer_penalties: '1',
    hockey_shootout: '81',
  },
};

export const phraseIconId = {
  match_start: 3,
  ace: 4,
  double_fault: 5,
  break: 6,
  break_point: 7,
  game_end: 2,
  game_point: 8,
  set_end: 9,
  set_point: 10,
  match_end: 11,
  match_point: 12,
  comment: 13,
  no_icon: 14,
};

export const eventTypeId = {
  match_started: 10,
  game_on: 16,
  match_ended: 20,
  match_cancelled: 21,
  current_period: 22,
  second_period_start: 23,
  period_start: 24,
  first_extra_period_start: 27,
  second_extra_period_start: 28,
  penalties_start: 29,
  goal: 30,
  normal_time_score: 32,
  score_after_extra_time: 33,
  score_after_penalties: 34,
  score_after_period_1: 35,
  score_after_period_2: 36,
  score_after_period_3: 37,
  score_after_period_4: 38,
  score_after_period_5: 39,
  yellow_card: 40,
  score_1st_extra_period: 41,
  score_2nd_extra_period: 42,
  suspension: 43,
  yellow_red_card: 45,
  red_card: 50,
  player_substitution: 60,
  corner_kicks_per_team: 70,
  score_after_period_6: 71,
  score_after_period_7: 72,
  score_after_period_8: 73,
  score_after_period_9: 74,
  lineup: 80,
  team_formation: 81,
  shirt_number: 86,
  injury_time_shown: 90,
  injury_time_actually_played: 91,
  ball_possession: 110,
  free_kick_count: 120,
  goal_kick_count: 121,
  throw_in_count: 122,
  offside_count: 123,
  corner_kick_count: 124,
  shot_on_target_count: 125,
  shot_off_target_count: 126,
  save_count: 127,
  fouls_count: 129,
  tennis_aces: 130,
  tennis_double_faults: 132,
  tennis_max_points_in_a_row: 134,
  tennis_total_points_count: 136,
  tennis_breaks: 139,
  tennis_service_points: 141,
  tennis_break_points: 142,
  tennis_max_games_in_a_row: 143,
  tennis_service_games: 145,
  free_kick: 150,
  goal_kick: 151,
  throw_in: 152,
  offside: 153,
  corner_kick: 154,
  shot_on_target: 155,
  shot_off_target: 156,
  goalkeeper_save: 157,
  injury: 158,
  penalty_kick: 161,
  penalty_rewarded: 161,
  stadium: 162,
  referee: 163,
  attendance: 165,
  team_kit_home: 166,
  team_kit_away: 167,
  back_from_injury: 168,
  home_team_manager: 169,
  away_team_manager: 170,
  shot_blocked_count: 171,
  shot_blocked: 172,
  penalty_missed: 666,
  penalty_shootout_event: 1002,
  possible_corner: 1016,
  cancelled_corner: 1017,
  possible_goal: 1018,
  cancelled_goal: 1019,
  first_server: 1022,
  match_about_to_start: 1024,
  score_change_tennis: 1025,
  dangerous_attack: 1029,
  dangerous_attack_percentage: 'dangerousattackpercentage',
  ball_safe: 1030,
  ball_safe_percentage: 'ballsafepercentage',
  ball_in_play: 1031,
  tennis_service_fault: 1032,
  rebound: 1034,
  timeout: 1035,
  time_running: 1036,
  attempt_missed: 1038,
  possible_redcard: 1040,
  cancelled_redcard: 1041,
  possible_penalty: 1042,
  cancelled_penalty: 1043,
  timeout_over: 1047,
  suspension_over: 1049,
  empty_net: 1051,
  empty_net_over: 1052,
  tryscoredevent: 1154,
  goldenset_score: 1057,
  direct_foul: 1059,
  direct_free_kick: 1060,
  service_taken: 1061,
  free_throws: 1065,
  points_attempted_and_success: 1066,
  rebounds: 1067,
  seven_metres: 1068,
  free_throws_handball: 1069,
  suspensions: 1070,
  goals_powerplay: 1072,
  goals_shorthanded: 1073,
  powerplays: 1075,
  service_errors_volley: 1077,
  time_info: 1080,
  current_serve: 1081,
  possible_yellowcard: 1084,
  cancelled_yellowcard: 1085,
  yellow_card_count: 1086,
  red_card_count: 1087,
  scrumevent: 1090,
  scrumoutcomeevent: 1092,
  missedrugbypoint: 1096,
  lineout: 1097,
  timeouts: 1098,
  penalty_count: 1099,
  direct_foul_count: 1100,
  direct_fouls_first_period: 'directfoulsfirst',
  direct_fouls_second_period: 'directfoulssecond',
  direct_freekick_count: 1101,
  match_situation: 1103,
  penaltyshootoutstartingteam: 1104,
  toilet_break: 1110,
  toilet_break_finished: 1111,
  stop_match: 1112,
  attack: 1126,
  attack_percentage: 'attackpercentage',
  ball_coordinates: 1140,
  ballrecycledevent: 1143,
  scrumresetevent: 1146,
  lineoutwon: 1147,
  ballkicked: 1144,
  twentytwodropoutevent: 1151,
  trygoalscored: 1154,
  penaltytryscored: 1155,
  conversionscored: 1156,
  penaltygoalscored: 1157,
  dropgoalscored: 1158,
  turnoverevent: 1162,
  players_warming_up: 1180,
  players_on_pitch: 1181,
  fouls_count_period: 1182,
  penaltyadvantage: 1196,
  receiver_points: 1410,
  will_throw_first_dart: 1022,
  players_walk_on: 1179,
  darts_score_change: 1130,
  darts_match_status: 1013,
  single_dart_throw: 1132,
  player_won_bull: 1178,
  biggest_lead: 1619,
  two_pointers: 'twopointers',
  three_pointers: 'threepointers',
  goalattempts: 'goalattempts',
};

export const eventTime = {
  not_given: -1,
  halftime: 45,
  awaiting_extra_time: 90,
  extra_time_halftime: 105,
  awaiting_penalties: 120,
  first_intermission: 20,
  second_intermission: 40,
};

export const matchStatusIds = {
  not_started: 0,
  first_period: 1,
  second_period: 2,
  third_period: 3,
  fourth_period: 4,
  fifth_period: 5,
  first_half: 6,
  second_half: 7,
  first_set: 8,
  second_set: 9,
  third_set: 10,
  fourth_set: 11,
  fifth_set: 12,
  first_quarter: 13,
  second_quarter: 14,
  third_quarter: 15,
  fourth_quarter: 16,
  golden_set: 17,
  started: 20,
  in_progress: 21,
  match_about_to_start: 22,
  pause: 30,
  halftime: 31,
  awaiting_extra_time: 32,
  extra_time_halftime: 33,
  awaiting_penalties: 34,
  awaiting_penalties_hockey: 35,
  overtime: 40,
  first_extra: 41,
  second_extra: 42,
  penalties: 50,
  penalties_hockey: 52,
  postponed: 60,
  start_delayed: 61,
  cancelled: 70,
  interrupted: 80,
  suspended: 81,
  abandoned: 90,
  walkover: 91,
  retired: 92,
  walkover_player_1_won: 93,
  walkover_player_2_won: 94,
  player_1_retired_player_2_won: 95,
  player_2_retired_player_1_won: 96,
  only_result: 99,
  ended: 100,
  AET: 110,
  AP: 120,
  AP_handball: 123,
  AP_hockey: 125,
  AGS: 130,
  first_break: 301,
  second_break: 302,
  third_break: 303,
  fourth_break: 304,
};

export const matchStatus = {
  live: 'live',
  result: 'result',
  recentlyended: 'recentlyended',
  upcoming: 'upcoming',
  gamescore_service_home: '1',
  gamescore_service_away: '2',
  pre: 'pre',
  post: 'post',
};

export const period = {
  p1: 'p1',
  p2: 'p2',
  p3: 'p3',
  p4: 'p4',
  p5: 'p5',
};

export const periodId = {
  overtime: 10,
  penalties: 20,
  interrupted: 40,
  abandoned: 45,
  suspended: 50,
};

export const team = {
  home: 'home',
  away: 'away',
};

export const card = {
  red: 'red',
  yellow: 'yellow',
  yellowred: 'yellowred',
};

export const situation = {
  attack: 'attack',
  dangerous: 'dangerous',
};

export const oddsname = {
  three_way: '3way',
};

export const tennisTournamentType = {
  singles: 'singles',
  doubles: 'doubles',
  mixed: 'mixed',
};

export const tennisTournamentTypeId = {
  singles: 1,
  doubles: 2,
  mixed: 3, // TODO just guessing, needs to be checked
};

export const tennisGender = {
  men: 'men',
  women: 'women',
};

export const tennisGenderId = {
  men: 0,
  women: 1,
};

export const gameStatus = {
  bet: 1,
  delay: 2,
  spin: 3,
  result: 4,
};

export const outcomeIds = {
  spin_cancelled_away: -3,
  review_result_away: -2,
  possible_result_away: -1,
  yellow_card_home: 1,
  penalty_home: 2,
  penalty_away: 3,
  yellow_card_away: 4,
  corner_away: 5,
  throw_in_home: 6,
  offside_home: 7,
  corner_home: 8,
  throw_in_away: 9,
  offside_away: 10,
  goal_home: 11,
  goal_away: 12,
  freekick_home: 13,
  goalkicks_home: 14,
  red_card_away: 15,
  freekick_away: 16,
  goalkicks_away: 17,
  red_card_home: 18,
};

export const status = {
  all: 'all',
  live: 'live',
  result: 'result',
  upcoming: 'upcoming',
  next: 'next',
  ended: 'Ended',
};

export const phraseTypeId = {
  goal_scored_and_team_lead: 1,
  result_after_normal_time: 9,
  official_lineups_presented: 13,
  lineups_presented_add_info_needed: 19,
  pre_match_commentary: 48,
  post_match_commentary: 49,
  pre_match_commentary_with_customer_logo_as_icon: 101,
  post_match_commentary_with_customer_logo_as_icon: 102,
};


export const oddsT = {
  pre: {
    two_way: '1',
    three_way: '2',
    total: '3',
    spread: '4',
    handicap: '5',
    outright: '6',
    correct_score: '22',
  },
  live: {
    restofthematch: '9',
    nextgoal: '10',
    draw_no_bet: '11',
    win_tennis_match: '13',
    win_tennis_set: '14',
    win_tennis_game: '15',
    to_score_goal_x: '16',
    three_way_live: '17',
    total: '18',
    two_way_live: '19',
    double_chance: '20',
    halftime_1x2: '21', // this one is also three_way
    win_current_period: '23',
    two_way_live_ot: '24',
    total_incl_ot: '25',
    race_to_points: '26',
    asian_handicap: '27',
    asian_handicap_ot: '28',
    tennis_results_3_sets: '29',
    tennis_results_5_sets: '30',
    tennis_number_of_sets: '31',
    total_current_round: '32',
    ht_who_wins_the_rest: '33',
    correct_score_live: '52',
    win_penalties: '55',
    asian_handicap_first_half: '57',
    total_live_only_ot: '59',
    total_games_in_set: '61',
    total_games_in_match: '63',
    handicap_current_period: '65',
    who_scores_point: '67',
    total_first_half: '71',
    two_way_fist_half: '73',
    handicap_first_half: '75',
    three_way_only_ot: '77',
    rest_of_match_only_ot: '79',
    next_goal_only_ot: '81',
    handicap: '85',
    rest_of_period: '86',
    win_games_of_set: '88',
    set_handicap: '108',
    first_team_to_score: '110',
    over_under_hometeam: '114',
    over_under_awayteam: '116',
    quarter_two_way: '117',
    tiebreak_in_set: '122',
    goals_awayteam: '124',
    goals_hometeam: '126',
    goal_nogoal: '128',
    next_goal_first_half: '132',
    correct_set_score: '156',
    to_score_goal_x_first_half: '161',
  },
};
export const preMatchMarkets = [
  oddsT.pre.three_way,
  oddsT.pre.two_way,
  oddsT.pre.total,
  oddsT.pre.spread,
  oddsT.pre.handicap,
  oddsT.pre.outright,
  oddsT.pre.correct_score,
];

export const totalMarkets = [
  oddsT.pre.total,
  oddsT.live.total_incl_ot,
  oddsT.live.total,
  oddsT.live.tennis_number_of_sets,
  oddsT.live.total_first_half,
  oddsT.live.total_current_round,
  oddsT.live.total_games_in_set,
  oddsT.live.total_games_in_match,
  oddsT.live.over_under_hometeam,
  oddsT.live.over_under_awayteam,
];
export const overUnderMarkets = [
  oddsT.pre.total,
  oddsT.live.total_incl_ot,
  oddsT.live.total,
  oddsT.live.tennis_number_of_sets,
  oddsT.live.total_first_half,
  oddsT.live.total_current_round,
  oddsT.live.total_games_in_set,
  oddsT.live.total_games_in_match,
  oddsT.live.over_under_hometeam,
  oddsT.live.over_under_awayteam,
];

export const spreadMarkets = [
  oddsT.pre.spread,
  oddsT.live.asian_handicap,
  oddsT.live.asian_handicap_first_half,
  oddsT.live.handicap_current_period,
  oddsT.live.asian_handicap_ot,
];

export const handicapMarkets = [
  oddsT.live.handicap_first_half,
  oddsT.live.handicap,
];

export const twoOrThreeWay = [
  oddsT.live.win_tennis_match,
  oddsT.pre.three_way,
  oddsT.pre.two_way,
  oddsT.live.three_way_live,
  oddsT.live.two_way_live,

];

export const replaceOneVarMarket = [
  oddsT.live.win_tennis_set,
  oddsT.live.win_tennis_game,
  oddsT.live.who_scores_point,
  oddsT.live.race_to_points,
  oddsT.live.two_way_live,
  oddsT.live.total_current_round,
  oddsT.live.handicap_current_period,
  oddsT.live.win_current_period,
];

export const specialMarkets = [
  oddsT.live.win_tennis_set,
  oddsT.live.win_tennis_game,
  oddsT.live.win_games_of_set,
  oddsT.live.race_to_points,
  oddsT.live.who_scores_point,
];

export const oddsTypeRanking = {
  [sport.all]: [
    oddsT.pre.three_way,
    oddsT.pre.two_way,
    oddsT.pre.spread,
    oddsT.pre.total,
    oddsT.pre.handicap,
    oddsT.live.three_way_live,
    oddsT.live.two_way_live,
    oddsT.live.total,
    oddsT.live.asian_handicap,
    oddsT.live.handicap,
  ],
  [sport.soccer]: [
    oddsT.pre.three_way,
    oddsT.pre.two_way,
    oddsT.pre.spread,
    oddsT.pre.total,
    oddsT.pre.handicap,
    oddsT.pre.correct_score,
    oddsT.live.three_way_live,
    oddsT.live.restofthematch,
    oddsT.live.total,
    oddsT.live.total_first_half,
    oddsT.live.handicap,
    oddsT.live.nextgoal,
    oddsT.live.correct_score_live,
    oddsT.live.ht_who_wins_the_rest,
    oddsT.live.halftime_1x2,
    oddsT.live.asian_handicap,
    oddsT.live.asian_handicap_first_half,
    oddsT.live.draw_no_bet,
    oddsT.live.double_chance,
    oddsT.live.goal_nogoal,
  ],
  [sport.tennis]: [
    oddsT.pre.two_way,
    oddsT.pre.total,
    oddsT.pre.handicap,
    oddsT.pre.correct_score,
    oddsT.live.win_tennis_match,
    oddsT.live.win_tennis_set,
    oddsT.live.win_tennis_game,
    oddsT.live.total_current_round,
    oddsT.live.total_games_in_match,
    oddsT.live.tennis_number_of_sets,
    oddsT.live.win_games_of_set,
    oddsT.live.total,
  ],
  [sport.basketball]: [
    oddsT.pre.two_way,
    oddsT.pre.spread,
    oddsT.pre.total,
    oddsT.live.two_way_live,
    oddsT.live.three_way_live,
    oddsT.live.asian_handicap,
    oddsT.live.total,
    oddsT.live.total_current_round,
    oddsT.live.handicap_current_period,
    oddsT.live.total_first_half,
    oddsT.live.two_way_fist_half,
    oddsT.live.quarter_two_way,
    oddsT.live.race_to_points,
    oddsT.live.who_scores_point,
  ],
  [sport.volleyball]: [
    oddsT.pre.two_way,
    oddsT.pre.spread,
    oddsT.pre.total,
    oddsT.live.two_way_live,
    oddsT.live.win_current_period,
    oddsT.live.quarter_two_way,
    oddsT.live.handicap_current_period,
    oddsT.live.total_current_round,
    oddsT.live.asian_handicap,
    oddsT.live.total,
    oddsT.live.race_to_points,
    oddsT.live.who_scores_point,
  ],
  [sport.ice_hockey]: [
    oddsT.pre.three_way,
    oddsT.pre.spread,
    oddsT.pre.total,
    oddsT.live.three_way_live,
    oddsT.live.nextgoal,
    oddsT.live.total,
    oddsT.live.total_current_round,
    oddsT.live.two_way_live,
    oddsT.live.asian_handicap,
    oddsT.live.restofthematch,
    oddsT.live.draw_no_bet,
    oddsT.live.double_chance,
    oddsT.live.handicap,
  ],
  [sport.handball]: [
    oddsT.pre.three_way,
    oddsT.pre.spread,
    oddsT.pre.total,
    oddsT.live.three_way_live,
    oddsT.live.handicap,
    oddsT.live.total,
    oddsT.live.total_current_round,
    oddsT.live.handicap_first_half,
    oddsT.live.halftime_1x2,
    oddsT.live.asian_handicap,
    oddsT.live.race_to_points,
  ],
  [sport.table_tennis]: [
    oddsT.pre.two_way,
    oddsT.live.two_way_live,
    oddsT.live.total,
    oddsT.live.asian_handicap,
    oddsT.live.tennis_number_of_sets,
    oddsT.live.win_current_period,
    oddsT.live.race_to_points,
    oddsT.live.tennis_results_5_sets,
    oddsT.live.total_current_round,
    oddsT.live.handicap_current_period,
    oddsT.live.who_scores_point,

    // not sure if used
    oddsT.live.win_tennis_set,
    oddsT.live.win_tennis_game,
    oddsT.live.total_games_in_match,
    oddsT.live.win_games_of_set,
  ],

  [sport.esoccer]: [
    oddsT.pre.three_way,
    oddsT.pre.two_way,
    oddsT.pre.spread,
    oddsT.pre.total,
    oddsT.pre.handicap,
    oddsT.pre.correct_score,
    oddsT.live.three_way_live,
    oddsT.live.restofthematch,
    oddsT.live.total,
    oddsT.live.total_first_half,
    oddsT.live.handicap,
    oddsT.live.nextgoal,
    oddsT.live.correct_score_live,
    oddsT.live.ht_who_wins_the_rest,
    oddsT.live.halftime_1x2,
    oddsT.live.asian_handicap,
    oddsT.live.asian_handicap_first_half,
    oddsT.live.draw_no_bet,
    oddsT.live.double_chance,
    oddsT.live.goal_nogoal,
  ],
  [sport.ebasketball]: [
    oddsT.pre.two_way,
    oddsT.pre.spread,
    oddsT.pre.total,
    oddsT.live.two_way_live,
    oddsT.live.three_way_live,
    oddsT.live.asian_handicap,
    oddsT.live.total,
    oddsT.live.total_current_round,
    oddsT.live.handicap_current_period,
    oddsT.live.total_first_half,
    oddsT.live.two_way_fist_half,
    oddsT.live.quarter_two_way,
    oddsT.live.race_to_points,
    oddsT.live.who_scores_point,
  ],
};

export const allOddsTypes = Object.values(oddsT).reduce(
  (accu, cur) => accu.concat(Object.values(cur)),
  [],
);

export const sportTermsMap = {
  [sport.tennis]: {
    period_1: 'set1',
    period_2: 'set2',
    period_3: 'set3',
    period_4: 'set4',
    period_5: 'set5',
  },
  [sport.beach_volley]: {
    period_1: 'set1',
    period_2: 'set2',
    period_3: 'set3',
    period_4: 'set4',
    period_5: 'set5',
  },
  [sport.volleyball]: {
    period_1: 'set1',
    period_2: 'set2',
    period_3: 'set3',
    period_4: 'set4',
    period_5: 'set5',
  },
  [sport.soccer]: {
    period_1: '1st_half',
    period_2: '2nd_half',
  },
  [sport.handball]: {
    period_1: '1st_half',
    period_2: '2nd_half',
  },
  [sport.basketball]: {
    period_1: 'q1',
    period_2: 'q2',
    period_3: 'q3',
    period_4: 'q4',
  },
};


export const tennisGround = {
  all: 'Overall',
  clay: 'Clay',
  hardcourt: 'Hardcourt',
  indoor: 'Indoor',
  grass: 'Grass',
};

export const tennisGroundId = {
  all: 0,
  clay: 1,
  hardcourt: 2,
  indoor: 3,
  grass: 4,
};

export const tennisGroundMap = {
  '0': 'all',
  '1': 'clay',
  '2': 'hardcourt',
  '3': 'hardcourt',
  '4': 'grass',
};

export const scoutCoverageStatus = {
  covered: 0,
  notCovered: 1,
  coverageAbandoned: 2,
};

export const lmtSupport = {
  noCoverage: 1,
  statusUpdate: 2,
  basicCoverage: 3,
  deepCoverage: 4,
};

export const scoutmatch = {
  notScouted: 0,
  scouted: 1,
  liveOdds: 2,
};

export const seasonType = {
  group_stage: 21,
  qualification: 16, // qualification playoff
  cup: 26, // playoff
};

export const roundnameType = {
  cup: 'cupround',
  table: 'tableround',
};

export const cuproundIds = {
  round_1: 7,
  round_2: 8,
  round_3: 9,
  round_4: 10,
  round_5: 11,
  round_6: 12,
  round_7: 36,
  round_8: 37,
  round_9: 38,
  R64: 6, // aka 1/32
  R32: 5, // aka 1/16
  R16: 4, // aka 1/8
  quarterfinals: 3,
  semifinals: 2,
  finals: 1,
  qualification_round_1: 14,
  qualification_round_2: 15,
  qualification_round_3: 16,
  qualification_round_4: 17,

  // placement
  placement_for_2nd: 49,
  placement_for_3rd: 50,
  placement_for_bronze: 20, // yes, there are "3rd" and "bronze"...
  placement_for_4th: 51,
  placement_for_5th: 31,
  placement_for_7th: 32,
  placement_for_9th: 33,
  placement_for_11th: 35,
  placement_for_13th: 34, // 13th is 34 and 11th is 35 - this is not typo!
  placement_for_15th: 39,
  placement_for_17th: 40,
  placement_for_19th: 41,
  placement_for_21st: 42,
  placement_for_23rd: 43,
  placement_qualification_match: 48,

  // tennis specific rounds
  tennis_R256: 22, // aka 1/128
  tennis_R128: 23, // aka 1/64
  tennis_R64: 24, // aka 1/32
  tennis_R32: 25, // aka 1/16
  tennis_R16: 26, // aka 1/8
  tennis_quarterfinals: 27,
  tennis_semifinals: 28,
  tennis_finals: 29,
  tennis_placement_for_bronze: 53,

  // some "special" tennis qualification rounds (based on number of participants??)
  tennis_qualification_48_participants: 44,
  tennis_qualification_24_participants: 45,
  tennis_qualification_28_participants: 46,
  tennis_qualification_96_participants: 47,
  tennis_qualification_32_participants: 54,
};

export const weather = {
  unknown: 0,
  good: 1,
  medium: 2,
  bad: 3,
  indoor: 4,
  extreme: 5,
};

export const pitchCondition = {
  unknown: 0,
  good: 1,
  medium: 2,
  bad: 3,
};

export const playerRoleType = {
  player: 1,
  referee: 2,
  manager: 3,
  club_official_FR: 4,
  assistant_coach: 5,
  unemployed: 6,
  in_jail: 7,
  on_loan: 8,
  career_ended: 9,
  uefa_referee_observer: 10,
  uefa_delegate: 11,
  agent: 12,
  owner: 13,
  president: 14,
  general_manager: 15,
  team_manager: 16,
  goalkeeper_coach: 17,
  main_sponsor: 18,
  team_doctor: 19,
  scout: 20,
  fitness_coach: 21,
  team_captain: 22,
  other: 23,
  chairman: 24,
  coach_for_technique: 25,
  director_of_football: 26,
  assistant_director_of_football: 27,
  director_of_finances: 28,
  vice_president: 29,
  board_Member: 30,
  secretary_general: 31,
  sponsor: 32,
  academy_leader: 33,
  player_coordinator: 34,
  secretary_handball: 35,
  team_official_handball: 36,
  match_supervisor_handball: 37,
  responsible_for_team_handball: 38,
  timekeeper_handball_Futsal: 39,
  match_commissioner: 40,
  '5th_official': 41,
  mF: 43,
};

export const setSportsPointType = {
  ace: '1',
  serviceError: '2',
};

export const rugbyPointType = {
  conversion: 3,
  penalty: 4,
  drop: 5,
};

export const uniqueTournament = {
  NBA: 132,
  MLS: 18,
};

export const tennisPointFlag = {
  _break: 'Break',
  break_point: 'Break point',
  game: 'Game',
  game_point: 'Game point',
  set: 'Set',
  set_point: 'Set point',
  match: 'Match',
  match_point: 'Match point',
  empty: '',
};

export const dartThrowSegment = {
  'unspecified': '-1',
  'miss': '0',
  'fat': '10',
  'thin': '11',
  'double': '2',
  'treble': '3',
  'outer_bull': '4',
  'bull': '5',
  'bounceout': '6',
  'null': '7',
};

export const dartsScoreChangeTypeId = {
  regular_points: 0, // changed points in leg (example: from 501 points to 431 points after throwing 70)
  leg_score: 1,
  set_score: 2,
  match_result: 3, // occurs only once per match, when a player wins
};

export const jerseyType = {
  'short_sleeve': 1,
  'long_sleeve': 2,
  'no_sleeve': 3,
};

export const jerseyStripesType = {
  'plain': 0,
  'vertical_stripes': 1,
  'horizontal_stripes': 2,
  'split': 3,
  'squares': 4,
};

export const resultStatus = {
  'win': 1,
  'draw': 0,
  'lose': -1,
  'overtimeWin': 2,
  'overtimeLose': -2,
};
