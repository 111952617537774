/*
 * action types
 */
import {
  STREAM_STOPPED_PLAYING,
  STREAM_IS_PLAYING,
  PLAYER_LOADING,
  PLAYER_LOAD_ERROR,
  PLAYER_LOAD_SUCCESS,
  PLAYER_AUTOPLAY_BLOCKED,
} from 'constants/actions';

/*
 * action creators
 */
export function streamStoppedPlaying() {
  return { type: STREAM_STOPPED_PLAYING };
}

export function streamIsPlaying() {
  return { type: STREAM_IS_PLAYING };
}

export function playerLoading() {
  return { type: PLAYER_LOADING };
}

export function playerLoadSuccess() {
  return { type: PLAYER_LOAD_SUCCESS };
}

export function playerLoadError(err) {
  return { type: PLAYER_LOAD_ERROR, payload: err };
}

export function playerAutoplayBlocked() {
  return { type: PLAYER_AUTOPLAY_BLOCKED };
}
