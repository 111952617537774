const stagingUrl = 'https://lbc-staging.sportradar.ag';
const prodUrl = 'https://lbc.betradar.com';

export default {
  baseUrl: prodUrl,
  stagingUrl,
  prodUrl,
  baseUrlDefault: prodUrl,
  NODE_ENV: process.env.NODE_ENV,
  baseStreamingFeeds: 'https://lcr.betradar.com',

  streamingFeedsURL: '/feeds/index.php',
  clientLogoUrl: 'https://lbc.betradar.com/img/client/logo.php?id=:clientId',
  lcrClientLogoUrl: 'https://lcr-cms-prod.s3.eu-central-1.amazonaws.com/client_:clientId_logo',
  betshopLogoUrl: 'https://lbc.betradar.com/img/betshop/logo.php?id=:betshopId',
  lcrBetshopLogoUrl: 'https://lcr-cms-prod.s3.eu-central-1.amazonaws.com/client_:clientId_betshop_:betshopId_logo',
  streamUrl: '/streaming/stream-url/',
  lcrStreamUrl: '/stream/std-stream',
  worldLotteryUrl: '/streaming/dash-url-wl/',
  lcrWorldLotteryUrl: '/stream/wns-stream',
  debugPlayerUrl: 'https://avplayer-cdn.akamaized.net/avvpl-debug-player?streamUrl=:url&type=:format',
  authorizationSettings: {
    pollInterval: 60,
    url: '/streaming/is-authorized/index.php',
  },
  liveplayerLogLevel: 2,
  lcrUrl: 'https://lcr.sportradar.com',
};
