// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Login__login_wrapper___3LYYJ{background:#f9f9f9;border-top:1px solid #eee;color:#000}.Login__login_info___1YXAM{border-right:1px solid #d8d8d8}.Login__client_logo___1t93U{width:400px;padding-left:10px}.Login__info_text___1hmTP{height:53px;color:#000;font-family:Arial;font-size:16px;line-height:18px;margin-top:5%;padding-left:10px}.Login__devices___2zItK{display:-ms-flexbox;display:flex;-ms-flex-pack:end;justify-content:flex-end;width:520px}.Login__border_middle___cizYs{-ms-flex:0 0 auto;flex:0 0 auto;-ms-flex-pack:center;justify-content:center}.Login__login_form_wrapper___3X_jO{box-sizing:border-box;display:inline-block;text-align:left;vertical-align:middle;padding-right:0;padding-left:60px;margin-top:60px;width:50%}.Login__login_text___2FiLN{font-family:Arial;color:#172b4d;font-size:28px;padding-bottom:30px}.Login__login_text_strong___3G-aR{font-family:Arial;color:#172b4d;font-size:28px;font-weight:bold}", ""]);
// Exports
exports.locals = {
	"login_wrapper": "Login__login_wrapper___3LYYJ",
	"login_info": "Login__login_info___1YXAM",
	"client_logo": "Login__client_logo___1t93U",
	"info_text": "Login__info_text___1hmTP",
	"devices": "Login__devices___2zItK",
	"border_middle": "Login__border_middle___cizYs",
	"login_form_wrapper": "Login__login_form_wrapper___3X_jO",
	"login_text": "Login__login_text___2FiLN",
	"login_text_strong": "Login__login_text_strong___3G-aR"
};
module.exports = exports;
