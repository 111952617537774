/*
 * action types
 */

import {
  SCOREBOARD_BOTTOM_SWITCH_SLIDE,
  SCOREBOARD_BOTTOM_UPDATE_SLIDES,
  SCOREBOARD_TOP_UPDATE_SLIDES,
  SCOREBOARD_TOP_SWITCH_SLIDE,
  SCOREBOARD_START,
  SCOREBOARD_STOP,
  STATSLIDES_SWITCH_SLIDE,
  STATSLIDES_UPDATE_SLIDES,
  BETTING_SUGGESTION_EVENT,
  CLEAR_BETTING_SUGGESTIONS,
  SHOW_BETTING_SUGGESTION_EVENT,
  HIDE_BETTING_SUGGESTION_EVENT,
  INITIAL_WAIT_OVER,
} from 'constants/actions';

/*
 * action creators
 */

export function startScoreboard() {
  return {
    type: SCOREBOARD_START,
  };
}

export function stopScoreboard() {
  return {
    type: SCOREBOARD_STOP,
  };
}

export function switchScorboardBottom(idx) {
  return {
    type: SCOREBOARD_BOTTOM_SWITCH_SLIDE,
    payload: idx,
  };
}

export function updateScorboardBottom(slides) {
  return {
    type: SCOREBOARD_BOTTOM_UPDATE_SLIDES,
    payload: slides,
  };
}

export function switchScorboardTop(idx) {
  return {
    type: SCOREBOARD_TOP_SWITCH_SLIDE,
    payload: idx,
  };
}

export function updateScorboardTop(slides) {
  return {
    type: SCOREBOARD_TOP_UPDATE_SLIDES,
    payload: slides,
  };
}

export function switchStatSlide(idx) {
  return {
    type: STATSLIDES_SWITCH_SLIDE,
    payload: idx,
  };
}

export function updateStatSlide(slides) {
  return {
    type: STATSLIDES_UPDATE_SLIDES,
    payload: slides,
  };
}

export function newBettingSuggestionEvent(event) {
  return {
    type: BETTING_SUGGESTION_EVENT,
    payload: event,
  };
}

export function showLatestBettingSuggestionEvent() {
  return {
    type: SHOW_BETTING_SUGGESTION_EVENT,
  };
}

export function hideLatestBettingSuggestionEvent() {
  return {
    type: HIDE_BETTING_SUGGESTION_EVENT,
  };
}

export function clearBettingSuggestionEvents() {
  return {
    type: CLEAR_BETTING_SUGGESTIONS,
  };
}

export function initialWaitOverBS() {
  return {
    type: INITIAL_WAIT_OVER,
  };
}
