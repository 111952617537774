import {
  call,
  put,
} from 'redux-saga/effects';
import {
  sirLoading,
  sirLoadSuccess,
  sirLoadError,
} from 'actions/sir';
import loadScript from 'load-script';
import * as config from 'Config/sir';


const fetchScript = url => new Promise((resolve, reject) => loadScript(url, {
  attrs: {
    // 'data-sr-theme': false,
    // 'data-sr-language': 'es',
  },
}, err => {
  if (err) {
    reject(err);
  }
  resolve();
}));


function* loadSir() {
  yield put(sirLoading());
  try {
    yield call(fetchScript, config.sirWidgetloaderUrl);
    yield put(sirLoadSuccess());
  } catch (err) {
    yield put(sirLoadError('Loading SIR failed: ' + err.message));
    throw err;
  }
}

export default loadSir;
