import { lcrUrl } from 'Config/app';
import { convertToInt } from './helpers';

export const isOldHostName = () => {
  const { hostname } = window.location;

  return ['avlcr-staging.sportradar.ag', 'avlcr.betradar.com'].includes(hostname);
};

export const isNewHostName = () => (
  !isOldHostName()
);

export const getUserIdFromUrl = () => {
  const regex = /client\/(\d+)/;
  const regexMatch = window.location.hash.match(regex);

  if (regexMatch) {
    const id = regexMatch[1];
    return convertToInt(id);
  }

  return null;
};

export const redirectNewHostToClient = userId => {
  window.location.href = `${lcrUrl}/#/client/${userId}`;
  window.location.reload(true);
};

export const redirectCurrentHostToClient = userId => {
  window.location.href = `/#/client/${userId}`;
};

export const redirectNewHostToLogin = () => {
  window.location.href = `${lcrUrl}/#/login`;
};

export const redirectToLogin = () => {
  window.location.href = '/#/login';
  window.location.reload(true);
};
export const redirectToForgotPassword = () => {
  window.location.href = `${lcrUrl}/#/forgot-password`;
};

export const redirectToLoginByToken = token => {
  window.location.href = `${lcrUrl}/auth/migrate?token=${token}`;
};

export const redirectToNewHostWithSameRoute = () => {
  window.location.href = `${lcrUrl}` + window.location.pathname + window.location.hash;
};
