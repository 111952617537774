// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/icomoon.eot?nsnbf2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/icomoon.eot");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/icomoon.woff?nsnbf2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./fonts/icomoon.ttf?nsnbf2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./fonts/icomoon.svg?nsnbf2");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:200,300,400,400italic,500,500italic,700,700italic,900&subset=latin,cyrillic-ext,latin-ext);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,300,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___, { hash: "?#iefixnsnbf2" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "#icomoon" });
// Module
exports.push([module.id, "html{min-width:0px !important;max-width:100%;height:100vh}body,.app__app___EVpmm{margin:0;padding:0;display:-ms-flexbox;display:flex;-ms-flex:1;flex:1;max-height:100vh;max-width:100vw;overflow:hidden}body{background:#000002;color:#fff;font-family:Roboto,Helvetica,Arial,sans-serif;font-size:14px;line-height:1.42857;height:100%}@font-face{font-family:\"icomoon\";src:local(\"icomoon\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"embedded-opentype\");src:local(\"icomoon\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"svg\");font-weight:normal;font-style:normal}", ""]);
// Exports
exports.locals = {
	"app": "app__app___EVpmm"
};
module.exports = exports;
