/* eslint-disable max-classes-per-file */
/* eslint no-console: ["error", {
  allow: ["warn", "error", "log", "groupCollapsed", "groupEnd", "group"]
}] */
import {
  DISPLAY_ERROR, DISPLAY_INFO, CONSOLE_LOG, DEBUG_LOG, ERROR_LOG, API_ERROR,
} from 'constants/bcms';

class LoggerItem {
  constructor(title, message, level, type) {
    const d = new Date();
    this.title = `[${d.toLocaleString()}] ${title}`;
    this.message = message;
    this.type = type;
    this.level = level;
    this.active = true;
    this.id = Math.trunc((Math.random() * 100000000));
    this.timestamp = d.getTime();

    if (type === CONSOLE_LOG) {
      this.generateConsoleMessage();
    } else if (type === DEBUG_LOG && process.env.NODE_ENV !== 'production' && level > 0) {
      this.generateConsoleMessage();
    } else if (type === ERROR_LOG) {
      this.generateErrorMessage();
    }
  }

  generateMessage() {
    if (this.error) {
      console.error(this.title);
      if (this.message) {
        console.error(`%c${this.message}`, 'padding: 1px 10px;');
      }
    } else {
      console.log(this.title);
      if (this.message) {
        console.log(`%c${this.message}`, 'padding: 1px 10px;');
      }
    }
  }

  generateConsoleMessage() {
    console.log(this.title);
    if (this.message) {
      console.log(`%c${this.message}`, 'padding: 1px 10px;');
    }
  }

  generateErrorMessage() {
    console.error(this.title);
    if (this.message) {
      console.error(`%c${this.message}`, 'padding: 1px 10px;');
    }
  }
}

export class DisplayError extends LoggerItem {
  constructor(title, message, level = 0) {
    super(title, message, level, DISPLAY_ERROR);
    this.error = true;
  }
}

export class InfoLog extends LoggerItem {
  constructor(title, message, level = 0) {
    super(title, message, level, DISPLAY_INFO);
  }
}

export class ConsoleLog extends LoggerItem {
  constructor(title, message, level = 0) {
    super(title, message, level, CONSOLE_LOG);
  }
}
export class DebugLog extends LoggerItem {
  constructor(title, message, level = 1) {
    super(title, message, level, DEBUG_LOG);
  }
}

export class ErrorLog extends LoggerItem {
  constructor(title, message, level = 0) {
    super(title, message, level, ERROR_LOG);
    this.error = true;
  }
}

export class APIError extends LoggerItem {
  constructor(title, message, fishnetException = undefined, level = 0) {
    super(title, message, level, API_ERROR);

    this.error = true;
    this.fishnetException = fishnetException;
  }
}
