import {
  put,
  select,
  apply,
  fork,
  call,
  delay,
} from 'redux-saga/effects';
import {
  getClientAlias,
  getLanguage,
  getQueryFeed,
  getMatchStatus,
} from 'reducers';
import { MatchDetailsRequest } from 'fishnet-api';
import * as config from 'Config/srlive';
import { PREMATCH_MATCH_DETAILS_POLL_INTERVAL, LIVE_MATCH_DETAILS_POLL_INTERVAL } from 'constants/fishnet';
import { updateMatchDetails, updateMatchDetailsFailed } from 'actions/fishnet/index';
import { sagaCancel, sagaRunning } from '../utilSagas';
import { isNotStarted, isLive, isEnded } from '../../utils/matchstatus';
import { pollFishnet, pollTillResponse, takeMatchUpdate } from '../utilLCR';

function* loadMD(matchId, offset) {
  const clientAlias = yield select(getClientAlias);
  const language = yield select(getLanguage);
  const qFeed = offset > 0 ? config.fnReplayUrl : (yield select(getQueryFeed));

  const request = new MatchDetailsRequest(matchId, clientAlias, language, qFeed, offset);
  const response = yield apply(request, request.get);

  if (response && !response.error) {
    yield put(updateMatchDetails(response, matchId));
  } else if (response && response.error) {
    yield put(updateMatchDetailsFailed(response, matchId));
    throw response;
  } else {
    throw new Error('Update match_details failed');
  }
}

// atleast once, different pre and live interval
function* pollMatchDetails(matchId, offset) {
  let status;
  let taskPre;
  let taskLive;

  yield call(pollTillResponse, { logLevel: 5 }, loadMD, matchId, offset); // atleast poll once

  while (true) {
    status = yield select(getMatchStatus, matchId);

    if (!sagaRunning(taskPre) && isNotStarted(status)) {
      // poll using prematch interval
      taskPre = yield fork(
        pollFishnet,
        { pollInterval: PREMATCH_MATCH_DETAILS_POLL_INTERVAL },
        loadMD,
        matchId,
        offset,
      );
    } else if (!sagaRunning(taskLive) && isLive(status)) {
      // poll using live interval
      taskPre = sagaCancel(taskPre); // cancel pre polling
      taskLive = yield fork(
        pollFishnet,
        { pollInterval: LIVE_MATCH_DETAILS_POLL_INTERVAL },
        loadMD,
        matchId,
        offset,
      );
    } else if (isEnded(status)) {
      // cancel when match is ended
      yield delay(LIVE_MATCH_DETAILS_POLL_INTERVAL + 1000); // let it make one final request
      taskPre = sagaCancel(taskPre);
      taskLive = sagaCancel(taskLive);
    }

    yield call(takeMatchUpdate, matchId);
  }
}

export default pollMatchDetails;
