/*
 * action types
 */
import {
  SRLIVE_LOADING,
  SRLIVE_LOAD_SUCCESS,
  SRLIVE_LOAD_ERROR,
  SRLIVE_RELOAD,
} from 'constants/actions';

/*
 * action creators
 */
export function srLiveLoading() {
  return { type: SRLIVE_LOADING };
}

export function srLiveLoadSuccess() {
  return { type: SRLIVE_LOAD_SUCCESS };
}

export function srLiveLoadError(err) {
  return { type: SRLIVE_LOAD_ERROR, payload: err };
}

export function srLiveReload(err) {
  return { type: SRLIVE_RELOAD, payload: err };
}
