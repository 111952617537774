/* eslint-disable max-classes-per-file */
import lcrRequest from 'lcrRequest';
import { transformAPIError } from 'utils';
import { APIError } from 'utils/LoggerItem';
import {
  BETSHOP_CONFIG_MAX_AGE,
  HTTP_STATUS_CODE_PAYMENT_REQUIRED,
} from 'constants/bcms';
import { isNewHostName, isOldHostName } from 'utils/Url';

class BCMSFeed {
  constructor(baseUrl, feed, params = undefined, isOperator = 0) {
    const urlBase = baseUrl;
    const paramArr = Object.keys(params)
      .map(
        param => `${param}=${params[param]}`,
      );
    const paramStr = `&${paramArr.join('&')}`;

    this.url = `${urlBase}?feed=${feed}${paramStr}`;

    // For new hostname, we need to use new endpoints
    if (isOldHostName()) {
      this.url = `${urlBase}?feed=${feed}${paramStr}`;
    } else {
      const lcrParams = isOperator ? `?${paramStr}` : '';

      if (feed === 'BetshopConfig') {
        this.url = '/feeds/betshop-config' + lcrParams;
      } else if (feed === 'NextMatches') {
        this.url = '/feeds/next-matches' + lcrParams;
      } else if (feed === 'HighlightsOfTheDay') {
        this.url = '/feeds/highlights-of-the-day' + lcrParams;
      } else if (feed === 'ChannelStageConfig') {
        this.url = '/feeds/channel-stage-config' + lcrParams;
      } else if (feed === 'Words') {
        this.url = `${urlBase}?feed=WordsLcr${paramStr}`;
      }
    }

    this.maxAge = 5;
  }

  getMaxAge() {
    return this.maxAge;
  }

  get() {
    return lcrRequest
      .get(this.url)
      .then(data => {
        if (isNewHostName() && data.code === HTTP_STATUS_CODE_PAYMENT_REQUIRED) {
          return data;
        }

        if (data.error) {
          throw data;
        }

        return data.doc[0];
      })
      .catch(transformAPIError);
  }
}

export class BetshopConfigRequest extends BCMSFeed {
  constructor(baseUrl, userId, isOperator) {
    super(baseUrl, 'BetshopConfig', { id: userId }, isOperator);
  }

  normalizeData(response) {
    if (isNewHostName() && response.code === HTTP_STATUS_CODE_PAYMENT_REQUIRED) {
      return response;
    }

    const {
      data,
      _maxage,
    } = response;
    this.maxAge = _maxage || BETSHOP_CONFIG_MAX_AGE;

    if (!data || response.data.code === 0) {
      const { message } = response.data;
      throw new APIError(
        'BCMS API error',
        message,
      );
    }
    return {
      betshopConfig: data,
    };
  }

  get() {
    return super
      .get()
      .then(response => {
        if (response.error) {
          throw response;
        }
        return this.normalizeData(response);
      })
      .catch(error => transformAPIError(error));
  }
}

class OddsPackageMarketsRequest extends BCMSFeed {
  constructor(baseUrl, packageId) {
    super(baseUrl, 'OddsPackageMarkets', { id: packageId });
  }
}

export class BetshopOddsPanelSettingsRequest extends BCMSFeed {
  constructor(baseUrl, betshopId, sportId) {
    super(baseUrl, 'BetshopOddsPanelSettings', {
      id: betshopId,
      sport_id: sportId,
    });
    this.baseUrl = baseUrl;
  }

  exec() {
    return super
      .get()
      .then(response => {
        if (response.data.package === null) {
          return null;
        }
        const request = new OddsPackageMarketsRequest(this.baseUrl, response.data.package);
        return request.get();
      })
      .catch(() => null);
  }
}


export class NextMatchesRequest extends BCMSFeed {
  constructor(baseUrl, userId, channelId, isOperator) {
    super(baseUrl, 'NextMatches', {
      id: userId,
      channel: channelId,
    }, isOperator);
  }

  static normalizeData(response) {
    const normalizedMatches = response.data.map(item => {
      const {
        status,
        ts,
        showrealcategory,
        statusmsg,
        alt,
        matchid,
      } = item;

      return {
        displaySolution: item.display_solution,
        status,
        timestamp: ts,
        showRealcategory: showrealcategory,
        statusMessage: statusmsg,
        alternativeEvent: alt,
        matchId: parseInt(matchid, 10),
      };
    });
    return normalizedMatches;
  }

  get() {
    return super
      .get()
      .then(response => NextMatchesRequest.normalizeData(response))
      .catch(error => error);
  }
}

export class HighlightsOfTheDayRequest extends BCMSFeed {
  constructor(baseUrl, userId, channelId, isOperator) {
    super(baseUrl, 'HighlightsOfTheDay', {
      id: userId,
      channel: channelId,
    }, isOperator);
  }
}

export class WordsRequest extends BCMSFeed {
  constructor(baseUrl, userId, lang, isOperator) {
    super(baseUrl, 'Words', {
      id: userId,
      language: lang,
    }, isOperator);
  }
}

export class ChannelStageConfigRequest extends BCMSFeed {
  constructor(baseUrl, userId, channelId, stageId, isOperator) {
    super(baseUrl, 'ChannelStageConfig', {
      id: userId,
      channel: channelId,
      stage: stageId,
    }, isOperator);
  }
}
