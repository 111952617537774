import {
  call,
  select,
  delay,
  put,
  race,
  take,
  fork,
  takeLatest,
} from 'redux-saga/effects';
import { log } from 'actions/logger';
import { DISPLAY_ERROR, DISPLAY_INFO } from 'constants/bcms';
import { DebugLog, ErrorLog } from 'utils/LoggerItem';
import {
  MAX_API_RETRIES,
  API_RETRY_DELAY,
  API_TIMEOUT,
  MIN,
  MAX_RESTARTS,
  BASE_TIME,
  MAX_WAIT_TIME,
  BASE_NUMBER,
} from 'constants/app';
import { transformAPIError } from '../utils/index';

export const sagaRunning = saga => saga && saga.isRunning();

export const sagaCancel = saga => {
  if (sagaRunning(saga)) {
    saga.cancel();
  }
  return saga;
};

export const sagaLogAndRethrow = (saga, ...args) => function* () {
  try {
    yield call(saga, ...args);
  } catch (error) {
    const msg = new ErrorLog(error.message, error.stack);
    yield put(log(msg));
    throw error;
  }
};

export const sagaTryLog = (saga, ...args) => function* () {
  try {
    yield call(saga, ...args);
  } catch (error) {
    if (error && error.error) {
      yield put(log(error));
    } else {
      const msg = new ErrorLog(error.message, error.stack);
      yield put(log(msg));
    }
  }
};

export const sagaTakeRunOnce = (pattern, saga, ...args) => function* () {
  yield take(pattern);
  yield call(sagaTryLog(saga, ...args));
};

export const sagaTryTimeout = (waitTime, saga, ...args) => function* () {
  try {
    yield race([
      call(saga, ...args),
      delay(waitTime),
    ]);
  } catch (error) {
    yield put(log(transformAPIError(error)));
  }
};

export const sagaRunTakeLatest = (pattern, saga, ...args) => function* () {
  try {
    const initialSaga = yield fork(saga, ...args);

    yield fork(function* () {
      yield take(pattern);
      sagaCancel(initialSaga);
    });

    yield takeLatest(pattern, saga, ...args);
  } catch (error) {
    const msg = new ErrorLog(error.message, error.stack);
    yield put(log(msg));
  }
};

// restarts only on error
export const makeRestartable = (retryObj, saga, ...args) => function* () {
  const baseTime = (retryObj && retryObj.baseTime) ? retryObj.baseTime : BASE_TIME;
  const retries = (retryObj && retryObj.retries) ? retryObj.retries : MAX_RESTARTS;
  const baseNumber = (retryObj && retryObj.baseNumber) ? retryObj.baseNumber : BASE_NUMBER;
  const maxWait = (retryObj && retryObj.maxWait) ? retryObj.maxWait : MAX_WAIT_TIME;

  for (let i = 0; ;i++) {
    if (i >= retries) {
      const msg = new ErrorLog(`${saga.name}(${args})`, 'Max Retries');
      yield put(log(msg));
      break;
    }
    try {
      yield call(saga, ...args);
      break;
    } catch (error) {
      if (error && (error.type === DISPLAY_ERROR || error.type === DISPLAY_INFO)) {
        yield put(log(error)); // is DisplayError, show onscreen
      } else {
        const msg = new ErrorLog(error.message, error.stack);
        yield put(log(msg));
      }
    }
    const wait = !!i * baseTime * (baseNumber ** i); // i = 0 = no delay
    const usedWait = wait > maxWait ? maxWait : wait;
    yield delay(usedWait);
  }
};

// set up to be fast enough to complete retries during channel trailer
export const retryApi = (MAX_RETRIES, saga, ...args) => function* () {
  for (let i = 0; i < MAX_RETRIES; i++) {
    try {
      const { api } = yield race({
        api: call(saga, ...args),
        timeout: delay(API_TIMEOUT),
      });

      if (api) {
        return api;
      }
      throw new Error('retryApi');
    } catch (error) {
      const msg = new DebugLog(
        `retryApi: ${saga.name}(${args}) failed`, `Attempt ${i}, ${error}`, 0,
      );
      yield put(log(msg));
    }
    yield delay(API_RETRY_DELAY * i);
  }
  // error after MAX_RETRIES attempts
  throw new DebugLog(`retryApi: ${saga.name}(${args}) failed`, 'Max Attempts', 0);
};

// poll Api using retryApi
export const pollApi = (pollInterval, saga, ...args) => function* () {
  let errors = 0;
  for (let i = 0; ;i++) {
    try {
      yield call(retryApi(MAX_API_RETRIES, saga, ...args));
      errors = 0;
    } catch (error) {
      const msg = new DebugLog(`pollApi: ${saga.name}(${args}) failed`, `${errors} times`, 1);
      yield put(log(msg));
      errors++;
    }
    // add minute delay on error
    if (errors > MAX_API_RETRIES) {
      yield delay(pollInterval + MIN);
    } else {
      yield delay(pollInterval);
    }
  }
};

export const takeApi = (pattern, saga, ...args) => function* () {
  for (let i = 0; ;i++) {
    try {
      yield call(retryApi(MAX_API_RETRIES, saga, ...args));
    } catch (error) {
      const msg = new DebugLog(`pollApi: ${saga.name}(${args}) failed`, '', 1);
      yield put(log(msg));
    }
    yield take(pattern);
  }
};

// run saga on change, return next value
export function* onSelectorChange(selector, prev, saga, ...args) {
  const next = yield select(selector);
  if (next !== prev) {
    yield call(sagaTryLog(saga, next, ...args));
  }
  return next;
}

// run saga on change
export function* onSelectorChangeMulti(selectors, prevs, saga, ...args) {
  const nextArr = [];

  let runSaga = 0;
  for (let i = 0; i < prevs.length; i++) {
    const prev = prevs[i];
    const selector = selectors[i];

    const next = yield select(selector);
    nextArr.push(next);

    if (next !== prev) {
      runSaga++;
    }
  }

  // 1 or more changed
  if (runSaga > 0) {
    const retVal = yield call(saga, ...nextArr, ...args);
    if (retVal) {
      return [...nextArr, retVal];
    }
    return nextArr;
  }

  return [...prevs, ...args];
}

// DEBUG FUNCTIONS
export function* alwaysPrinting(text = 'alive', secs = 5000, num = 0) {
  for (let i = 0; ; i++) {
    // eslint-disable-next-line no-console
    console.log(text, i + num);
    yield delay(secs);
  }
}
export function* throwAfter(secs) {
  yield delay(secs * 1000);
  throw new Error('Intentional Throw');
}
