import {
  put,
  select,
  fork,
  take,
  all,
  delay,
} from 'redux-saga/effects';
import {
  switchStatSlide,
  updateStatSlide,
} from 'actions/components';
import {
  getMatchId,
  getSSCompAllSlides,
  getSSCompDynamicSlides,
  getSSCompActivePreMatchSlideIds,
  getSSCompActiveBreakSlideIds,
  getActiveStage,
  getSSCompConfig,
  getMatchHomeLastMResult,
  getMatchPrevMeetings,
  getMatchAwayLastMResult,
  getMatchStatus,
  getMatchWinPctGround,
  getMatchDetails,
  getMatchGroundId,
  getMatchEvents,
  getMatchSportId,
  getMatchTable,
  getIsNewDesign,
} from 'reducers/index';
import {
  STATSLIDES_UPDATE_SLIDES,
  UPDATE_STATS_TEAM_LASTX,
} from 'constants/actions';
import {
  SS_FORM_CLAY,
  SS_FORM_GRASS,
  SS_FORM_HARDCOURT,
  SS_FORM_INDOOR,
  SS_FORM_OVERALL,
  SS_LAST_MATCHES,
  SS_LEAGUE_TABLE,
  SS_LIVESTATS,
  SS_PREVIOUS_MEETINGS,
  SS_TIMELINE,
} from 'constants/components';
import {
  UPDATE_MATCH_DELTA,
  UPDATE_MATCH_DETAILS,
  UPDATE_MATCH_TIMELINE,
  UPDATE_STATS_TEAM_VERSUS,
  UPDATE_STATS_TEAM_WIN_PCT,
  UPDATE_TOURNAMENT_LIVETABLE,
} from 'actions/fishnet/actionTypes';
import {
  CLAY, GRASS, HARDCOURT, INDOOR, OVERALL,
} from 'constants/fishnet';
import { isNotStarted } from 'utils/matchstatus';
import { sport } from 'constants/enum';
import { STAGE_BREAK, STAGE_PRE_MATCH } from 'constants/bcms';
import { sagaRunTakeLatest } from '../../utilSagas';


function* onTeamLastX() {
  const matchId = yield select(getMatchId);
  const home = yield select(getMatchHomeLastMResult, matchId);
  const away = yield select(getMatchAwayLastMResult, matchId);

  yield put(updateStatSlide({
    slide: SS_LAST_MATCHES,
    status: !!((home && home.length) && (away && away.length)),
  }));
}


function* onTeamVersus() {
  const matchId = yield select(getMatchId);
  const prevMeet = yield select(getMatchPrevMeetings, matchId);

  yield put(updateStatSlide({
    slide: SS_PREVIOUS_MEETINGS,
    status: !!(prevMeet && prevMeet.matches && prevMeet.matches.length),
  }));
}

function* onTimeline() {
  const matchId = yield select(getMatchId);
  const sportId = yield select(getMatchSportId, matchId);
  const events = yield select(getMatchEvents, matchId);

  const supportedSports = [sport.soccer, sport.esoccer, sport.ice_hockey];

  yield put(updateStatSlide({
    slide: SS_TIMELINE,
    status: supportedSports.includes(sportId) && Object.keys(events || {}).length > 0,
  }));
}

function* onTeamWinPct() {
  const matchId = yield select(getMatchId);
  const ground = yield select(getMatchGroundId, matchId);
  let winPct = yield select(getMatchWinPctGround, matchId, ground);

  let slideId;
  switch (ground) {
    case CLAY:
      slideId = SS_FORM_CLAY;
      break;
    case HARDCOURT:
      slideId = SS_FORM_HARDCOURT;
      break;
    case INDOOR:
      slideId = SS_FORM_INDOOR;
      break;
    case GRASS:
      slideId = SS_FORM_GRASS;
      break;
    default:
      break;
  }

  if (slideId) {
    // slide for played on ground type
    yield put(updateStatSlide({
      slide: slideId,
      status: !!(winPct && (winPct.home || winPct.away)),
    }));
  }

  winPct = yield select(getMatchWinPctGround, matchId, OVERALL);
  // overall slide
  yield put(updateStatSlide({
    slide: SS_FORM_OVERALL,
    status: !!(winPct && (winPct.home || winPct.away)),
  }));
}

function* onMatchDetails(matchId) {
  const statusId = yield select(getMatchStatus, matchId);
  const mDetails = yield select(getMatchDetails, matchId);

  yield put(updateStatSlide({
    slide: SS_LIVESTATS,
    status: !isNotStarted(statusId) && Object.keys(mDetails || {}).length > 0,
  }));
}

function* onTable() {
  const matchId = yield select(getMatchId);
  const table = yield select(getMatchTable, matchId);

  yield put(updateStatSlide({
    slide: SS_LEAGUE_TABLE,
    status: table && table.length > 0,
  }));
}

function* generalStatSlides(matchId, sagas) {
  // toggles slides ready on actions
  // eslint-disable-next-line no-unused-vars
  let allOn;
  if (!sagas) {
    allOn = yield all([
      fork(sagaRunTakeLatest([UPDATE_STATS_TEAM_LASTX], onTeamLastX)),
      fork(sagaRunTakeLatest([UPDATE_STATS_TEAM_VERSUS], onTeamVersus)),
      fork(sagaRunTakeLatest([UPDATE_MATCH_DETAILS], onMatchDetails, matchId)),
      fork(sagaRunTakeLatest([UPDATE_STATS_TEAM_WIN_PCT], onTeamWinPct, matchId)),
      fork(sagaRunTakeLatest([UPDATE_MATCH_TIMELINE, UPDATE_MATCH_DELTA], onTimeline, matchId)),
      fork(sagaRunTakeLatest([UPDATE_TOURNAMENT_LIVETABLE], onTable)),
    ]);
  } else {
    allOn = yield all(sagas);
  }

  yield all([
    take([STATSLIDES_UPDATE_SLIDES]),
    delay(200), // wait a little, lastx is first and requires two requests
  ]);

  const activeStage = yield select(getActiveStage);
  const activePreMatchSlideIds = yield select(getSSCompActivePreMatchSlideIds);
  const activeBreakSlideIds = yield select(getSSCompActiveBreakSlideIds);
  const isBreakStage = activeStage === STAGE_BREAK;
  const isPreMatchStage = activeStage === STAGE_PRE_MATCH;
  const newDesign = yield select(getIsNewDesign);

  let idx = 0;
  let idxMod = 0;
  let setIdx = -1;
  let numChecked = 0;
  const config = yield select(getSSCompConfig);

  // controls current index for top row
  for (;;) {
    const allSlides = yield select(getSSCompAllSlides);
    const dynamicSlides = yield select(getSSCompDynamicSlides);
    idxMod = idx % allSlides.length;
    numChecked += 1;


    let currentStageNewDesignSlideCheck = true;
    // TODO: Cleanup logic of working with the prematch stage and brake stage after completely moving to the new design
    if (newDesign) {
      if (isPreMatchStage) {
        currentStageNewDesignSlideCheck = activePreMatchSlideIds.includes(idxMod);
      } else if (isBreakStage) {
        currentStageNewDesignSlideCheck = activeBreakSlideIds.includes(idxMod);
      }
    }

    if (dynamicSlides[idxMod].active && currentStageNewDesignSlideCheck) {
      if (setIdx !== idxMod) {
        yield put(switchStatSlide(idxMod));
        setIdx = idxMod;
      }

      yield delay(config[allSlides[idxMod]].duration);
    } else if (numChecked >= allSlides.length * 2 && setIdx === -1) {
      yield take(STATSLIDES_UPDATE_SLIDES);
      numChecked = 0;
    }

    idx += 1;
    yield delay(100);
  }
}

export default generalStatSlides;
