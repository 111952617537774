import {
  LOG_MESSAGE,
  HIDE_MESSAGE,
} from 'constants/actions';

export function log(message) {
  return { type: LOG_MESSAGE, payload: message };
}

export function hideLoggerMessage(id) {
  return { type: HIDE_MESSAGE, payload: id };
}
