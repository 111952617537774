/*
 * action types
 */

import {
  CANCEL_MESSAGE,
  SET_MESSAGE,
  ENQUEUE_MESSAGE,
  DEQUEUE_MESSAGE,
  TOGGLE_VISIBILITY,
  VISIBILITY_ON,
  VISIBILITY_OFF,
} from 'constants/actions';


/*
 * action creators
 */
export function enqueueMessage(payload) {
  return { type: ENQUEUE_MESSAGE, payload };
}
export function dequeueMessage() {
  return { type: DEQUEUE_MESSAGE };
}

export function cancelCurrentMessage() {
  return { type: CANCEL_MESSAGE };
}

export function setCurrentMessage(payload) {
  return { type: SET_MESSAGE, payload };
}

export function toggleVisibility() {
  return { type: TOGGLE_VISIBILITY };
}

export function visibilityOn() {
  return { type: VISIBILITY_ON };
}

export function visibilityOff() {
  return { type: VISIBILITY_OFF };
}
