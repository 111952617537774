import {
  put,
  select,
  fork,
  race,
  take,
  delay,
} from 'redux-saga/effects';
import { switchMatchBreakSlide, updateBreakSlides } from 'actions/stages';
import { STAGE_BREAK, CHANNEL_TRAILER } from 'constants/bcms';
import runAnnouncementStage from 'sagas/stages/announcement';
import {
  getStageConfig,
  getSubstages,
  getMatchId,
} from 'reducers';
import { SET_STATIC_VIDEO_ENDED } from 'constants/actions';
import controlScoreboard from '../components/scoreboard';
import controlStatSlides from '../components/statslides';

export default function* runMatchBreakStage() {
  yield fork(runAnnouncementStage);
  yield fork(controlScoreboard, yield select(getMatchId));
  yield fork(controlStatSlides, yield select(getMatchId));


  // Fliter slides based on the ones from bcms feeds dynamic config
  let stageConf = yield select(getStageConfig, STAGE_BREAK);
  const substages = yield select(getSubstages);
  let filteredSlides = stageConf.allSlides.filter(
    itm => substages.indexOf(itm) !== -1,
  );
  // when stage isn't selected in bcms, but break stage is forced, substages will be empty
  // use all then
  if (!filteredSlides.length) {
    filteredSlides = [...stageConf.allSlides].filter(slideId => slideId !== CHANNEL_TRAILER);
  }

  yield put(updateBreakSlides(filteredSlides));

  for (;;) {
    yield race({
      customInterval: take(SET_STATIC_VIDEO_ENDED),
      defaultInterval: delay(stageConf.defaultInterval),
    });
    stageConf = yield select(getStageConfig, STAGE_BREAK);

    // Calculate next slide idx
    const currentSlideIdx = (stageConf.currentSlideIdx + 1) % filteredSlides.length;

    yield put(switchMatchBreakSlide(currentSlideIdx));
  }
}
