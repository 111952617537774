import { fork, put } from 'redux-saga/effects';
import monitorStreamsUrls from 'sagas/streams';
import { pollStreamUrls } from 'actions/stream';


export default function* runWorldLotteryStage() {
  const id = -1;
  const worldLottery = true;
  yield fork(monitorStreamsUrls, id, worldLottery);
  yield put(pollStreamUrls());
}
