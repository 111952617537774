/*
 * action types
 */

import {
  BCMS_BETSHOP_CONFIG_LOAD_SUCCESS,
  INITALSTAGE_ENDED,
  RESET_APP,
  REACT_ERROR,
  SET_VIDEO_BREAK,
  REMOVE_VIDEO_BREAK,
  CHANGE_MAIN_MATCH,
  CHANGE_LAST_MATCH,
  CHANGE_HOTD_MATCH,
  SET_AS_MATCHID,
  SET_AS_MATCHES,
  SET_LMT_MATCH,
  SET_AS_STAGEID,
  SET_READY_MATCHID,
} from 'constants/actions';

/*
* action creators
*/
export function changeMainMatch(mId) {
  return { type: CHANGE_MAIN_MATCH, payload: mId };
}

export function changeLastMatch(mId) {
  return { type: CHANGE_LAST_MATCH, payload: mId };
}
export function changeHOTDMatch(mId) {
  return { type: CHANGE_HOTD_MATCH, payload: mId };
}

export function setReadyMatchId(mId) {
  return { type: SET_READY_MATCHID, payload: mId };
}

export function setInitialStageEnded() {
  return { type: INITALSTAGE_ENDED };
}

export function setVideoBreak() {
  return { type: SET_VIDEO_BREAK };
}
export function removeVideoBreak() {
  return { type: REMOVE_VIDEO_BREAK };
}

export function resetApp() {
  return { type: RESET_APP };
}

export function setAutoSportMatchId(matchId) {
  return { type: SET_AS_MATCHID, payload: matchId };
}

export function setAutoSportMatches(matches) {
  return { type: SET_AS_MATCHES, payload: matches };
}

export function setLMTMatch(matchId) {
  return { type: SET_LMT_MATCH, payload: matchId };
}

export function setAutoSportStageId(stageId) {
  return { type: SET_AS_STAGEID, payload: stageId };
}

export default function updateBcmsConfig(config) {
  return { type: BCMS_BETSHOP_CONFIG_LOAD_SUCCESS, payload: config };
}

export function logReactError(error, names) {
  return {
    type: REACT_ERROR,
    payload: {
      ...error,
      names,
    },
  };
}
