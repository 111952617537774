const lcrTranslations = {
  en: {
    'started': 'match start',
  },
  es: {
    otl: 'PTA',
    p: 'j',
    'ø goals scored': 'Ø GOLES A FAVOR',
    'ø goals conceded': 'Ø GOLES EN CONTRA',
    form: 'TABLA DE TENDENCIAS',
    ot: 'pr',
    ap: 'dp',
    ended: 'FINALIZADO',
    'last matches': 'ÚLTIMOS JUEGOS',
    'previous meetings': 'ENFRENTAMIENTOS ANTERIORES',
    'league rank': 'CLASIFICACIÓN DE LA LIGA',
    'ø points scored': 'Ø PUNTOS MARCADOS',
    'ø points conceded': 'Ø PUNTOS EN CONTRA',
    wins: 'ganados',
    losses: 'derrotas',
    'atp ranking': 'ATP Clasificación',
    'wta ranking': 'WTA Clasificación',
    overall: 'global',
    'win rate': 'ratio de victorias',
    clay: 'tierra batida',
    kickoff: 'inicio',
    'match of the day': 'partido del día',
  },
  et: {
    'ø points conceded': 'Ø VÄRAVAD VASTASE KASUKS',
    'ø goals scored': 'Ø LÖÖDUD VÄRAVAD',
    'corner kicks': 'nurgalöögid',
    'attacking strength': 'RÜNDETUGEVUS',
    'match of the day': 'mängupäev',
    'clay': 'savi',
    'wta ranking': 'Asetus WTA edetabelis',
  },
  de: {
    otl: 'NVL',
    p: 'sp',
    'ø goals scored': 'Ø TORE GESCHOSSEN',
    'ø goals conceded': 'Ø GEGENTORE',
    ap: 'n.E.',
    ended: 'BEENDET',
    'last matches': 'LETZTE SPIELE',
    'previous meetings': 'VORHERIGE BEGEGNUNGEN',
    'league rank': 'LIGA-PLATZIERUNG',
    'ø points scored': 'Ø PUNKTE ERZIELT',
    'ø points conceded': 'Ø PUNKTE KASSIERT',
    kickoff: 'spielbeginn',
    'match of the day': 'spiel des tages',
  },
  pl: {
    otl: 'Prz.',
    p: 'rm',
    'ø goals scored': 'Ø GOLE STRZELONE',
    'ø goals conceded': 'Ø GOLE STRACONE',
    'last matches': 'OSTATNIE MECZE',
    ot: 'Do',
    ap: 'pk',
    ended: 'ZAKOŃCZONY',
    'previous meetings': 'POPRZEDNIE SPOTKANIA',
    'ø points scored': 'Ø PUNKTY ZDOBYTE',
    'ø points conceded': 'Ø PUNKTY STRACONE',
    'league rank': 'TABELA LIGI',
    kickoff: 'POCZĄTEK MECZU',
    'match of the day': 'MECZ DNIA',
  },
  fr: {
    otl: 'DPR',
    p: 'j',
    'ø goals scored': 'Ø BUTS MARQUÉS',
    'ø goals conceded': 'Ø BUTS ENCAISSÉSS',
    'form': 'forme',
    ot: 'prl',
    ap: 'ap', // Après TaB
    ended: 'TERMINÉ',
    'last matches': 'DERNIERS MATCHS',
    'previous meetings': 'RENCONTRES PRÉCÉDENTES',
    'ø points scored': 'Ø POINTS MARQUÉS',
    'ø points conceded': 'Ø POINTS CONCÉDÉS',
    'league rank': 'CLASSEMENT DU CHAMPIONNAT',
    wins: 'victoire(s)',
    losses: 'défaites',
    'atp ranking': 'ATP classement',
    'wta ranking': 'WTA classement',
    overall: 'total',
    'win rate': '% de victoires',
    clay: 'Terre battue',
    indoor: 'en salle',
    kickoff: "Coup d'envoi",
    'match of the day': 'match du jour',
  },
  lt: {
    'ø points conceded': 'Ø PRALEISTA ĮVARČIŲ',
    'ø goals scored': 'Ø PELNYTI ĮVARČIAI',
    'corner kicks': 'kampiniai',
    'attacking strength': 'PUOLIMO STIPRUMAS',
    'match of the day': 'varžybų diena',
    'clay': 'molis',
    'wta ranking': 'WTA Reitingas',
  },
  lv: {
    'ø points conceded': 'Ø ZAUDĒTIE VĀRTI',
    'ø goals scored': 'Ø GŪTIE VĀRTI',
    'corner kicks': 'stūra sitieni',
    'attacking strength': 'APMEKLĒTĀJU SKAITS',
    'match of the day': 'spēles diena',
    'clay': 'māls',
    'wta ranking': 'WTA Kopvērtējums',
  },
  nl: {
    p: 'wg',
    'form': 'vorm',
    'ø goals scored': 'Ø DOELPUNTEN GEMAAKT',
    'ø goals conceded': 'Ø DOELPUNTEN TEGEN',
    'last matches': 'LAATSTE WEDSTRIJDEN',
    statistics: 'STATISTIEKEN',
    ot: 'et',
    ap: 'np',
    ended: 'BEËINDIGD',
    'previous meetings': 'VOORGAANDE ONTMOETINGEN',
    'ø points scored': 'Ø PUNTEN GESCOORD',
    'ø points conceded': 'Ø PUNTEN TEGEN',
    'league rank': 'COMPETITIEKLASSERING',
    wins: 'Overwinningen',
    losses: 'Nederlagen',
    overall: 'TOTAAL',
    'atp ranking': 'ATP Ranglijst',
    'wta ranking': 'WTA Ranglijst',
    clay: 'gravel',
    kickoff: 'aftrap',
    'match of the day': 'Wedstrijd van de dag',
  },
  aze: {
    'last matches': 'son matçlar',
    'previous meetings': 'əvvəlki görüş',
    ended: 'bitdi',
    'corner kicks': 'künc zərbələri',
  },
};

export default lcrTranslations;
